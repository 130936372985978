<template>
<div class="modal fade" id="modalWarning" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><b>Warning/Error</b></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-md-left">
                    <div class="row">
                        <div class="col-12">
                            <div class="col-12">
                                <b> Request: </b><br>
                                <label>{{(warningSend!=0)?warningSend:''}}</label>
                                <label>{{(warningSend2!=0)?warningSend2:''}}</label>
                                <label>{{(warningSend3!=0)?warningSend3:''}}</label>
                                <label>{{(warningSend4!=0)?warningSend4:''}}</label>
                                <label>{{(warningSend5!=0)?warningSend5:''}}</label>
                            </div>
                            <div class="col-12">
                                <b> Response: </b><br>
                                <label>{{(error!=0)?error:''}}</label>
                                <label>{{(warning!=0)?warning:''}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Modal-WE",
    props: {
        error: String,
        warning: String,
        warningSend: String,
        warningSend2: String,
        warningSend3: String,
        warningSend4: String,
        warningSend5: String,
        dataTarget: {
            type: String,
            default: ""
        }
    },
    mounted() {

    },
    watch: {

    },
    data() {
        return {

        };
    },
    methods: {

    },
};
</script>

<style>
.modal-backdrop {
    top: 0;
    left: -2;
    z-index: 1040;
    width: 0vw;
    height: 100vh;
    background-color: #000;
}

.md-table-cell {
    height: 1%;
    position: relative;
    transition: .3s cubic-bezier(.4, 0, .2, 1);
    font-size: 13px;
    line-height: 0.1px;
}

.modal-body-trama {
    font-size: .7rem;
    font-weight: 600;
    line-height: 1;
}
.md-card .table {
      margin-left: 15px;
    border-left-style: solid;
    border-left-width: 0px;
    margin-right: 15px;
}
</style>
