<template>
<div>
   landing
</div>
</template>

<script>
export default {
    name: 'LandingComponent',
}
</script>

<style scoped>
.jumbotron {
    color: #fff;
    background-color: rgba(98, 177, 173, 0.89);
    background-color: #309792ed;
    background-image: url("https://www.transparenttextures.com/patterns/gplay.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.options {
    display: flex;
    flex-wrap: wrap;
}
</style>
