<template>
  <div
    v-if="loggedUser"
    class="wrapper"
    :class="{ 'nav-open': isSidebarVisible }"
  >
    <notifications></notifications>
    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <div v-if="sidebarLinks.length">
        <sidebar-link v-for="link in sidebarLinks" :key="link.to" :to="link.to">
          <md-icon>{{ link.icon }}</md-icon>
          <p>{{ link.name }}</p>
        </sidebar-link>
      </div>
    </side-bar>

    <div v-if="loggedUser.user_projects.length > 0" class="main-panel">
      <TopNavbar></TopNavbar>
      <DashboardContent> </DashboardContent>
      <ContentFooter v-if="!$route.meta.hideFooter"></ContentFooter>
    </div>
    <div
      v-else-if="loggedUser.user_projects.length == 0 && loggedUser.type === 1"
      class="main-panel"
    >
      <TopNavbar></TopNavbar>
      <FixedPlugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      ></FixedPlugin>
      <DashboardContent> </DashboardContent>
      <ContentFooter v-if="!$route.meta.hideFooter"></ContentFooter>
    </div>
    <div v-else class="main-panel">
      <TopNavbar></TopNavbar>
      <NotFound></NotFound>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "../../views/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";
import NotFound from "../../components/NotFoundProjectComponent.vue";
import { mapState } from "vuex";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin,
    NotFound,
  },

  data() {
    return {
      sidebarBackground: this.color,
      sidebarBackgroundImage: require("../../assets/img/sidebar-6.png"),
      version: "",
      sidebar: this.$root.sidebarStore,
    };
  },
  computed: {
    ...mapState("design", ["color"]),
    isSidebarVisible() {
      return this.$data.sidebar && this.$data.sidebar.showSidebar;
    },
    sidebarLinks() {
      if (
        this.loggedUser.user_projects.length > 0 &&
        this.loggedUser.type === 2
      ) {
        return [
          { to: "/inicio/dashboard", icon: "dashboard", name: "Dashboard" },
          {
            to: "/inicio/testing/execution",
            icon: "play_circle_filled",
            name: "Ejecución Pruebas",
          },
          {
            to: "/inicio/resultado/logger",
            icon: "playlist_add_check",
            name: "Resultado Pruebas",
          },
          /* {
            to: "/inicio/reportes",
            icon: "playlist_add_check",
            name: "Reportes",
          }, */
          {
            to: "/inicio/test/repository",
            icon: "playlist_add_check",
            name: "Repositorio Pruebas",
          },
        ];
      } else if (this.loggedUser.type === 1) {
        return [
          {
            to: "/inicio/mantenedor/usuarios",
            icon: "person",
            name: "Usuarios",
          },
          {
            to: "/inicio/mantenedor/clientes",
            icon: "person",
            name: "Clientes",
          },
          {
            to: "/inicio/mantenedor/proyectos",
            icon: "settings",
            name: "Proyectos",
          },
          {
            to: "/inicio/mantenedor/ambientes",
            icon: "settings",
            name: "Ambientes",
          },
        ];
      } else if (this.loggedUser.type === 3){
        return [
          {
            to: "/inicio/mantenedor/usuarios",
            icon: "person",
            name: "Usuarios",
          },
          {
            to: "/inicio/mantenedor/clientes",
            icon: "person",
            name: "Clientes",
          },
          {
            to: "/inicio/mantenedor/proyectos",
            icon: "settings",
            name: "Proyectos",
          },
          {
            to: "/inicio/mantenedor/ambientes",
            icon: "settings",
            name: "Ambientes",
          },
          { to: "/inicio/dashboard", icon: "dashboard", name: "Dashboard" },
          {
            to: "/inicio/testing/execution",
            icon: "play_circle_filled",
            name: "Ejecución Pruebas",
          },
          {
            to: "/inicio/resultado/logger",
            icon: "playlist_add_check",
            name: "Resultado Pruebas",
          },
          /* {
            to: "/inicio/reportes",
            icon: "playlist_add_check",
            name: "Reportes",
          }, */
          {
            to: "/inicio/test/repository",
            icon: "playlist_add_check",
            name: "Repositorio Pruebas",
          },
        ];
      }
      return [];
    },
    ...mapState("auth", ["loggedUser", "token"]),
  },
};
</script>
