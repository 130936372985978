import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";
import Swal from "sweetalert2";

ls.config.encrypt = false;
const userStore = {
  namespaced: true,
  state: {
    users: [],
    selectedUser: null,
    branches: [],
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setSelectedUser(state, selectedUser) {
      state.selectedUser = selectedUser;
    },
    setBranches(state, branches) {
      state.branches = branches;
    },
  },
  actions: {
    alert({ commit }, msg) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: msg,
      });
    },
    async getUsers({ commit }) {
      try {
        const response = await HttpClient.get("/api/user");
        commit("setUsers", response.data);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },
    async createUser({ commit, dispatch }, createUserDto) {
      const msg = "Se agrego un nuevo Usuario!";
      try {
        const response = await HttpClient.post("/api/user", createUserDto);
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al crear usuario:", error);
      }
    },
    async updateUser({ commit, dispatch }, { id, updateUserDto }) {
      const msg = "Usuario Modificado.";
      try {
        const response = await HttpClient.patch(
          `/api/user/${id}`,
          updateUserDto
        );
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al actualizar usuario:", error);
      }
    },
    async deleteUser({ commit, dispatch }, id) {
      const msg = "El Usuario fue eliminado correctamente.";
      try {
        const response = await HttpClient.delete("/api/user/", id);
        dispatch("alert", msg);
      } catch (error) {
        console.error("Error al eliminar usuario:", error);
      }
    },
    async assignProject({ commit, dispatch }, assignProjectDto) {
      const msg = "El Proyecto fue asignado al usuario correctamente.";
      try {
        const response = await HttpClient.post(
          "/api/user/assign/project",
          assignProjectDto
        );
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al asignar proyecto:", error);
      }
    },
    async storeBranch({ commit, dispatch }, storeBranchDto) {
      const msg = "Las ramas fueron asignadas al usuario correctamente.";
      try {
        const data = [];

        storeBranchDto.forEach((element) => {
          const { userId, projectId, branch } = element;

          data.push({
            userId: userId,
            projectId: projectId,
            branch: branch,
          });
        });

        const response = await HttpClient.post("/api/user/store/branch", data);
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al asignar ramas:", error);
      }
    },
    async getUserBranches({ commit }, getBranchesDto) {
      try {
        const projectId = parseInt(getBranchesDto.projectId, 10);
        const userId = parseInt(getBranchesDto.userId, 10);

        const url = `/api/user/branches/byproject?project_id=${projectId}&user_id=${userId}`;
        const response = await HttpClient.get(url);
        commit("setBranches", response.data);
      } catch (error) {
        console.error("Error al obtener ramas:", error);
      }
    },
  },
  getters: {
    getUsers: (state) => state.users,
    getUserBranches: (state) => state.branches,
  },
};

export default userStore;
