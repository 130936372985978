<template>
  <form>
    <md-card>
      <md-card-header :data-background-color="dataBackgroundColor">
        <h4 class="title">Parametros Ejecución Pruebas</h4>
        <p class="category">
          Especifique los parametros necesarios para realizar sus pruebas.
        </p>
      </md-card-header>

      <md-card-content class="d-flex justify-content-center">
        <div class="row col-12">
          <div class="col-2">
            <b-form-group
              label="Metodo Ejecución:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                v-model="selectedRadioBtn"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Ambiente"
                :disabled="executionRunning"
                >Ambiente</b-form-radio
              >
              <b-form-radio
                v-model="selectedRadioBtn"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="Pruebas"
                :disabled="executionRunning"
                >Pruebas</b-form-radio
              >
            </b-form-group>

            <!-- <div class="mt-3">Selected: <strong>{{ selectedRadioBtn }}</strong></div> -->
          </div>

          <div class="col-5">
            <label>Proyecto:</label>
            <b-form-select
              :state="stateProject"
              v-model="projectSelected"
              :disabled="executionRunning"
            >
              <option disabled value="" selected="selected">Seleccione</option>
              <option
                v-bind:key="index"
                v-bind:value="p"
                v-for="(p, index) in projects"
              >
                {{ p.project.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback id="pass">
              Seleccione Proyecto.
            </b-form-invalid-feedback>
          </div>

          <div class="col-5">
            <label for="">Rama:</label>
            <b-form-select
              :state="stateBranch"
              v-model="branchSelected"
              :disabled="executionRunning || branches.length === 0"
            >
              <option disabled value="" selected="selected">Seleccione</option>
              <option
                v-bind:key="index"
                v-bind:value="bran"
                v-for="(bran, index) in filteredBranches"
              >
                {{ bran.branch }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback id="pass">
              Seleccione Rama.
            </b-form-invalid-feedback>
          </div>
          <!-- <div v-if="selectedRadioBtn == 'Pruebas'" > -->
          <div v-if="selectedRadioBtn == 'Pruebas'" class="col-2">
            ¿Archivo?
            <div>
              <b-form-checkbox
                :state="stateEmisorChange"
                id="checkbox-7"
                v-model="emisorChange"
                name="checkbox-7"
                value="Con cambios"
                unchecked-value="Sin Cambios"
                :disabled="executionRunning"
              >
                Emisor
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox
                :state="stateAdquirienteChange"
                id="checkbox-8"
                v-model="adquirienteChange"
                name="checkbox-8"
                value="Con cambios"
                unchecked-value="Sin Cambios"
                :disabled="executionRunning"
              >
                Adquiriente
              </b-form-checkbox>
            </div>
          </div>
          <div v-if="emisorChange == 'Sin Cambios'" class="col-5">
            <b-form-group
              v-if="selectedRadioBtn == 'Pruebas'"
              id="type"
              label="Emisor:"
              label-for="type"
            >
              <b-form-select
                :state="stateEmisor"
                class="shadow-sm"
                id="selManager"
                v-model="emisor"
                :disabled="executionRunning"
                @input="findFolders('emisor', false)"
              >
                <option disabled value="" selected="selected">
                  Seleccione
                </option>
                <option value="visanet">Visanet</option>
                <option value="hiso87">Hiso87</option>
                <option value="hiso93">Hiso93</option>
                <option value="mds">Mds</option>
              </b-form-select>
              <b-form-invalid-feedback id="iss">
                Seleccione Emisor.
              </b-form-invalid-feedback>
            </b-form-group>

            <div v-if="showEmisorSecondSelect" class="row">
              <div v-if="selectedRadioBtn == 'Pruebas'" class="col-7">
                <b-form-group
                  :class="{ collapse: !showEmisorSecondSelect }"
                  label="Nombre"
                >
                  <b-form-select
                    :state="stateEmisorClient"
                    v-model="emisorClient"
                    :disabled="executionRunning || spinnerEmisorFile"
                  >
                    <option
                      v-bind:key="index"
                      v-bind:value="e.name"
                      v-for="(e, index) in emisorLoad"
                    >
                      {{ e.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    Seleccione Nombre.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-if="selectedRadioBtn == 'Pruebas'" class="col-5">
                <b-form-group
                  :class="{ collapse: !showEmisorSecondSelect }"
                  label="Versión"
                >
                  <b-form-select
                    :state="stateEmisorClientVersion"
                    v-model="emisorClientVersion"
                    :disabled="executionRunning || !emisorClient"
                  >
                    <option
                      v-bind:key="index"
                      v-bind:value="e"
                      v-for="(e, index) in emisorVersions"
                    >
                      {{ e }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    Seleccione Versión.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
          <div v-if="emisorChange != 'Sin Cambios'" class="col-5 mb-3">
            <div v-if="selectedRadioBtn == 'Pruebas'" class="row">
              <div class="col-6">
                <label>Emisor: </label>
              </div>
              <div class="col-6 text-right">
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="emisorVersionable"
                  name="checkbox-2"
                  @input="openModalVersion('emisor')"
                  :disabled="!emisorCheck || executionRunning"
                >
                  ¿Versionar Test Data?
                </b-form-checkbox>
              </div>
              <!-- <b-spinner v-if="spinnerEmisorFile" small variant="white"></b-spinner> -->
            </div>
            <b-form-file
              v-if="selectedRadioBtn == 'Pruebas'"
              :state="stateEmisorFile"
              v-model="emisorFile"
              id="file-emisor"
              placeholder="Seleccione Archivo"
              browse-text="Examinar"
              @input="findFolders('emisor', true)"
              :disabled="executionRunning"
            ></b-form-file>
          </div>

          <div v-if="adquirienteChange == 'Sin Cambios'" class="col-5">
            <b-form-group
              v-if="selectedRadioBtn == 'Pruebas'"
              id="type"
              label="Adquiriente:"
              label-for="type"
            >
              <b-form-select
                :state="stateAdquiriente"
                class="shadow-sm"
                id="selManager"
                v-model="adquiriente"
                :disabled="executionRunning"
                @input="findFolders('adquiriente', false)"
              >
                <option disabled value="" selected="selected">
                  Seleccione
                </option>
                <option value="visanet">Visanet</option>
                <option value="hiso87">Hiso87</option>
                <option value="hiso93">Hiso93</option>
                <option value="mds">Mds</option>
              </b-form-select>
              <b-form-invalid-feedback id="iss">
                Seleccione Adquiriente.
              </b-form-invalid-feedback>
            </b-form-group>

            <div v-if="showAdquirienteSecondSelect" class="row">
              <div v-if="selectedRadioBtn == 'Pruebas'" class="col-7">
                <b-form-group
                  :class="{ collapse: !showAdquirienteSecondSelect }"
                  label="Nombre"
                >
                  <b-form-select
                    :state="stateAdquirienteClient"
                    v-model="adquirienteClient"
                    :disabled="executionRunning || spinnerAdquirienteFile"
                  >
                    <option
                      v-bind:key="index"
                      v-bind:value="a.name"
                      v-for="(a, index) in adquirienteLoad"
                    >
                      {{ a.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    Seleccione Nombre.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-if="selectedRadioBtn == 'Pruebas'" class="col-5">
                <b-form-group
                  :class="{ collapse: !showAdquirienteSecondSelect }"
                  label="Versión"
                >
                  <b-form-select
                    :state="stateAdquirienteClientVersion"
                    v-model="adquirienteClientVersion"
                    :disabled="executionRunning || !adquirienteClient"
                  >
                    <option
                      v-bind:key="index"
                      v-bind:value="a"
                      v-for="(a, index) in adquirienteVersions"
                    >
                      {{ a }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    Seleccione Versión.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
          <div v-if="adquirienteChange != 'Sin Cambios'" class="col-5">
            <div v-if="selectedRadioBtn == 'Pruebas'" class="row">
              <div class="col-6">
                <label>Adquiriente: </label>
              </div>
              <div class="col-6 text-right">
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="adquirienteVersionable"
                  name="checkbox-3"
                  @input="openModalVersion('adquiriente')"
                  :disabled="!adquirienteCheck || executionRunning"
                >
                  ¿Versionar Test Data?
                </b-form-checkbox>
              </div>
            </div>
            <b-form-file
              v-if="selectedRadioBtn == 'Pruebas'"
              :state="stateAdquirienteFile"
              v-model="adquirienteFile"
              id="file-adquiriente"
              placeholder="Seleccione Archivo"
              browse-text="Examinar"
              :disabled="executionRunning"
              @input="findFolders('adquiriente', true)"
              @change="setFile"
            ></b-form-file>
          </div>
          <div
            v-if="selectedRadioBtn == 'Pruebas' && projectSelected"
            class="col-2"
          >
            <label>Ambiente</label>
            <b-form-select
              v-model="environmentSelected"
              :disabled="executionRunning"
              @input="findEnvironment"
            >
              <option disabled value="" selected="selected">Seleccione</option>
              <option
                v-bind:key="index"
                v-bind:value="e"
                v-for="(e, index) in projectSelected.project.environments"
              >
                {{ e.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback id="pass">
              Seleccione Ambiente.
            </b-form-invalid-feedback>
          </div>
          <div v-else class="col-2"></div>
          <div v-if="selectedRadioBtn == 'Pruebas'" class="col-md-3">
            <label for="emisorPort">Puerto Emisor</label>
            <b-form-input
              id="emisorPort"
              maxLength="10"
              :state="stateEmisorPort"
              type="text"
              class="white-sm"
              v-model="emisorPort"
              trim
              :disabled="executionRunning"
            />
            <b-form-invalid-feedback id="emisorPort">
              Ingrese Puerto Emisor.
            </b-form-invalid-feedback>
          </div>
          <div v-if="selectedRadioBtn == 'Pruebas'" class="col-md-4">
            <label for="ip">IP</label>
            <b-form-input
              id="ip"
              maxLength="20"
              :state="stateIp"
              type="text"
              class="white-sm"
              v-model="ip"
              trim
              :disabled="executionRunning"
            />
            <b-form-invalid-feedback id="ip">
              Ingrese IP.
            </b-form-invalid-feedback>
          </div>
          <div v-if="selectedRadioBtn == 'Pruebas'" class="col-md-3">
            <label for="adquirientePort">Puerto Adquiriente</label>
            <b-form-input
              id="adquirientePort"
              maxLength="10"
              :state="stateAdquirientePort"
              type="text"
              class="white-sm"
              v-model="adquirientePort"
              trim
              :disabled="executionRunning"
            />
            <b-form-invalid-feedback id="adquirientePort">
              Ingrese Puerto Adquiriente.
            </b-form-invalid-feedback>
          </div>
          <!-- <div class="col-md-5"></div>
          <div class="col-md-4">
            
          </div>
          <div class="col-md-3"></div> -->

          <div
            class="d-flex justify-content-between md-layout-item col-12 text-center"
          >
            <div class="p-2">
              <md-button
                v-if="selectedRadioBtn == 'Pruebas'"
                class="md-raised md-warning"
                @click="handleFileUpload"
                :disabled="executionRunning"
              >
                Ver Test
              </md-button>
            </div>

            <div class="p-2 d-flex align-items-center">
              <md-button
                class="md-raised md-danger"
                :disabled="!executionRunning || cancelButtonDisabled"
                style="margin-right: 5px"
                @click="stopExecution"
              >
                Cancelar &nbsp;<i class="fas fa-ban"></i>
                &nbsp;
                <b-spinner v-if="spinnerStop" small variant="white"></b-spinner>
              </md-button>

              <md-button
                class="md-raised md-success"
                @click="validate"
                :hidden="btnHiddenEjecutar"
                :disabled="executionRunning || executionBtnDisabled"
              >
                Ejecutar &nbsp;
                <b-spinner
                  v-if="spinnerEjecutar"
                  small
                  variant="white"
                ></b-spinner>
              </md-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <ModalTests :trama="datosJson" data-target="#modalViewTest"></ModalTests>
    <ModalVersion
      :type="versionType"
      @saveVersionName="saveVersionName"
      data-target="#modalVersion"
    ></ModalVersion>
  </form>
</template>

<script>
import * as XLSX from "xlsx";
import "vue-step-progress/dist/main.css";
import ModalTests from "../Modal/ModalTests.vue";
import ModalVersion from "../Modal/ModalVersion.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "execution-form",
  components: {
    ModalTests,
    ModalVersion,
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
    cloneLogs: function () {},
    chron: function () {},
    getMySteps: function () {},
    getPipeline: function () {},
    valuesEmisorAdquiriente: function () {},
    projects: Array,
    branchProjectSelected: Array,
    projectIdApiSelected: Number,
    spinnerEjecutarPadre: Boolean,
    disabledEjecutarPadre: Boolean,
    btnHidden: Boolean,
    btnHiddenEjecutar: Boolean,
    canceled: Boolean,
    status: Boolean,
  },
  mounted() {
    if (!this.epsStatus) {
      this.getEpsStatus();
    }
  },
  watch: {
    canceled: function () {
      if (this.canceled == true) {
        this.clearForm();
      }
    },
    disabledEjecutarPadre: function () {
      this.disabledEjecutar = this.disabledEjecutarPadre;
    },
    spinnerEjecutarPadre: function () {
      this.spinnerEjecutar = this.spinnerEjecutarPadre;
    },
    emisorFile: function () {
      if (
        (this.branchSelected && this.adquirienteFile && this.emisorFile) !=
        undefined
      ) {
        this.$emit(
          "valuesEmisorAdquiriente",
          this.emisorFile.name,
          this.adquirienteFile.name,
          this.branchSelected.branch,
          this.emisor,
          this.adquiriente
        );
      }

      if ((this.emisorFile == [] || this.emisorFile) == undefined) {
        this.emisorCheck = false;
      } else {
        this.emisorCheck = true;
      }
    },
    adquirienteFile: function () {
      if (
        (this.branchSelected && this.adquirienteFile && this.emisorFile) !=
        undefined
      ) {
        this.$emit(
          "valuesEmisorAdquiriente",
          this.emisorFile.name,
          this.adquirienteFile.name,
          this.branchSelected.branch,
          this.emisor,
          this.adquiriente
        );
      }

      if (this.adquirienteFile == [] && this.adquirienteFile == undefined) {
        this.adquirienteCheck = false;
      } else {
        this.adquirienteCheck = true;
      }
    },
    emisor: function () {
      if (
        (this.branchSelected && this.adquirienteFile && this.emisorFile) !=
        undefined
      ) {
        this.$emit(
          "valuesEmisorAdquiriente",
          this.emisorFile.name,
          this.adquirienteFile.name,
          this.branchSelected.branch,
          this.emisor,
          this.adquiriente
        );
      }
      if (this.emisor == "") {
        this.showEmisorSecondSelect = false;
      } else {
        this.showEmisorSecondSelect = true;
      }
    },
    adquiriente: function () {
      if (
        (this.branchSelected && this.adquirienteFile && this.emisorFile) !=
        undefined
      ) {
        this.$emit(
          "valuesEmisorAdquiriente",
          this.emisorFile.name,
          this.adquirienteFile.name,
          this.branchSelected.branch,
          this.emisor,
          this.adquiriente
        );
      }
      if (this.adquiriente == "") {
        this.showAdquirienteSecondSelect = false;
      } else {
        this.showAdquirienteSecondSelect = true;
      }
    },
    branchSelected: function () {
      if (
        (this.branchSelected && this.adquirienteFile && this.emisorFile) !=
        undefined
      ) {
        this.$emit(
          "valuesEmisorAdquiriente",
          this.emisorFile.name,
          this.adquirienteFile.name,
          this.branchSelected.branch,
          this.emisor,
          this.adquiriente
        );
      }
    },
    emisorChange: function () {
      if (this.emisorChange == "Sin Cambios") {
        this.eFile = true;
        this.emisorFile = [];
      } else {
        this.eFile = false;
        this.emisorCheck = true;
        this.emisor = "";
      }
    },
    adquirienteChange: function () {
      if (this.adquirienteChange == "Sin Cambios") {
        this.aFile = true;
        this.adquirienteFile = [];
      } else {
        this.aFile = false;
        this.adquirienteCheck = true;
        this.adquiriente = "";
      }
    },
    projectSelected: function () {
      if (this.projectSelected != undefined) {
        const data = {
          projectId: this.projectSelected.project_id,
          userId: this.loggedUser.id,
        };
        this.setSelectedProject(this.projectSelected);
        this.getUserBranches(data);
        this.value = false;
      }
    },
    selectedRadioBtn: function () {
      this.setExecutionMethod(this.selectedRadioBtn);
      this.branchSelected = "";
      this.cleanForm();
    },
    checkingEpsStatus: function () {
      if (this.checkingEpsStatus) {
        this.showEpsStatusToast();
      } else {
        this.closeEpsStatusToast();
      }
    },
    adquirienteVersionable: function () {
      if (!this.adquirienteVersionable) {
        this.adquirienteVersionName = "";
      }
    },
    emisorVersionable: function () {
      if (!this.emisorVersionable) {
        this.emisorVersionName = "";
      }
    },
  },
  data() {
    return {
      datosJson: [],
      emisor: "",
      emisorFile: [],
      emisorPort: "",
      adquirienteFile: [],
      adquiriente: "",
      adquirientePort: "",
      ip: "",
      emisorVersionable: false,
      adquirienteVersionable: false,
      emisorChange: "Sin Cambios",
      adquirienteChange: "Sin Cambios",
      dfile: true,
      eFile: true,
      aFile: true,
      ejecucionPipeline: null,
      branchSelected: "",
      spinnerEjecutar: false,
      disabledEjecutar: false,
      filePrub: [],
      dateExecution: new Date(),
      file: null,
      projectSelected: "",
      value: true,
      selectedRadioBtn: "Pruebas",
      newToken: "",
      showEmisorSecondSelect: false,
      showAdquirienteSecondSelect: false,
      emisorCheck: false,
      emisorClient: "",
      emisorClientVersion: "",
      emisorVersionName: "",
      spinnerEmisorFile: false,
      adquirienteCheck: false,
      adquirienteClient: false,
      adquirienteClientVersion: "",
      adquirienteVersionName: "",
      spinnerAdquirienteFile: false,
      spinnerStop: false,
      versionType: "",
      toastInstance: null,
      environmentSelected: "",
    };
  },
  methods: {
    ...mapActions("user", ["getUserBranches"]),
    ...mapActions("gitlab", ["executePipeline", "cancelExecution"]),
    ...mapActions("google", ["uploadFiles", "getFolders"]),
    ...mapActions("environment", ["getEnvironment"]),
    ...mapMutations("gitlab", ["setSelectedProject"]),
    ...mapMutations("execution", [
      "setExecutionMethod",
      "setExecutionRunning",
      "setCheckingEpsStatus",
      "setAdquirienteVersion",
      "setEmisorVersion",
      "setCancelButtonDisabled",
    ]),
    cleanForm() {
      this.emisor = "";
      this.emisorFile = [];
      this.emisorPort = "";
      this.adquirienteFile = [];
      this.adquiriente = "";
      this.adquirientePort = "";
      this.ip = "";
      this.emisorVersionable = false;
      this.adquirienteVersionable = false;
      this.emisorChange = "Sin Cambios";
      this.adquirienteChange = "Sin Cambios";
      this.eFile = true;
      this.aFile = true;
      this.showEmisorSecondSelect = false;
      (this.showAdquirienteSecondSelect = false), (this.emisorCheck = false);
      this.emisorClient = "";
      this.emisorClientVersion = "";
      this.emisorVersionName = "";
      this.adquirienteCheck = false;
      this.adquirienteClient = false;
      this.adquirienteClientVersion = "";
      this.adquirienteVersionName = "";
      this.versionType = "";
      this.environmentSelected = "";
      this.spinnerEjecutar = false;
    },
    findFolders(type, hasFile) {
      if (this.projectSelected) {
        let file = "";
        if (type == "emisor") {
          this.spinnerEmisorFile = true;
          if (!hasFile) {
            file = this.emisor;
          } else {
            if (this.emisorFile.name.charAt(0) == "V") {
              file = "visanet";
            } else if (this.emisorFile.name.charAt(0) == "M") {
              file = "mds";
            } else if (this.emisorFile.name.includes('87')){
              file = "hiso87";
            } else if (this.emisorFile.name.includes('93')){
              file = "hiso93";
            }
          }
        }

        if (type == "adquiriente") {
          this.spinnerAdquirienteFile = true;
          if (!hasFile) {
            file = this.adquiriente;
          } else {
            if (this.adquirienteFile.name.charAt(0) == "V") {
              file = "visanet";
            } else if (this.adquirienteFile.name.charAt(0) == "M") {
              file = "mds";
            } else if (this.adquirienteFile.name.includes('87')){
              file = "hiso87";
            } else if (this.adquirienteFile.name.includes('93')){
              file = "hiso93";
            }
          }
        }

        const data = {
          client: this.projectSelected.project.client.name,
          type: type,
          test: file,
        };

        this.getFolders(data).then((response) => {
          if (type == "emisor") {
            this.spinnerEmisorFile = false;
          }

          if (type == "adquiriente") {
            this.spinnerAdquirienteFile = false;
          }
        });
      }
    },
    findEnvironment() {
      if (this.environmentSelected) {
        this.getEnvironment(this.environmentSelected.id).then((response) => {
          if (this.emisorFile.length != 0) {
            if (this.emisorFile.name.charAt(0) == "V") {
              this.emisorPort = response.port_visanet;
            } else if (this.emisorFile.name.charAt(0) == "M") {
              this.emisorPort = response.port_mds;
            } else if(this.emisorFile.name.includes('87')){
              this.emisorPort = response.port_hiso87;
            } else if(this.emisorFile.name.includes('93')){
              this.emisorPort = response.port_hiso93;
            }
          }

          if (this.adquirienteFile.length != 0) {
            if (this.adquirienteFile.name.charAt(0) == "V") {
              this.adquirientePort = response.port_visanet;
            } else if (this.adquirienteFile.name.charAt(0) == "M") {
              this.adquirientePort = response.port_mds;
            } else if(this.adquirienteFile.name.includes('87')){
              this.adquirientePort = response.port_hiso87;
            } else if(this.adquirienteFile.name.includes('93')){
              this.adquirientePort = response.port_hiso93;
            }
          }

          if (this.emisor) {
            if (this.emisor.charAt(0) == "v") {
              this.emisorPort = response.port_visanet;
            } else if (this.emisor.charAt(0) == "m") {
              this.emisorPort = response.port_mds;
            } else if(this.emisor.includes('87')){
              this.emisorPort = response.port_hiso87;
            } else if(this.emisor.includes('93')){
              this.emisorPort = response.port_hiso93;
            }
          }

          if (this.adquiriente) {
            if (this.adquiriente.charAt(0) == "v") {
              this.adquirientePort = response.port_visanet;
            } else if (this.adquiriente.charAt(0) == "m") {
              this.adquirientePort = response.port_mds;
            } else if (this.adquiriente.includes('87')){
              this.adquirientePort = response.port_hiso87;
            } else if (this.adquiriente.includes('93')){
              this.adquirientePort = response.port_hiso93;
            }
          }

          if (response.ip) {
            this.ip = response.ip;
          }
        });
      }
    },
    showEpsStatusToast() {
      let timerInterval;
      this.toastInstance = this.$swal.fire({
        title: "Obteniendo estado de Base24",
        html: "Esperando actualización <b></b>",
        timerProgressBar: true,
        showConfirmButton: false,
        backdrop: true,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
          const b = this.$swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = "";
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    },
    closeEpsStatusToast() {
      if (this.toastInstance) {
        this.toastInstance.close();
      }
    },
    setEmisorPort() {
      if (this.emisorFile.name.charAt(0) == "V") {
        this.emisorPort = "1809";
      } else if (this.emisorFile.name.charAt(0) == "M") {
        this.emisorPort = "7781";
      } else {
        this.emisorPort = "1719";
      }
    },
    setAdquirientePort() {
      if (this.adquirienteFile.name.charAt(0) == "V") {
        this.adquirientePort = "1809";
      } else if (this.adquirienteFile.name.charAt(0) == "M") {
        this.adquirientePort = "7781";
      } else {
        this.adquirientePort = "1719";
      }
    },
    saveVersionName(name, type) {
      if (type == "emisor") {
        this.emisorVersionName = name;
      }

      if (type == "adquiriente") {
        this.adquirienteVersionName = name;
      }

      $("#modalVersion").modal("hide");
    },
    openModalVersion(type) {
      if (type == "emisor") {
        if (this.emisorVersionable) {
          this.versionType = type;
          $("#modalVersion").modal("show");
        }
      } else {
        if (this.adquirienteVersionable) {
          this.versionType = type;
          $("#modalVersion").modal("show");
        }
      }
    },
    setFile(event) {
      this.file = event.target.files[0];
    },
    handleFileUpload() {
      if (!this.file) {
        this.$swal({
          title: "No se ha encontrado el test",
          text: "Debe cargar un archivo en el campo Adquiriente para ver.",
          icon: "error",
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });

        let sheetName = "";
        if (this.file.name.charAt(0) == "H") {
          sheetName = "HISO87_Acquirer_Test_Cases";
        } else if (this.file.name.charAt(0) == "V") {
          sheetName = "VisaNet_Acquirer_Test_Cases";
        } else {
          sheetName = "MDS_Acquirer_Test_Cases";
        }

        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
          console.error(`La hoja ${sheetName} no se encuentra en el archivo`);
          return;
        }

        // Leer las cabeceras de la fila 3
        const headers = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          range: 2,
          end: 2,
        })[0];

        // Leer los datos desde la fila 9, usando las cabeceras obtenidas
        let json = "";
        if (this.file.name.charAt(0) == "H") {
          json = XLSX.utils.sheet_to_json(worksheet, {
            header: headers,
            range: 9,
          });
        } else {
          json = XLSX.utils.sheet_to_json(worksheet, {
            header: headers,
            range: 8,
          });
        }

        // Identificar las columnas que contienen "NOVALUE" o "END"
        const columnsToRemove = new Set();
        json.forEach((row) => {
          Object.entries(row).forEach(([key, value]) => {
            if (value === "**NOVALUE**" || value === "**END**") {
              columnsToRemove.add(key);
            }
          });
        });

        // Eliminar la columna "Test Condition"
        json = json.map((row) => {
          const newRow = {
            ...row,
          };
          delete newRow["Test Condition"];
          return newRow;
        });

        // Remover las columnas identificadas de cada fila
        json = json.map((row) => {
          const filteredRow = {
            ...row,
          };
          columnsToRemove.forEach((col) => delete filteredRow[col]);
          return filteredRow;
        });

        this.datosJson = json;
        this.$nextTick(() => {
          $("#modalViewTest").modal("show");
        });
      };
      reader.readAsBinaryString(this.file);
    },
    clearForm() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    validate() {
      this.errors = [];
      if (this.selectedRadioBtn == "Pruebas") {
        if (!this.stateProject) {
          this.projectSelected = undefined;
          this.errors.push("falta project");
        }
        if (!this.stateBranch) {
          this.branchSelected = undefined;
          this.errors.push("falta branch");
        }
        if (this.emisorChange == "Sin Cambios") {
          if (!this.stateEmisor) {
            this.emisor = undefined;
            this.errors.push("falta emisor");
          }
          if (!this.stateEmisorClient) {
            this.emisorClient = undefined;
            this.errors.push("faltan cliente emisor");
          }
          if (!this.stateEmisorClientVersion) {
            this.emisorClientVersion = undefined;
            this.errors.push("faltan version cliente emisor");
          }
        } else {
          if (!this.stateEmisorFile) {
            this.emisorFile = undefined;
            this.errors.push("falta emisorFile");
          }
        }
        if (this.adquirienteChange == "Sin Cambios") {
          if (!this.stateAdquiriente) {
            this.adquiriente = undefined;
            this.errors.push("falta adquiriente");
          }
          if (!this.stateAdquirienteClient) {
            this.adquirienteClient = undefined;
            this.errors.push("faltan cliente adquiriente");
          }
          if (!this.stateAdquirienteClientVersion) {
            this.adquirienteClientVersion = undefined;
            this.errors.push("faltan version cliente adquiriente");
          }
        } else {
          if (!this.stateAdquirienteFile) {
            this.adquirienteFile = undefined;
            this.errors.push("falta adquirienteFile");
          }
        }
        if (!this.stateIp) {
          this.ip = undefined;
          this.errors.push("falta ip");
        }
        if (!this.stateEmisorPort) {
          this.emisorPort = undefined;
          this.errors.push("falta puerto emisor");
        }
        if (!this.stateAdquirientePort) {
          this.adquirientePort = undefined;
          this.errors.push("falta puerto adquiriente");
        }
      } else {
        if (!this.stateProject) {
          this.projectSelected = undefined;
          this.errors.push("falta project");
        }
        if (!this.stateBranch) {
          this.branchSelected = undefined;
          this.errors.push("falta branch");
        }
      }

      if (this.errors.length == 0) {
        if (this.selectedRadioBtn == "Pruebas") {
          if (!this.status) {
            this.$swal({
              title: "Base24 Apagado",
              text: "Debe encender Base24 antes de ejecutar pruebas.",
              icon: "error",
            });
          } else {
            this.setExecutionRunning(true);
            this.importFiles();
          }
        }
        if (this.selectedRadioBtn == "Ambiente") {
          this.setExecutionRunning(true);
          this.$emit("chron");
          this.ejecutarPipeline();
        }
      }
    },
    importFiles() {
      this.setCancelButtonDisabled(true);
      this.spinnerEjecutar = true;
      this.disabledEjecutar = true;
      this.$emit("chron");

      let files = [];
      let emisorFileObject = null;
      let adquirienteFileObject = null;

      if (this.emisorCheck) {
        emisorFileObject = {
          file: this.emisorFile,
          versionable: this.emisorVersionable,
          versionName: this.emisorVersionName,
          client: this.projectSelected.project.client.name,
          type: "emisor",
        };
      }
      if (this.adquirienteCheck) {
        adquirienteFileObject = {
          file: this.adquirienteFile,
          versionable: this.adquirienteVersionable,
          versionName: this.adquirienteVersionName,
          client: this.projectSelected.project.client.name,
          type: "adquiriente",
        };
      }

      if (emisorFileObject != null) {
        files.push(emisorFileObject);
      }

      if (adquirienteFileObject != null) {
        files.push(adquirienteFileObject);
      }

      let noFiles = [];
      let emisorNoFileObject = null;
      let adquirienteNoFileObject = null;

      if (this.emisor != "") {
        emisorNoFileObject = {
          test: this.emisor,
          versionName: this.emisorClient,
          version: this.emisorClientVersion,
          client: this.projectSelected.project.client.name,
          type: "emisor",
        };
      }
      if (this.adquiriente != "") {
        adquirienteNoFileObject = {
          test: this.adquiriente,
          versionName: this.adquirienteClient,
          version: this.adquirienteClientVersion,
          client: this.projectSelected.project.client.name,
          type: "adquiriente",
        };
      }

      if (emisorNoFileObject != null) {
        noFiles.push(emisorNoFileObject);
      }

      if (adquirienteNoFileObject != null) {
        noFiles.push(adquirienteNoFileObject);
      }

      let adquiriente = "";
      let emisor = "";

      if (this.emisorChange == "Con cambios") {
        if (this.emisorFile.name.charAt(0) == "V") {
          emisor = "visanet";
        } else if (this.emisorFile.name.charAt(0) == "M") {
          emisor = "mds";
        } else if (this.emisorFile.name.includes('87')){
          emisor = "hiso87";
        } else if (this.emisorFile.name.includes('93')){
          emisor = "hiso93";
        }
      }
      if (this.adquirienteChange == "Con cambios") {
        if (this.adquirienteFile.name.charAt(0) == "V") {
          adquiriente = "visanet";
        } else if (this.adquirienteFile.name.charAt(0) == "M") {
          adquiriente = "mds";
        } else if(this.adquirienteFile.name.includes('87')){
          adquiriente = "hiso87";
        } else if(this.adquirienteFile.name.includes('93')){
          adquiriente = "hiso93";
        }
      }

      this.uploadFiles({
        files: files,
        noFiles: noFiles,
        emisor: emisor,
        adquiriente: adquiriente,
      })
        .then((response) => {
          if (response.message == "push") {
            if (response.versions.length > 0) {
              response.versions.forEach((element) => {
                if (element.type == "emisor") {
                  this.setEmisorVersion(element.version);
                } else {
                  this.setAdquirienteVersion(element.version);
                }
              });
            }
            this.setCancelButtonDisabled(false);
            this.ejecutarPipeline();
          }
        })
        .catch((error) => {
          console.error("Error en la carga de archivos:", error);
        })
        .finally(() => {
          this.spinnerEjecutar = false;
          this.disabledEjecutar = false;
        });
    },

    createdToken() {
      let url = "http://gitlab.kvz.cl";
      var data = new FormData();
      data.append("description", "new token");
      var config = {
        method: "post",
        url:
          url + "/api/v4/projects/" + this.projectSelected.id_git + "/triggers",
        headers: {
          "PRIVATE-TOKEN": "qbEbGSsJgf2os2nnHdJs",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          console.log("created token");
          this.getToken();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      let url = "http://gitlab.kvz.cl";
      var config = {
        method: "get",
        url:
          url + "/api/v4/projects/" + this.projectSelected.id_git + "/triggers",
        headers: {
          "PRIVATE-TOKEN": "qbEbGSsJgf2os2nnHdJs",
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.length <= 0) {
            this.createdToken();
          } else {
            this.ejecutarPipeline(response.data[0].token);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ejecutarPipeline() {
      let url = "http://gitlab.kvz.cl";
      let emisor = "";
      let adquiriente = "";

      if (this.selectedRadioBtn == "Pruebas") {
        if (this.adquirienteCheck) {
          if (this.adquirienteFile.name.charAt(0) == "V") {
            adquiriente = "visanet";
          } else if (this.adquirienteFile.name.charAt(0) == "M") {
            adquiriente = "mds";
          } else if(this.adquirienteFile.name.includes('87')){
            adquiriente = "hiso87";
          } else if(this.adquirienteFile.name.includes('93')){
            adquiriente = "hiso93";
          }
        } else {
          if (this.adquiriente.charAt(0) == "v") {
            adquiriente = "visanet";
          } else if (this.adquiriente.charAt(0) == "m") {
            adquiriente = "mds";
          } else if(this.adquiriente.includes('87')){
            adquiriente = "hiso87";
          } else if(this.adquiriente.includes('93')){
            adquiriente = "hiso93";
          }
        }

        if (this.emisorCheck) {
          if (this.emisorFile.name.charAt(0) == "V") {
            emisor = "visanet";
          } else if (this.emisorFile.name.charAt(0) == "M") {
            emisor = "mds";
          } else if(this.emisorFile.name.includes('87')){
            emisor = "hiso87";
          } else if(this.emisorFile.name.includes('93')){
            emisor = "hiso93";
          }
        } else {
          if (this.emisor.charAt(0) == "v") {
            emisor = "visanet";
          } else if (this.emisor.charAt(0) == "m") {
            emisor = "mds";
          } else if(this.emisor.includes('87')){
            emisor = "hiso87";
          } else if(this.emisor.includes('93')){
            emisor = "hiso93";
          }
        }
      }

      const data = {
        token: process.env.VUE_APP_PRIVATE_USER_TOKEN,
        // token: "glptt-0c7da73d2ffae4f68f7b86d52748c50696efd6c1",
        ref: this.branchSelected.branch,
        ACQUIRER: adquiriente,
        ISSUER: emisor,
        idProject: this.projectSelected.project.id_git,
        acquirerPort: this.adquirientePort,
        issuerPort: this.emisorPort,
        ip: this.ip,
      };

      this.executePipeline(data);
      this.$emit("getMySteps", this.projectSelected.project.id_git);
    },
    stopExecution() {
      this.spinnerStop = true;
      const data = {
        token: process.env.VUE_APP_PRIVATE_STOP_EXECUTION_TOKEN,
        // token: "glptt-0c7da73d2ffae4f68f7b86d52748c50696efd6c1",
        idProject: this.projectSelected.project.id_git,
        pipelineId: this.idPipeline,
      };
      this.cancelExecution(data).then((response) => {
        this.cleanForm();
        this.spinnerStop = false;
        this.spinnerEjecutar = false;
      });
    },
    getEpsStatus() {
      this.setCheckingEpsStatus(true);
      const data = {
        token: process.env.VUE_APP_PRIVATE_USER_TOKEN,
        // token: "glptt-0c7da73d2ffae4f68f7b86d52748c50696efd6c1",
        ref: "gestor-estado",
        idProject: process.env.VUE_APP_ID_PROJECT_GIT,
      };
      this.executePipeline(data);
      this.$emit("getMySteps", process.env.VUE_APP_ID_PROJECT_GIT);
    },
  },
  computed: {
    ...mapState("auth", ["loggedUser"]),
    ...mapState("user", ["branches"]),
    ...mapState("execution", [
      "executionRunning",
      "checkingEpsStatus",
      "adquirienteVersion",
      "emisorVersion",
      "cancelButtonDisabled",
    ]),
    ...mapState("google", ["emisorLoad", "adquirienteLoad"]),
    ...mapState("gitlab", ["epsStatus", "idPipeline"]),
    executionBtnDisabled() {
      let disabled = true;
      const errors = [];
      if (this.selectedRadioBtn == "Pruebas") {
        if (!this.stateProject) {
          errors.push("falta project");
        }
        if (!this.stateBranch) {
          errors.push("falta branch");
        }
        if (this.emisorChange == "Sin Cambios") {
          if (!this.stateEmisor) {
            errors.push("falta emisor");
          }
          if (!this.stateEmisorClient) {
            errors.push("faltan cliente emisor");
          }
          if (!this.stateEmisorClientVersion) {
            errors.push("faltan version cliente emisor");
          }
        } else {
          if (!this.stateEmisorFile) {
            errors.push("falta emisorFile");
          }
        }
        if (this.adquirienteChange == "Sin Cambios") {
          if (!this.stateAdquiriente) {
            errors.push("falta adquiriente");
          }
          if (!this.stateAdquirienteClient) {
            errors.push("faltan cliente adquiriente");
          }
          if (!this.stateAdquirienteClientVersion) {
            errors.push("faltan version cliente adquiriente");
          }
        } else {
          if (!this.stateAdquirienteFile) {
            errors.push("falta adquirienteFile");
          }
        }
        if (!this.stateIp) {
          errors.push("falta ip");
        }
        if (!this.stateEmisorPort) {
          errors.push("falta puerto emisor");
        }
        if (!this.stateAdquirientePort) {
          errors.push("falta puerto adquiriente");
        }
      } else {
        if (!this.stateProject) {
          errors.push("falta project");
        }
        if (!this.stateBranch) {
          errors.push("falta branch");
        }
      }

      if (errors.length == 0) {
        if (this.selectedRadioBtn == "Pruebas") {
          disabled = false;
        }
        if (this.selectedRadioBtn == "Ambiente") {
          disabled = false;
        }
      } else {
        disabled = true;
      }

      return disabled;
    },
    filteredBranches() {
      let branches = [];
      if (this.branches.length > 0) {
        branches = [...this.branches];

        if (this.selectedRadioBtn == "Pruebas") {
          branches = branches.filter(
            (branch) =>
              !branch.branch.includes("encender") &&
              !branch.branch.includes("apagar")
          );
        }

        if (this.selectedRadioBtn == "Ambiente") {
          branches = branches.filter(
            (branch) => !branch.branch.includes("ejecutar")
          );
        }
      } else {
        branches = [];
      }

      return branches;
    },
    stateProject() {
      if (this.projectSelected == "") {
        return null;
      } else if (this.projectSelected == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateBranch() {
      if (this.branchSelected == "") {
        return null;
      } else if (this.branchSelected == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateEmisorChange() {
      if (this.emisorChange == "Sin Cambios") {
        return null;
      } else if (this.emisorChange == "Con cambios") {
        return true;
      } else {
        return false;
      }
    },
    stateAdquirienteChange() {
      if (this.adquirienteChange == "Sin Cambios") {
        return null;
      } else if (this.adquirienteChange == "Con cambios") {
        return true;
      } else {
        return false;
      }
    },
    stateEmisor() {
      if (this.emisor == "") {
        return null;
      } else if (this.emisor == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateEmisorClient() {
      if (this.emisorClient == "") {
        return null;
      } else if (this.emisorClient == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateEmisorClientVersion() {
      if (this.emisorClientVersion == "") {
        return null;
      } else if (this.emisorClientVersion == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateAdquiriente() {
      if (this.adquiriente == "") {
        return null;
      } else if (this.adquiriente == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateAdquirienteClient() {
      if (this.adquirienteClient == "") {
        return null;
      } else if (this.adquirienteClient == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateAdquirienteClientVersion() {
      if (this.adquirienteClientVersion == "") {
        return null;
      } else if (this.adquirienteClientVersion == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateEmisorFile() {
      if (this.emisorFile == "") {
        return null;
      } else if (this.emisorFile == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateAdquirienteFile() {
      if (this.adquirienteFile == "") {
        return null;
      } else if (this.adquirienteFile == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateEmisorPort() {
      if (this.emisorPort == "") {
        return null;
      } else if (this.emisorPort == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateAdquirientePort() {
      if (this.adquirientePort == "") {
        return null;
      } else if (this.adquirientePort == undefined) {
        return false;
      } else {
        return true;
      }
    },
    stateIp() {
      if (this.ip == "") {
        return null;
      } else if (this.ip == undefined) {
        return false;
      } else {
        return true;
      }
    },
    emisorVersions() {
      const emisor = this.emisorLoad.find(
        (emisor) => emisor.name === this.emisorClient
      );

      if (emisor) {
        return emisor.subfolders;
      } else {
        return [];
      }
    },
    adquirienteVersions() {
      const adquiriente = this.adquirienteLoad.find(
        (adquiriente) => adquiriente.name === this.adquirienteClient
      );

      if (adquiriente) {
        return adquiriente.subfolders;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.step-progress__step-label {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 100;
  color: grey;
  transition: 0.3s ease;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
input[type="text"] {
  border: 1px solid #cccccc !important;
  padding: 8px;
}

/* input[type="text"]:focus {
    outline: none;
    box-shadow: none;
    border-color: #cccccc;
} */
input[type="text"]:disabled {
  opacity: 0.5 !important; /* Esto dará a los inputs un aspecto opaco */
  background-color: #e9e9e9 !important;
}
</style>
