<template>
  <div>
    <div class="modal fade" id="modalLoadProjects" tabindex="-1" role="dialog">
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Proyectos Asociados al Usuario:
              <strong>{{ selectedUser.email }}</strong>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table text-center table-sm">
                <thead>
                  <tr>
                    <th>Proyecto</th>
                    <th>ID</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(proj, index) in selectedUser.user_projects"
                    :key="index"
                  >
                    <td>{{ proj.project.name }}</td>
                    <td>{{ proj.project.id }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :class="{
                          'btn-warning': activeProjectId === proj.project.id,
                        }"
                        @click="showBranchesModal(proj.project.id)"
                      >
                        Ver Ramas
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="activeProjectId" class="mt-4">
              <h5>
                Ramas del proyecto: <b>{{ activeProjectName }}</b>
              </h5>
              <div v-if="projectBranches" class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-left">Rama</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="branch in activeBranches" :key="branch.id">
                      <td class="text-left">{{ branch.branch }}</td>
                      <td class="text-center">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteBranch(branch.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
              
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Modal-Load-Projects",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["selectedUser"]),
    ...mapState("project", [
      "userBranches",
      "activeProjectId",
      "projectBranches",
    ]),
    activeBranches() {
      if (this.activeProjectId && this.projectBranches[this.activeProjectId]) {
        return this.projectBranches[this.activeProjectId].branches;
      }
      return [];
    },
    activeProjectName() {
      const activeProject = this.selectedUser.user_projects.find(
        (proj) => proj.project.id === this.activeProjectId
      );
      return activeProject ? activeProject.project.name : "";
    },
  },

  methods: {
    ...mapActions("project", ["getUserBranches", "deleteUserBranch"]),
    showBranchesModal(projectId) {
      if (this.activeProjectId !== projectId) {
        this.$store.commit("project/setActiveProjectId", projectId);

        if (
          !this.projectBranches[projectId] ||
          !this.projectBranches[projectId].branches.length
        ) {
          this.getUserBranches({
            userId: this.selectedUser.id,
            projectId: projectId,
          });
        }
      } else {
        this.$store.commit("project/setActiveProjectId", null);
      }
    },
    deleteBranch(branchId) {
      if (confirm("¿Estás seguro de que deseas eliminar esta rama?")) {
        // this.deleteUserBranch({ branchId, projectId: this.activeProjectId });
        this.deleteUserBranch({ branchId: branchId, projectId: this.activeProjectId });

      }
    },
  },
};
</script>

<style></style>
