<template>
<li class="md-list-item">
    <router-link class="md-list-item-router md-list-item-container md-button-clean" @click="executionRunning ? null : hideSidebar()" :class="{ 'disabled-link': executionRunning }" v-bind="$attrs">
        <div class="md-list-item-content md-ripple">
            <slot>
                <md-icon>{{ link.icon }}</md-icon>
                <p>{{ link.name }}</p>
            </slot>
        </div>
    </router-link>
</li>
</template>

<script>
import { mapState } from 'vuex';
export default {
    inject: {
        autoClose: {
            default: true
        }
    },
    props: {
        link: {
            type: [String, Object],
            default: () => {
                return {
                    name: "",
                    path: "",
                    icon: ""
                };
            }
        },
        tag: {
            type: String,
            default: "router-link"
        }
    },
    methods: {
        hideSidebar() {
            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
        }
    },
    computed: {
        ...mapState("execution", ["executionRunning"]),
    }
};
</script>

<style>
.disabled-link {
    pointer-events: none;
    opacity: 0.6;
}
</style>
