<template>
<div class="container ">
    <br><br><br><br><br>
    <div class="wrapper fadeInDown">
        <div id="formContent">
            <div class="row">
                <!-- Icon -->
                <div class="col-12" style="text-align: center;">
                <img src="../../public/img/logo.png" alt="" class="nav-logo" />
                </div>
                <!-- Tabs Titles -->

                <div class="col-12 mt-2">
                    <h3 style="color: #070098; font-size: 18px;"><b>GESTOR DE PRUEBAS</b></h3>
                    <!-- <hr class="bg-info"> -->
                </div>
            </div>
            <br>
            <!-- Login Form -->
            <form @submit.prevent="validate" ref="contactForm" class="needs-validation">
                <input v-model="email" type="text" id="login" class="fadeIn second" name="login" placeholder="Correo Electrónico">
                <br>
                <input v-model="password" type="password" id="password" class="fadeIn third" name="login" placeholder="Contraseña">
                <br><br>
                <b>
                    <button type="submit" class="fadeIn fourth">
                        <span class="ml-1">Acceder</span> &nbsp;
                        <b-spinner
                            v-if="spinnerLogin"
                            small
                            variant="white"
                        ></b-spinner>

                    </button>
                    <!-- <button class="btn btn-form" @click="onSignIn"> Iniciar Sesión con Google</button> -->
                    <!-- <div id="gSignInWrapper">
                        <span class="label"></span>
                        <div @click="onSignIn" id="customBtn" class="customGPlusSignIn">
                            <span class="icon" style="width: 30px; height: 30px;"> <img src="../../../public/img/google-button-g.png" style="width: 30px; height: 30px;" alt=""></span>
                            <span class="buttonText">Iniciar Sesión con Google</span>
                        </div>
                    </div> -->
                </b>
            </form>
            <!-- Remind Passowrd -->
            <div id="formFooter">
                <p v-if="errors.length" class="text-left mt-2">
                    <ul>
                        <li style="color:red" v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
export default {
    name: "LoginComponent",
    mounted() {},
    watch: {

    },
    data() {
        return {
            email: "",
            password: "",
            errors: [],
            isLogin: false,
            userGoogle: "",
            user: "",
            spinnerLogin: false,
        };
    },

    methods: {
        async onSignIn() {
            const googleUser = await this.$gAuth.signIn();
            this.isLogin = this.$gAuth.isAuthorized;
            if (this.isLogin) {
                this.login(googleUser.getBasicProfile());
            }

        },
        validate() {
            // this.$bvToast.hide('errorsToast')
            this.errors = [];
            if (this.email.trim() == "") {
                this.errors.push("Debe ingresar un correo electrónico.");
            } else {
                if (!this.validEmail(this.email)) {
                    this.errors.push("El correo electrónico debe ser válido.");
                }
            }
            if (this.password.trim() == "") {
                this.errors.push("Debe ingresar la contraseña.");
            }
            if (this.errors.length == 0) {

                this.loginAttempt();
            } else {
                // this.$bvToast.show('errorsToast')
            }
        },
        validEmail: function (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        async loginAttempt() {
            this.spinnerLogin = true;

            try {
                const loginResult = await this.login({
                    email: this.email,
                    password: this.password
                });

                if(loginResult){
                    this.spinnerLogin = false;
                    this.$router.push('inicio/dashboard');
                }else{
                    this.spinnerLogin = false;
                    this.errors.push("Correo electrónico y/o contraseña incorrectos.")
                }   
                
            } catch (error) {
                this.spinnerLogin = false;
            }
        },
        ...mapActions('auth', ['login']),
    },
}
</script>

<style scoped>
/* BASIC */
#customBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 193px;
    /* border-radius: 5px; */
    border: thin solid #888;
    /* box-shadow: 1px 1px 1px grey; */
    white-space: nowrap;
    border-bottom-width: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-bottom: 11px;
}

#customBtn:hover {
    cursor: pointer;
}

span.label {
    font-family: serif;
    font-weight: normal;
}

span.icon {
    /* background: url('./../../../../src/public/img/google-button-g.png') transparent 3px 50% no-repeat; */
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 2px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
}

html {
    background-color: #56baed;
}

body {
    font-family: "Poppins", sans-serif;
    height: 100vh;
}

a {
    color: #92badd;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
}

h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
}

/* STRUCTURE */

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 90vh; /* Cambia el valor a tu preferencia, por ejemplo, 80% de la altura de la ventana */
    padding: 20px;
    margin: auto; /* Esto centra horizontalmente el contenido */
}


#formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
}

#formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: left;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
    color: #cccccc;
}

h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

button[type=button],
button[type=submit],
button[type=reset] {
    background-color: #56baed;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
    box-shadow: 0 5px 20px 0 rgba(11, 0, 212, 1);
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

button[type=button]:hover,
button[type=submit]:hover,
button[type=reset]:hover {
    background-color: #0b00d4;
}

button[type=button]:active,
button[type=submit]:active,
button[type=reset]:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

input[type=text],
input[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 70%;
    border: 2px solid #070098;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus,
input[type=password]:focus {
    background-color: #fff;
    border-bottom: 2px solid #070098;
}

input[type=text]:placeholder,
input[type=password] {
    color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
    background-color: #070098;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
}

.underlineHover:hover {
    color: #0d0d0d;
}

.underlineHover:hover:after {
    width: 100%;
}

/* OTHERS */

*:focus {
    outline: none;
}

#icon {
    width: 60%;
}
</style>
