<template>
<div class="container-fluid">
    <md-card>
        <md-card-header data-background-color="purple">
            <h4 class="title">Registro de Ambientes</h4>
            <p class="category">
               Aquí puedes predefinir ambientes de puertos y IP para utilizar al momento de realizar una ejecución de pruebas.
            </p>
        </md-card-header>

        <md-card-content>
           <div class="row">
        <div class="col-md-1" />
        <div class="col-md-2">
            <h5 class="text-center">Nuevo Ambiente</h5>
            <div class="col-md-12">
                <FormEnvironmentComponent/>
            </div>
        </div>
        <div class="col-md-8">
            <h5 class="text-center">Ambientes</h5>
            <div class="col-md-12">
                <TableEnvironmentsComponent/>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-spinner label="Cargando..." v-if="spinner==true" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </div>
    </div>
        </md-card-content>
    </md-card>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import FormEnvironmentComponent from "../../components/Forms/FormEnvironmentComponent.vue";
import TableEnvironmentsComponent from "../../components/Tables/TableEnvironmentsComponent.vue";
export default {
    components: {
        TableEnvironmentsComponent,
        FormEnvironmentComponent
    },
    mounted() {
        this.getEnvironments();
    },
    data() {
        return {
            spinner: false,
        };
    },
    methods: {
        ...mapActions('environment', ['getEnvironments']),
    },

};
</script>

<style>
</style>
