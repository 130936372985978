<template>
  <div class="content">
    <div class="row">
      <div class="col-8" v-if="loggedUser.user_projects">
        <div class="md-layout-item md-medium-size-100 md-size-100">
          <ExecutionTestingForm
            data-background-color="green"
            :btnHidden="btnHidden"
            :btnHiddenEjecutar="btnHiddenEjecutar"
            :projects="loggedUser.user_projects"
            :branchProjectSelected="branchProjectSelected"
            :spinnerEjecutarPadre="spinnerEjecutarPadre"
            :disabledEjecutarPadre="disabledEjecutarPadre"
            :canceled="canceled"
            :status="epsStatus"
            @getMySteps="getMySteps"
            @getPipeline="getPipeline"
            @valuesEmisorAdquiriente="valuesEmisorAdquiriente"
            @chron="chron"
            @cloneLogs="cloneLogs"
          />
        </div>

        <div class="md-layout-item md-medium-size-100 md-size-100">
          <LogTestingCard :jobsLogs="jobsLogs" />
        </div>
      </div>
      <div class="col-4">
        <div class="md-layout-item md-medium-size-100 md-size-100">
          <EpsStatusCardVue :status="epsStatus" />
        </div>
        <div class="md-layout-item md-medium-size-100 md-size-100">
          <DataExecutionCard
            :emisor="emisor"
            :adquiriente="adquiriente"
            :emisorFile="emisorFile"
            :adquirienteFile="adquirienteFile"
            :branchSelected="branchSelected"
          />
        </div>
        <!-- <div class="md-layout-item md-medium-size-100 md-size-100">
          <VersionRespository />
        </div> -->
        <div class="md-layout-item md-medium-size-100 md-size-100">
          <Chronmeter :resutTime="resutTime" />
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div v-if="stepOn == true">
        <VueStepProgressIndicator
          :steps="mySteps"
          :active-step="currentStep"
          :styles="styleData"
          :colors="colorData"
          :key="currentStep"
          @onEnterFinalStep="onEnterFinalStep"
        />
      </div>
    </div>
    <div
      v-if="idExecution"
      class="md-layout-item md-medium-size-100 md-size-100"
    >
      <ModalResultTransaccions
        data-target="#executionsSummary"
        :idExecution="idExecution"
        :idPipeline="idPipeline"
        @newExecution="newExecution"
      />
    </div>
  </div>
</template>

<script>
import ExecutionTestingForm from "../../components/Forms/ExecutionTestingForm.vue";
import DataExecutionCard from "../../components/Cards/DataExecutionCard.vue";
import VueStepProgressIndicator from "vue-step-progress-indicator";
import Chronmeter from "../../components/Cards/ChronometerCard.vue";
import LogTestingCard from "../../components/Cards/LogTestingCard.vue";
import ModalResultTransaccions from "../../components/Modal/ModalResultTransaccions.vue";
import EpsStatusCardVue from "../../components/Cards/EpsStatusCard.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Testing-Execution",
  components: {
    ExecutionTestingForm,
    DataExecutionCard,
    VueStepProgressIndicator,
    Chronmeter,
    LogTestingCard,
    ModalResultTransaccions,
    EpsStatusCardVue,
  },
  mounted() {
    /* this.getProjectFilterUser(); */
    this.setJobsLogs("");
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.disabledEjecutarPadre = false;
  },
  watch: {
    currentStep: function () {
      if (this.jobs.length == this.currentStep && this.switch == false) {
        if (!this.checkingEpsStatus) {
          if (this.executionMethod == "Pruebas") {
            this.cloneLogs();
          }
          if (this.executionMethod == "Ambiente") {
            this.setExecutionRunning(false);
            this.stepOn = false;
            clearInterval(this.chronmet);
            this.getEpsStatus();
          }
        } else {
          this.setCheckingEpsStatus(false);
          this.stepOn = false;
          this.setJobsLogs("");
          this.switch = true;
          clearInterval(this.interval);
          this.mySteps = [];
          this.cont = 0;
          this.currentStep = 0;
          this.resutTime = "00:00:00";
        }
      }
    },
  },
  data() {
    return {
      notiDate: new Date(),
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
      btnHidden: true,
      btnHiddenEjecutar: false,
      idExecution: "",
      projects: "",
      projectSelected: {},
      resutTime: "00:00:00",
      crono: 0,
      chronmet: "",
      projectsApi: null,
      projectApiSelected: null,
      branchProjectSelected: null,
      branchSelected: "",
      canceled: false,
      pipeline: "",
      mySteps: [],
      currentStep: 0,
      interval: "",
      flag: 0,
      cont: 0,
      icon: "",
      emisor: "",
      adquiriente: "",
      emisorFile: "",
      adquirienteFile: "",
      stepOn: false,
      spinnerEjecutarPadre: "",
      disabledEjecutarPadre: "",
      switch: false,
      styleData: {
        progress__wrapper: {
          display: "flex -ms-flexbox ",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          margin: "1rem 0",
        },
        progress__block: {
          display: "flex",
          alignItems: "center",
        },
        progress__bridge: {
          backgroundColor: "grey",
          height: "2px",
          flexGrow: "1",
          width: "20px",
        },
        progress__bubble: {
          margin: "0",
          padding: "0",
          lineHeight: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30px",
          width: "30px",
          borderRadius: "100%",
          backgroundColor: "transparent",
          border: "2px grey solid",
          textAlign: "center",
        },
        progress__label: {
          margin: "0 0.8rem",
        },
      },
      colorData: {
        progress__bubble: {
          active: {
            color: "#fff",
            backgroundColor: "#e74c3c",
            borderColor: "#e74c3c",
          },
          inactive: {
            color: "#fff",
            backgroundColor: "#34495e",
            borderColor: "#34495e",
          },
          completed: {
            color: "#fff",
            borderColor: "#27ae60",
            backgroundColor: "#27ae60",
          },
        },
        progress__label: {
          active: {
            color: "#e74c3c",
          },
          inactive: {
            color: "#34495e",
          },
          completed: {
            color: "#27ae60",
          },
        },
        progress__bridge: {
          active: {
            backgroundColor: "#e74c3c",
          },
          inactive: {
            backgroundColor: "#34495e",
          },
          completed: {
            backgroundColor: "#27ae60",
          },
        },
      },
    };
  },
  methods: {
    ...mapMutations("gitlab", ["setJobs", "setJobsLogs"]),
    ...mapMutations("execution", [
      "setExecutionRunning",
      "setCheckingEpsStatus",
      "setEmisorVersion",
      "setAdquirienteVersion",
    ]),
    ...mapActions("gitlab", [
      "getJobsProject",
      "getJobsLogs",
      "doCloneLogs",
      "executePipeline",
    ]),
    removeNotification(timestamp) {
      this.$notifications.removeNotification(timestamp);
    },
    notifyVue(verticalAlign, horizontalAlign, time) {
      var color = "warning";
      this.$notify({
        message: "PROCESANDO TRANSACCIONES Y DESCARGANDO TRACES...",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
        timeout: time,
        timestamp: this.notiDate,
      });
    },
    cloneLogs() {
      this.stepOn = false;
      this.notifyVue("bottom", "right", 2147483647);

      let data = {
        time: this.resutTime,
        project: this.selectedProject.project.id_git,
        branch: this.branchSelected,
        user: this.loggedUser.id,
        ad: this.adquiriente != "" ? this.adquiriente : this.adquirienteFile,
        countJobs: this.jobs.length,
        idPipeline: this.idPipeline,
        adVersion: this.adquirienteVersion,
        emVersion: this.emisorVersion,
      };

      this.doCloneLogs(data).then((response) => {
        this.removeNotification(this.notiDate);
        if(response == undefined){
          this.newExecution();
          this.$swal({
            title:
              "Error durante el procesamiento de los logs.",
            icon: "error",
            showConfirmButton: true,
          });
        }else if (response.message == "error al generar trace") {
          this.$swal({
            title:
              "Error Base24 001, Pruebas NO Ejecutadas, por favor comuniquese con el administrador.",
            icon: "danger",
            showConfirmButton: true,
          });
          clearInterval(this.chronmet);
        } else if (response.message == "compilacion") {
          this.$swal({
            title: "Compilación Finalizada",
            icon: "success",
            showConfirmButton: true,
          });
          clearInterval(this.chronmet);
        } else {
          this.setExecutionRunning(false);
          this.setAdquirienteVersion("");
          this.setEmisorVersion("");
          this.$swal({
            title: "¡Prueba Exitosa!",
            html: '<button id="verMas" class="swal2-confirm swal2-styled">Ver más detalles</button>',
            icon: "success",
            showConfirmButton: false,
            onOpen: () => {
              document
                .getElementById("verMas")
                .addEventListener("click", () => {
                  this.$router.push({
                    name: "Result Logger",
                  });
                  this.$swal.close();
                });
            },
          });
          clearInterval(this.chronmet);
        }
      });
    },
    newExecution() {
      clearInterval(this.interval);
      clearInterval(this.chronmet);
      Object.assign(this.$data, this.$options.data.call(this));
      this.switch = true;
      this.setJobsLogs("");
      this.setExecutionRunning(false);
    },
    valuesEmisorAdquiriente(eF, aF, b, e, a) {
      this.emisorFile = eF;
      this.adquirienteFile = aF;
      this.branchSelected = b;
      this.emisor = e;
      this.adquiriente = a;
    },
    onEnterFinalStep() {
      this.stepOn = false;
    },
    chron() {
      this.chronmet = setInterval(() => {
        this.cronometro();
      }, 1000);
    },
    cronometro() {
      this.secondsToString(this.crono++);
    },
    secondsToString(seconds) {
      let hour = Math.floor(seconds / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      let minute = Math.floor((seconds / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      let second = seconds % 60;
      second = second < 10 ? "0" + second : second;
      this.resutTime = hour + ":" + minute + ":" + second;
    },
    getMySteps(projectId) {
      this.jobs != "" ? this.setJobs("") : "";
      this.canceled = false;
      this.interval = setInterval(async () => {
        await this.getJobsProject({
          idProject: projectId,
          pipelineId: this.idPipeline,
        });
        if (this.jobs) {
          this.setJobs(this.jobs.reverse());
          for (let index = this.cont; index < this.jobs.length; index++) {
            const jobs = this.jobs[index];
            this.mySteps[index] = jobs.name;

            switch (jobs.status) {
              case "success":
                await this.getJobsLogs({
                  idProject: this.idGitProject,
                  jobId: jobs.id,
                });
                this.spinnerEjecutarPadre = false;
                this.stepOn = true;
                this.currentStep++;
                this.switch = false;
                this.cont++;
                if (jobs.name == "Clean-Source") {
                  jobs.status = "success";
                }
                if (this.currentStep == this.jobs.length) {
                  clearInterval(this.interval);
                  this.disabledEjecutarPadre = false;
                }
                break;
              case "pending":
                this.spinnerEjecutarPadre = false;
                this.stepOn = true;
                this.colorData.progress__label.active.color = "#fd7e14";
                this.colorData.progress__bubble.active.backgroundColor =
                  "#fd7e14";
                this.colorData.progress__bubble.active.borderColor = "#fd7e14";
                break;
              case "running":
                await this.getJobsLogs({
                  idProject: this.idGitProject,
                  jobId: jobs.id,
                });
                this.spinnerEjecutarPadre = false;
                this.stepOn = true;
                this.colorData.progress__label.active.color = "#158cba";
                this.colorData.progress__bubble.active.backgroundColor =
                  "#158cba";
                this.colorData.progress__bubble.active.borderColor = "#158cba";
                break;
              case "skipped":
                this.spinnerEjecutarPadre = false;
                this.stepOn = true;
                this.currentStep++;
                this.switch = false;
                this.cont++;
                if (jobs.name == "Clean-Source") {
                  jobs.status = "success";
                }
                if (this.currentStep == this.jobs.length) {
                  clearInterval(this.interval);
                  this.disabledEjecutarPadre = false;
                }
                break;
              case "failed":
                this.spinnerEjecutarPadre = false;
                this.stepOn = true;
                this.currentStep++;
                this.switch = false;
                this.cont++;
                this.colorData.progress__label.active.color = "#333";
                this.colorData.progress__bubble.active.backgroundColor = "#333";
                this.colorData.progress__bubble.active.borderColor = "#333";
                if (jobs.stage == "Stop-Eps") {
                  jobs.status = "success";
                }
                if (jobs.stage == "Start-Eps") {
                  jobs.status = "success";
                }
                break;
              case "canceled":
                this.canceled = true;
                this.$swal({
                  title: "Ejecución Cancelada",
                  icon: "error",
                  timer: 25000,
                  showConfirmButton: true,
                });
                this.colorData.progress__label.active.color = "#e51b1b";
                this.colorData.progress__bubble.active.backgroundColor =
                  "#e51b1b";
                this.colorData.progress__bubble.active.borderColor = "#e51b1b";
                this.newExecution();
                break;
              default:
                break;
            }
          }
        }
      }, 3000);
    },
    getPipeline(id) {
      axios
        .get(`http://localhost:3000/api/gitlab/pipelines/${id}`)
        .then((response) => {
          this.pipelinesProject = response.data;
          this.idPipeline = this.pipelinesProject[0].id;
        });
    },
    async getEpsStatus() {
      this.stepOn = false;
      this.setJobsLogs("");
      clearInterval(this.interval);
      this.mySteps = [];
      this.cont = 0;
      this.currentStep = 0;
      await this.setCheckingEpsStatus(true);
      const data = {
        token: process.env.VUE_APP_PRIVATE_USER_TOKEN,
        ref: "gestor-estado",
        idProject: process.env.VUE_APP_ID_PROJECT_GIT,
      };
      await this.executePipeline(data);
      this.getMySteps(process.env.VUE_APP_ID_PROJECT_GIT);
    },
  },
  computed: {
    ...mapState("auth", ["loggedUser"]),
    ...mapState("gitlab", [
      "pipelinesProject",
      "idPipeline",
      "jobs",
      "idGitProject",
      "jobsLogs",
      "selectedProject",
      "epsStatus",
    ]),
    ...mapState("execution", ["executionMethod", "checkingEpsStatus", "adquirienteVersion", "emisorVersion"]),
  },
};
</script>
