import ls from 'localstorage-slim';

ls.config.encrypt = false;
const designStore = {
    namespaced: true,
    state: {
      color: 'purple',
    },
    mutations: {
      setDesign(state, color) {
        state.color = color;
      },
    },
    actions: {
      
    },
    getters: {
      getDesign: (state) => state.color,
    },
  };
  
  export default designStore;
  
