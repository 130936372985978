import axios from 'axios';
import ls from 'localstorage-slim';

const routeServer = process.env.VUE_APP_ROUTE_SERVER;

const getToken = () => {
  if (ls.get('user')) {
    const token = ls.get('token');
    return token;
  }
  return 1;
};
export const HttpClient = {
  get: (route) => axios({
    method: 'get',
    url: routeServer + route,
    headers: { Authorization: `Bearer ${getToken()}` },
  })
    .then((response) => response)
    .catch((error) => error),
  post: (route, data) => axios({
    method: 'post',
    url: routeServer + route,
    headers: { Authorization: `Bearer ${getToken()}` },
    data,
  })
    .then((response) => response)
    .catch((error) => error),
  put: (route, data) => axios({
    method: 'put',
    url: routeServer + route,
    headers: { Authorization: `Bearer ${getToken()}` },
    data,
  })
    .then((response) => response)
    .catch((error) => error),
  patch: (route, data) => axios({
    method: 'patch',
    url: routeServer + route,
    headers: { Authorization: `Bearer ${getToken()}` },
    data,
  })
    .then((response) => response)
    .catch((error) => error),
  delete: (route, id) => axios({
    method: 'delete',
    url: routeServer + route,
    headers: { Authorization: `Bearer ${getToken()}` },
  })
    .then((response) => response)
    .catch((error) => error),
};

export default { HttpClient };