import Vue from "vue";
import VueRouter from "vue-router";
import store from "../storage";
import isAuthenticatedGuard from './auth-guard';
Vue.use(VueRouter);
//Bloque para importar componentes
import Landing from "../views/Landing";
import Login from "../views/Login";
import NotFoundProjectComponent from "../components/NotFoundProjectComponent";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Dashboard from "../views/Dashboard.vue";
import UserProfile from "../views/UserProfile.vue";
import TableList from "../views/TableList.vue";
import Typography from "../views/Typography.vue";
import Icons from "../views/Icons.vue";
import Notifications from "../views/Notifications.vue";

import TestingExecution from "../views/TestingExecution/TestingExecution.vue";
import ResultLogger from "../views/ProcessorLogger/ResultLogger.vue";
import ProjectMaintainer from "../views/Maintainers/ProjectMaintainer";
import UserMaintainer from "../views/Maintainers/UserMaintainer";
import ClientMaintainer from "../views/Maintainers/ClientMaintainer";
import EnvironmentMaintainer from "../views/Maintainers/EnvironmentMaintainer";
import Reports from "../views/Reports/Reports";
import TestRepository from "../views/TestRepository/TestRepository";

//Bloque para rutas
const routes = [
    {
        path: "/",
        redirect: "/acceder"
    },
    {
        name: "login",
        path: "/acceder",
        component: Login,
        meta: {
            auth: false
        }
    },
    {
        name: "notfound",
        path: "/notfound",
        component: NotFoundProjectComponent,
        meta: {
            auth: false
        }
    },
    {
        name: "home",
        path: "/inicio",
        component: DashboardLayout,
        meta: {
            auth: true
        },
        children: [
            {
                path: "reportes",
                name: "reports",
                component: Reports,
                meta: {
                    auth: true
                }
            },
            {
                path: "mantenedor/usuarios",
                name: "maintainer-users",
                component: UserMaintainer,
                meta: {
                    auth: true
                }
            },
            {
                path: "mantenedor/proyectos",
                name: "maintainer-project",
                component: ProjectMaintainer,
                meta: {
                    auth: true
                }
            },
            {
                path: "mantenedor/clientes",
                name: "maintainer-clients",
                component: ClientMaintainer,
                meta: {
                    auth: true
                }
            },
            {
                path: "mantenedor/ambientes",
                name: "maintainer-environments",
                component: EnvironmentMaintainer,
                meta: {
                    auth: true
                }
            },
            {
                // path: "result/logger/:data",
                path: "resultado/logger/",
                name: "Result Logger",
                component: ResultLogger,
                props: true
            },
            {
                path: "testing/execution",
                name: "Testing Execution",
                component: TestingExecution,
                meta: {
                    auth: true
                }
            },
            ,
            {
                path: "test/repository",
                name: "Test Repository",
                component: TestRepository,
                meta: {
                    auth: true
                }
            },
            {
                path: "dashboard",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    auth: true
                }
            },
            /*  {
                path: "user",
                name: "User Profile",
                component: UserProfile,
                meta: {
                    auth: true
                }
            }, */
            {
                path: "table",
                name: "Table List",
                component: TableList,
                meta: {
                    auth: true
                }
            },
            {
                path: "typography",
                name: "Typography",
                component: Typography,
                meta: {
                    auth: true
                }
            },
            {
                path: "icons",
                name: "Icons",
                component: Icons,
                meta: {
                    auth: true
                }
            },
            {
                path: "notifications",
                name: "Notifications",
                component: Notifications,
                meta: {
                    auth: true
                }
            }
        ]
    }
];
const router = new VueRouter({
    mode: "history",
    routes: routes,
    linkExactActiveClass: "nav-item active"
});

  router.beforeEach(async (to, from, next) => {
    const authorized = await isAuthenticatedGuard();
  
    if (to.meta.auth && !authorized) {
      next({ name: 'login' });
    } else {
      next();
    }
  });

export default router;
