<template>
  <div class="content">
    <div class="md-layout-item md-small-size-100 md-size-100">
      <label><b>Proyecto</b></label>
      <md-field>
        <b-form-select
          class="form form-control"
          v-model="projectSelected"
          @input="getExecutions"
        >
          <option disabled value="" selected="selected">Seleccione</option>
          <option
            v-bind:key="index"
            v-bind:value="p.project"
            v-for="(p, index) in loggedUser.user_projects"
          >
            {{ p.project.name }}
          </option>
        </b-form-select>
        <b-form-invalid-feedback>
          Seleccione una Proyecto.
        </b-form-invalid-feedback>
      </md-field>
    </div>
    <div v-if="executions.length > 0" class="md-layout">
      <div v-if="projectSelected && lastExecution" class="col-md-4">
        <b-card
          id="1card"
          title="Title"
          header-tag="header"
          footer-tag="footer"
          no-body
          class="col- md-3 p-1 bg-white border-1 shadow-sm"
        >
          <template #header>
            <h6 class="mb-0">Ultima Ejecución</h6>
          </template>
          <DataLastExecutionCard
            :lastExecution="lastExecution"
            :projectSelected="projectSelected"
          />
          <template #footer>
            <button
              type="button"
              class="btn btn-primary"
              @click="push"
              style="padding-left: 12px; margin-left: 320px"
            >
              Nueva Ejecución
            </button>
            <!--   <md-button class="md-raised md-success" >
            </md-button> -->
          </template>
        </b-card>
      </div>
      <div v-if="pieChartData && projectSelected" class="col-md-4">
        <b-card
          title="Title"
          header-tag="header"
          footer-tag="footer"
          no-body
          class="col- md-3 p-1 bg-white border-1 shadow-sm"
        >
          <template #header>
            <h6 class="mb-0">Transacciones Ejecutadas</h6>
          </template>
          <GChart
            style="width: 100%; height: 500%"
            type="PieChart"
            :data="pieChartData"
            :options="pieChartOptions"
            :resizeDebounce="0"
          />
          <template #footer>
            <em>
              <i class="fa fa-circle text-warning"></i> Sin Respuesta
              <i class="fa fa-circle text-danger"></i> Rechazadas
              <i class="fa fa-circle text-success"></i> Aprobadas
            </em>
          </template>
        </b-card>
      </div>
      <div class="col-md-4" v-if="executionsChart && projectSelected">
        <b-card
          title="Title"
          header-tag="header"
          footer-tag="footer"
          no-body
          class="col- md-3 p-1 bg-white border-1 shadow-sm"
        >
          <template #header>
            <h6 class="mb-0">Ejecuciones de Pruebas</h6>
          </template>
          <GChart
            style="width: 100%; height: 200%"
            type="ColumnChart"
            :data="executionsChart"
            :options="executionOptions"
            :resizeDebounce="0"
          />
          <template #footer>
            <em>
              <i class="fa fa-circle color:blue"></i> Diarias
              <!--  <i class="fa fa-circle text-danger"></i> Semanales
                        <i class="fa fa-circle text-success"></i> Mensuales --></em
            >
          </template>
        </b-card>
      </div>
      <div
        v-if="projectSelected"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <StatsCard data-background-color="green">
          <template slot="header">
            <md-icon>check_circle</md-icon>
          </template>

          <template slot="content">
            <h5 class="category"><b>Aprobadas</b></h5>
            <h3 class="title">{{ totalApproved() }}</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>check_circle</md-icon>
              Transacciones Totales Aprobadas
            </div>
          </template>
        </StatsCard>
      </div>

      <div
        v-if="projectSelected"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <StatsCard data-background-color="orange">
          <template slot="header">
            <md-icon>rule</md-icon>
          </template>
          <template slot="content">
            <h5 class="category"><b>Sin Respuesta</b></h5>
            <h3 class="title">{{ totalTimeout() }}</h3>
            <!-- <small>GB</small> -->
          </template>
          <template slot="footer">
            <div class="stats">
              <md-icon>rule</md-icon>
              Transacciones Totales Sin Respuesta
            </div>
          </template>
        </StatsCard>
      </div>
      <div
        v-if="projectSelected"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <StatsCard data-background-color="red">
          <template slot="header">
            <md-icon>info_outline</md-icon>
          </template>
          <template slot="content">
            <h5 class="category"><b>Rechazadas</b></h5>
            <h3 class="title">{{ totalRejected() }}</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>info_outline</md-icon>
              Transacciones Totales Rechazadas
            </div>
          </template>
        </StatsCard>
      </div>
      <div
        v-if="projectSelected"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <StatsCard data-background-color="blue">
          <template slot="header">
            <md-icon>fact_check</md-icon>
          </template>

          <template slot="content">
            <h5 class="category"><b>Total</b></h5>
            <h3 class="title">{{ totalFinal() }}</h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>fact_check</md-icon>
              Transacciones Totales
            </div>
          </template>
        </StatsCard>
      </div>
      <div
        v-if="executions"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="purple">
            <h4 class="title">Ejecuciones</h4>
          </md-card-header>
          <md-card-content>
            <ExecutionsTable
              table-header-color="purple"
              @getExecutions="getExecutions"
              :executions="executions"
              :tableSize="4"
            ></ExecutionsTable>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div v-else-if="projectSelected != '' && !load" class="text-center"><h4>No hay datos para el proyecto seleccionado.</h4></div>
    <div v-if="load" class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="2"
        aria-valuemax="100"
        :style="loading"
      ></div>
    </div>
  </div>
</template>

<script>
import ExecutionsTable from "../components/Tables/ExecutionsTable.vue";
import DataLastExecutionCard from "../components/Cards/DataLastExecutionCard.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { StatsCard } from "../components";

export default {
  name: "DashboardComponent",
  components: {
    StatsCard,
    ExecutionsTable,
    DataLastExecutionCard,
  },
  created() {
    if (this.loggedUser.type == 1) {
      this.$router.push({
        name: "maintainer-users",
      });
    }
  },
  mounted() {
    this.setExecutions([]);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    projects: function () {
      this.projectSelected = this.projects[0];
    },
  },
  data() {
    return {
      lastExecution: "",
      interval: "",
      loading: "",
      load: false,
      executionsChart: "",
      summaryTotal: "",
      dataProject: [],
      projects: "",
      projectSelected: "",
      spinner: false,
      pieChartData: "",
      pieChartOptions: {
        chart: {},
        title: "",
        pieHole: 0.4,
        legend: {
          position: "labeled",
        },
        slices: {
          0: {
            color: "#28b62c",
          },
          1: {
            color: "#fb8c00",
          },
          2: {
            color: "#ff5733",
          },
        },
      },
      executionOptions: {
        // width: 700,
        /*  legend: {
                     position: 'a'
                 }, */
        /*    chart: {
                    title: 'Chess opening moves',
                    subtitle: 'popularity by percentage'
                },
                title: 'Nearby galaxies - distance on the left, brightness on the right',
          vAxes: {
            // Adds titles to each axis.
            0: {title: 'parsecs'},
            1: {title: 'apparent magnitude'}
          },
                axes: {
                    x: {
                        0: {
                            side: 'top',
                            label: 'White to move'
                        } // Top x-axis.
                    }
                }, */
        bar: {
          groupWidth: "60%",
        },
      },
    };
  },
  methods: {
    ...mapActions("execution", ["getUserExecutions"]),
    ...mapMutations("execution", ["setExecutions"]),
    totalApproved() {
      let count = 0;

      this.executions.forEach((element) => {
        count += element.transactionSummary.approved;
      });

      return count;
    },
    totalRejected() {
      let count = 0;

      this.executions.forEach((element) => {
        count += element.transactionSummary.rejected;
      });

      return count;
    },
    totalTimeout() {
      let count = 0;

      this.executions.forEach((element) => {
        count += element.transactionSummary.missing;
      });

      return count;
    },
    totalFinal() {
      let count = 0;

      this.executions.forEach((element) => {
        count += element.transactionSummary.total;
      });

      return count;
    },
    makeColumnData() {
      const date = new Date();
      this.executionsChart = [["Fecha", "Ejecuciones"]];

      for (let i = 0; i < 5; i++) {
        const currentDate = new Date(date);
        currentDate.setDate(currentDate.getDate() - i);
        const formattedDate = currentDate.toISOString().slice(0, 10);

        const filteredExecutions = this.executions.filter((execution) => {
          const executionDate = new Date(execution.created_at);
          const exDate = `${executionDate.getFullYear()}-${(
            executionDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${executionDate
            .getDate()
            .toString()
            .padStart(2, "0")}`;
          return exDate === formattedDate;
        });

        this.executionsChart.push([formattedDate, filteredExecutions.length]);
      }
    },
    makeChartData() {
      let timeout = 0;
      let rejected = 0;
      let approved = 0;

      this.executions.forEach((element) => {
        timeout += element.transactionSummary.missing;
        rejected += element.transactionSummary.rejected;
        approved += element.transactionSummary.approved;
      });

      this.pieChartData = [
        ["Txns", "Resultado"],
        ["Aprobadas", approved],
        ["Rechazadas", rejected],
        ["Sin Respuesta", timeout],
      ];
    },
    getExecutions(page = 1) {
      this.getLoading();
      let data = {
        idProject: this.projectSelected != "" ? this.projectSelected.id : "",
        idUser: this.loggedUser.id,
        page: page,
        dash: "dash",
      };

      this.getUserExecutions(data).then((response) => {
        this.load = false;
        this.lastExecution = this.executions[0];
        this.makeChartData();
        this.makeColumnData();
      });
    },
    push() {
      this.$router.push({
        name: "Testing Execution",
      });
    },
    getLoading() {
      this.load = true;
      this.loading = "";
      let cont = 0;
      this.interval = setInterval(() => {
        cont = cont + 10;
        this.loading = "width:" + cont + "%";
      }, 1000);
    },
    getProjectFilterUser() {
      axios
        .get("/projects/filter/user", {
          params: {
            idUser: this.loggedUser.id,
          },
        })
        .then((response) => {
          this.projects = response.data;
        });
    },
  },
  computed: {
    ...mapState("auth", ["loggedUser"]),
    ...mapState("execution", ["executions"]),
  },
};
</script>

<style scoped>
.md-card {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 25px 0;
  overflow: unset;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  transition-property: color, background-color;
  will-change: color, background-color;
}

.md-card .md-card-header {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  margin: -20px 15px 0;
  border-radius: 3px;
  padding: 15px;
  background-color: #999999;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  padding-top: 0px;
  padding-bottom: 1px;
  background-color: white;
  border-top: white;
  /* margin-top: -22px; */
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #f0f0f0;
  border-radius: 0.25rem;
  margin-top: 350px;
}
</style>
