<template>
<md-card class="md-card-profile">
    <div class=""></div>

    <md-card-content>
        <h6 class="category text-gray">Gestor de Pruebas</h6>
        <h4 class="card-title">Parametros Seleccionados</h4>
        <ul class="text-left">
            <li><b>Proyecto:</b> {{(selectedProject.project)?selectedProject.project.name:""}}</li>
            <li><b>Rama:</b> {{ branchSelected }}</li>
            <li><b>Emisor:</b> {{ (emisor!="")?emisor:emisorFile }}</li>
            <li><b>Adquiriente:</b> {{ (adquiriente!="")?adquiriente:adquirienteFile }}</li>
        </ul>
        <em>
            <i class="fa fa-circle text-secondary"></i> No Ejecutado 
            <i class="fa fa-circle text-warning"></i> Pendiente
            <i class="fa fa-circle text-info"></i> Ejecutandose
            <i class="fa fa-circle text-success"></i> Finalizado
        </em>
    </md-card-content>
</md-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "data-testing-card",
    props: {
        logoKvz: {
            type: String,
            default: require("../../assets/imgKvz/logo.png")
        },
        emisor: String,
        emisorFile: String,
        adquiriente: String,
        adquirienteFile: String,
        branchSelected: String,
    },
    data() {
        return {};
    },
    computed:{
        ...mapState('gitlab',['selectedProject'])
    }
};
</script>

<style scoped>
.md-card-content {
    font-size: 16px;
    line-height: 22px;
}
.text-secondary{
    color: #34495e !important;
}
</style>
