<template>
<b-navbar toggleable="sm" variant="light" type="light">
    <b-container v-if="loggedUser">   
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>           
            <b-navbar-nav class="ml-auto">
                <b-sidebar backdrop bg-variant="dark" text-variant="light" id="sidebar-right" right shadow>
                    <div class="text-center">
                        <p class="h5">Gestor de Pruebas</p>
                        <b-avatar badge-variant="light" style="border:2px solid #fff" class="my-3" size="120">
                            <template #badge>
                                <font-awesome-icon icon="camera" />
                            </template>
                        </b-avatar>
                        <p class="h4 font-weight-bold">{{ loggedUser.name }}</p>
                        <p class="h6">{{ loggedUser.email }}</p>
                    </div>
                    <div class="px-3 py-2">
                        <b-button variant="light" @click="closeSesion()" block pill>
                            <font-awesome-icon icon="sign-out-alt" /> Cerrar Sesión</b-button>
                    </div>
                </b-sidebar>
            </b-navbar-nav>
        </b-collapse>
    </b-container>
</b-navbar>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            selectedEmployee: null,           
        };
    },
    methods: {
        ...mapMutations('auth', ['logout']),
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        closeSesion() {
            if (this.loggedUser) {
                this.logout();
                /* this.loggedUser = null; */
                /* this.$router.push('/acceder'); */
            }
        }
    },
    computed: {
        ...mapState('auth', ['loggedUser','token']),
    }
};
</script>

<style lang="css"></style>
