<template>
  <div>
    <div
      class="modal fade"
      id="executionsSummary"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><b>Detalle de Transacciones</b></h4>
            <h4 class="modal-title"><b>Pipeline ID:</b> {{ idPipeline }}</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-md-left">
              <table
                v-if="execution"
                class="table text-center table-sm table-hover"
              >
                <thead>
                  <tr>
                    <th scope="col">Fecha Ejecución</th>
                    <th scope="col">Num Txn</th>
                    <th scope="col">MType</th>
                    <th scope="col">MTypeR</th>
                    <th scope="col">E. RC</th>
                    <th scope="col">RC</th>
                    <th scope="col">Resultado</th>
                    <th scope="col">W/E</th>
                    <th scope="col">Trama</th>
                    <th scope="col">Trama Resp.</th>
                    <th scope="col">Trace</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-bind:key="ind"
                    v-for="(t, ind) in execution.transactionSummary
                      .transactions"
                    style="font-size: small; color: black"
                  >
                    <td>{{ execution.transactionSummary.date_execution }}</td>
                    <td>{{ t.header }}</td>
                    <td>{{ t.m_type }}</td>
                    <td>{{ t.m_type_res }}</td>
                    <td
                      :style="`${
                        t.expected_rc == '' ? 'color:#00bcd4' : 'color:green'
                      }`"
                    >
                      <b>{{ t.expected_rc == "" ? "-" : t.expected_rc }}</b>
                    </td>
                    <td
                      :style="`${
                        t.result == 1
                          ? 'color:green'
                          : t.result == 3
                          ? 'color:#00bcd4'
                          : 'color:red'
                      }`"
                    >
                      <b>{{ t.rc == "" ? "-" : t.rc }}</b>
                    </td>
                    <td v-if="t.result == 1">
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-success"
                        size="1x"
                      />
                    </td>
                    <td v-else-if="t.result == 0">
                      <font-awesome-icon
                        class="text-danger"
                        icon="times-circle"
                        size="1x"
                      /><br />
                    </td>
                    <td v-else>
                      <font-awesome-icon
                        icon="tools"
                        class="text-info"
                        size="1x"
                      /><br />
                    </td>
                    <td v-if="t.error == null">
                      <font-awesome-icon
                        data-toggle="modal"
                        data-target="#modalWarning"
                        icon="exclamation-triangle"
                        class="text-warning"
                        @click="loadWarningError(t)"
                        size="1x"
                      /><br />
                    </td>
                    <td v-else-if="t.error != '' && t.error != '-'">
                      <font-awesome-icon
                        icon="bug"
                        class="text-danger"
                        size="1x"
                        data-toggle="modal"
                        data-target="#modalWarning"
                        @click="loadWarningError(t)"
                      /><br />
                    </td>
                    <td v-else style="color: #00bcd4">-</td>
                    <td>
                      <font-awesome-icon
                        icon="list-ol"
                        class="text-secondary"
                        size="1x"
                        data-toggle="modal"
                        data-target="#modalTrama"
                        @click="loadTrama(t.trama)"
                      />
                    </td>
                    <td>
                      <font-awesome-icon
                        icon="list-ol"
                        class="text-secondary"
                        size="1x"
                        data-toggle="modal"
                        data-target="#modalTramaResponse"
                        @click="loadTramaResponse(t.trama_response)"
                      />
                    </td>
                    <td>
                      <font-awesome-icon
                        icon="download"
                        class="text-primary"
                        size="1x"
                        @click="dloadTrace(t.ts_id, t.header)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="newE"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalTrama data-target="#modalTrama" :trama="trama"></ModalTrama>
    <ModalTramaResponse
      data-target="#modalTramaResponse"
      :tramaResponse="tramaResponse"
    ></ModalTramaResponse>
    <ModalWE
      data-target="#modalWarning"
      :error="error"
      :warning="warning"
      :warningSend="warningSend"
      :warningSend2="warningSend2"
      :warningSend3="warningSend3"
      :warningSend4="warningSend4"
      :warningSend5="warningSend5"
    ></ModalWE>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ModalTrama from "./ModalTrama.vue";
import ModalTramaResponse from "./ModalTramaResponse.vue";
import ModalWE from "./ModalWE.vue";
export default {
  name: "Modal-Result-Transactions",
  components: {
    ModalTrama,
    ModalWE,
    ModalTramaResponse,
  },
  props: {
    newExecution: function () {},
    idExecution: Number,
    idPipeline: Number,
    execution: Object,
    dataTarget: {
      type: String,
      default: "",
    },
  },
  mounted() {
    /* this.getDetailTransactions() */
  },
  watch: {
    /* idExecution: function () {
            this.getDetailTransactions();

        }, */
  },
  data() {
    return {
      transactions: [],
      spinner: false,
      spinnerTable: false,
      spinnerDelete: false,
      error: "",
      warning: "",
      warningSend: "",
      warningSend2: "",
      warningSend3: "",
      warningSend4: "",
      warningSend5: "",
      trama: [],
      tramaResponse: [],
    };
  },
  methods: {
    ...mapActions("execution", ["downloadTrace"]),
    newE() {
      this.$emit("newExecution");
    },
    dloadTrace(tsId, numTxn) {
      const idPipeline = this.idPipeline;
      this.downloadTrace({ tsId, numTxn, idPipeline });
    },
    loadTrama(trama) {
      if (trama != null) {
        this.trama = trama.split("|");
      }
    },
    loadTramaResponse(tramaResponse) {
      if (tramaResponse != null) {
        this.tramaResponse = tramaResponse.split("|");
      }
    },
    loadWarningError(t) {
      this.error = t.error;
      this.warning = t.warning;
      this.warningSend = t.warning_send;
      this.warningSend2 = t.warning_send2;
      this.warningSend3 = t.warning_send3;
      this.warningSend4 = t.warning_send4;
      this.warningSend5 = t.warning_send5;
    },
    deleteExecution(id) {
      this.$swal({
        title: "¿Seguro que deseas eliminar esta Ejecución?",
        text: "(Esta acción es irreversible)",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
      }).then((result) => {
        if (result.value) {
          this.spinnerDelete = true;
          axios
            .delete("/execution-delete", {
              data: {
                id: id,
              },
            })
            .then((response) => {
              this.$swal({
                title: "Ejecución eliminada correctamente.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              this.transactions = [];
              this.$emit("cleanVSelect");
              this.spinnerTable = false;
              this.spinnerDelete = false;
              this.spinner = false;
            });
        }
      });
    },
    getDetailTransactions(page = 1) {
      if (this.idExecution != 0) {
        axios
          .get("/transaction-detail", {
            params: {
              idExecution: this.idExecution,
              page: page,
            },
          })
          .then((response) => {
            this.transactions = response.data;
            this.transactions[0].transaction.sort();
            $("#executionsSummary").modal("show");
          });
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  top: 0;
  left: -2;
  z-index: 1040;
  width: 0vw;
  height: 100vh;
  background-color: #000;
}

.md-table-cell {
  height: 1%;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  line-height: 0.1px;
}

.modal-body-trama {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
}

.table td,
.table th {
  padding: 0.3rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}

/* elemento {
    margin-left: 15px;
    border-left-style: solid;
    border-left-width: 0px;
    margin-right: 15px;
} */
.md-card .table {
  margin-left: 15px;
  border-left-style: solid;
  border-left-width: 0px;
  margin-right: 15px;
}
.close {
  display: none;
}
.modal-dialog.modal-lg .modal-content {
  max-height: 600px;
}
</style>
