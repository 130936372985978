<template>
  <form @submit.prevent="validate" class="needs-validation" novalidate>
    <b-form-group label="Proyectos" label-for="project">
      <b-form-select
        id="project"
        v-model="selectedProject"
        :state="projectState"
      >
        <option disabled value="" selected="selected">Seleccione</option>
        <option
          v-bind:key="index"
          v-bind:value="p"
          v-for="(p, index) in gitlabProjects"
        >
          {{ p.name }}
        </option>
      </b-form-select>
      <b-form-invalid-feedback id="project">
        Seleccione un proyecto.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Clientes" label-for="client">
      <b-form-select id="client" v-model="selectedClient" :state="clientState">
        <option disabled value="" selected="selected">Seleccione</option>
        <option
          v-bind:key="index"
          v-bind:value="c"
          v-for="(c, index) in clients"
        >
          {{ c.name }}
        </option>
      </b-form-select>
      <b-form-invalid-feedback id="client">
        Seleccione un cliente.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-button
      class="btn btn-success btn-sm btn-block"
      type="submit"
      variant="success"
    >
      <font-awesome-icon icon="save" /><span class="ml-1">Agregar</span>
    </b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form-Projects",
  mounted() {
    this.getGitlabProjects();
    this.getClients();
  },
  props: {},
  data() {
    return {
      loading: false,
      project: {
        name: "",
        id_git: "",
        default_branch: "",
        active: 1,
        client_id: "",
      },
      selectedProject: "",
      selectedClient: "",
      errors: [],
    };
  },
  methods: {
    ...mapActions("project", ["getProjects", "createProject"]),
    ...mapActions("gitlab", ["getGitlabProjects"]),
    ...mapActions("client", ["getClients"]),
    validate() {
      this.errors = [];
      if (!this.projectState) {
        this.selectedProject = undefined;
        this.errors.push("falta proyecto");
      }
      if (!this.clientState) {
        this.selectedClient = undefined;
        this.errors.push("falta cliente");
      }

      if (this.errors.length == 0) {
        (this.project.name = this.selectedProject.name),
          (this.project.id_git = this.selectedProject.id),
          (this.project.default_branch = this.selectedProject.default_branch),
          (this.project.client_id = this.selectedClient.id);

        this.registerProject(this.project);
      }
    },
    async registerProject(project) {
      await this.createProject(project);
      await this.getProjects();
      this.cleanForm();
    },
    cleanForm() {
      (this.selectedProject = ""),
        (this.selectedClient = ""),
        (this.project.name = ""),
        (this.project.id_git = ""),
        (this.project.default_branch = ""),
        (this.project.client_id = "");
    },
  },
  computed: {
    ...mapState("gitlab", ["gitlabProjects"]),
    ...mapState("client", ["clients"]),
    projectState() {
      if (this.selectedProject == "") {
        return null;
      } else if (this.selectedProject == undefined) {
        return false;
      } else {
        return true;
      }
    },
    clientState() {
      if (this.selectedClient == "") {
        return null;
      } else if (this.selectedClient == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
