<template lang="es">
<div>
    <div class="table-responsive">
        <table class="table text-center table-sm">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Cargo</th>
                    <th>Correo</th>
                    <th>Proyecto Asignado</th>
                    <th>Asignar Proyecto/Ramas</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-bind:key="index" v-for="(u, index) in users">
                    <td>{{u.name}}</td>
                    <td>{{(u.type==1)?"Administrador":u.type==2 ? "Procesador" :"Usuario"}}</td>
                    <td>{{u.email}}</td>
                    <div v-if="u.type!=1" class="d-flex justify-content-center mb-3">
                        <td v-if="u.user_projects.length>0"><button data-toggle="modal" data-target="#modalLoadProjects" class="btn btn-success btn-sm" @click="loadProjectsModal(u)">Ver Proyectos</button></td>
                        <!-- <td v-if="u.user_projects.length>0" v-bind:key="index" v-for="(up, index) in u.user_projects"><button data-toggle="modal" data-target="#modalAssingBranch" class="btn btn-success btn-sm" @click="loadModalAssingBranch(up)">{{up.project.name}}</button> -</td> -->
                        <td v-else></td>
                    </div>
                    <div v-else class="d-flex justify-content-center mb-3">
                        <td>Acceso Total</td>
                    </div>
                    <td>
                        <button title="Asignar proyecto" class="btn btn-success btn-sm" type="button" data-toggle="modal" data-target="#editProject" @click="setSelectedUser(u)" style="margin-right: 5px;">
                            <font-awesome-icon icon="plus-circle" />
                        </button>
                    </td>
                    <td>

                        <button title="Editar" class="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#editModal" @click="loadEditModal(u)" style="margin-right: 5px;">
                            <font-awesome-icon icon="edit" />
                        </button>
                        <button title="Eliminar" @click="deleteUs(u.id)" class="btn btn-danger btn-sm" type="button">
                            <font-awesome-icon icon="trash-alt" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--Edit Project-->
    <div class="modal" id="editProject" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Asignar Proyecto</h5>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-12 mb-2">
                                <label for=""><b>Proyecto</b></label>
                                <v-select v-model="dProject" :options="projects" label="name" placeholder="Seleccione Proyecto" @input="getUserBranch"></v-select>
                            </div>
                            <div v-if="dProject" class="col-10">
                                <label for=""><b>Ramas disponibles</b></label>
                                <v-select v-model="selectedBranch" :options="gitlabBranches" label="name" placeholder="Seleccione Rama"></v-select>
                            </div>
                            <div v-if="dProject" class="col-2 d-flex align-items-end">
                                <button type="button" class="btn btn-sm btn-success" @click="assignBranch()">Agregar</button>
                            </div>
                        </div>
                        <div v-if="assignedBranches.length > 0" class="row mb-2">
                            <div class="table-responsive">
                                <table class="table text-left table-sm">
                                    <thead>
                                        <tr>
                                            <th>Proyecto</th>
                                            <th>Rama</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-bind:key="index" v-for="(item, index) in assignedBranches">
                                            <td>{{item.projectName}}</td>
                                            <td>{{item.branch}}</td>
                                            <td>
                                                <button title="Eliminar" @click="removeBranch(index)" class="btn btn-danger btn-sm" type="button">
                                                    <font-awesome-icon icon="trash-alt" />
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    <button type="button" class="btn btn-success" @click="storeProject()" :disabled="assignedBranches.length < 1">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <!--Edit Modal-->
    <div class="modal" id="editModal" tabindex="-1" role="dialog">
        <!-- <div class="modal-dialog-lg" role="document"> -->
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Editar Usuario</h5>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- name -->
                        <div>
                            <label id="email">Nombre Usuario: *</label>
                            <b-form-input id="name" maxLength="45" :state="nameState" type="text" class="shadow-sm" v-model="form.name" placeholder="Ingrese Nombre de usuario" trim />
                            <b-form-invalid-feedback> Ingrese un nombre valido. </b-form-invalid-feedback>
                        </div>
                        <!-- type -->
                        <b-form-group id="type" label="Tipo Usuario: *" label-for="type">
                            <b-form-select class="shadow-sm" id="selManager" :state="typeState" v-model="form.type">
                                <option disabled value="" selected="selected">Seleccione</option>
                                <option value="1">Administrador</option>
                                <option value="2">Procesador</option>
                                <option value="3">Usuario</option>
                            </b-form-select>
                        </b-form-group>
                        <!-- email -->
                        <div>
                            <label id="email">Correo Electronico: *</label>
                            <b-form-input id="email" maxLength="45" :state="emailState" type="text" class="shadow-sm" v-model="form.email" placeholder="Ingrese correo electronico" trim />
                            <b-form-invalid-feedback id="client"> Ingrese Cliente. </b-form-invalid-feedback>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    <button type="button" class="btn btn-success" @click="validate()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <ModalAssingBranch data-target="modalAssingBranch" :project="project"></ModalAssingBranch>
    <div v-if="selectedUser">
        <ModalLoadProjects data-target="modalAssingBranch"></ModalLoadProjects>
    </div>
</div>
</template>

<script>
import ModalAssingBranch from "../Modal/ModalAssignBranch.vue";
import ModalLoadProjects from "../Modal/ModalLoadProjects.vue";
import {
    mapActions,
    mapState,
    mapMutations
} from 'vuex';
export default {
    name: "Table-User",
    components: {
        ModalAssingBranch,
        ModalLoadProjects
    },
    props: {},
    update() {},
    data() {
        return {
            form: {
                name: "",
                type: "",
                email: "",
                pass: "",
            },
            id: "",
            dProject: "",
            idUser: "",
            assignedProjects: "",
            project: {},
            selectedBranch: "",
            assignedBranches: [],
        };
    },
    methods: {
        ...mapActions('user', ['getUsers', 'deleteUser', 'updateUser', 'assignProject', 'storeBranch']),
        ...mapActions('project', ['getProjects']),
        ...mapActions('gitlab', ['getGitlabBranchesByProject']),
        ...mapMutations('user', ['setSelectedUser']),
        async getUserBranch() {
            await this.getGitlabBranchesByProject(this.dProject.id_git);
        },
        loadProjectsModal(user) {
            this.setSelectedUser(user);
        },
        loadModalAssingBranch(u) {
            this.project = u;
        },
        assignBranch() {
            this.assignedBranches.push({
                projectName: this.dProject.name,
                projectId: this.dProject.id,
                branch: this.selectedBranch.name,
                userId: this.selectedUser.id
            });

            this.selectedBranch = '';
        },
        removeBranch(index) {
            this.assignedBranches.splice(index, 1);
        },
        async storeProject() {
            const project = {
                userId: this.selectedUser.id,
                projectId: this.dProject.id
            }
            await this.assignProject(project);
            await this.storeBranch(this.assignedBranches);
            this.getUsers();
            $("#editProject").modal("hide");
            this.dProject = "";
            this.selectedBranch = '';
            this.assignedBranches = [];
        },
        cleanForm() {
            this.form.name = "";
            this.form.type = "";
            this.form.email = "";
            this.form.pass = "";
        },
        loadEditModal(u) {
            this.id = u.id;
            this.form.name = u.name;
            this.form.type = u.type;
            this.form.email = u.email;
        },
        async validate() {
            !this.nameState ? (this.form.name = undefined) : this.nameState;
            !this.typeState ? (this.form.type = undefined) : this.typeState;
            !this.emailState ? (this.form.email = undefined) : this.emailState;

            this.form.type = parseInt(this.form.type);

            try {
                await this.updateUser({
                    id: this.id,
                    updateUserDto: this.form
                });
                this.getUsers();
                $("#editModal").modal("hide");
            } catch (error) {
                // Manejar errores de updateUser si es necesario
                console.error('Error al actualizar usuario:', error);
            }
        },
        async deleteUs(id) {
            const confirmed = await this.$swal({
                title: "¿Seguro que deseas eliminar este Usuario?",
                text: "(Esta acción es irreversible)",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Si",
            });

            if (confirmed) {
                try {
                    console.log(id);
                    await this.deleteUser(id);
                    await this.getUsers();
                } catch (error) {
                    // Manejar errores si es necesario
                    console.error('Error al eliminar usuario o al obtener usuarios:', error);
                }
            }
        }

    },
    computed: {
        ...mapState('user', ['users', 'selectedUser']),
        ...mapState('project', ['projects']),
        ...mapState('gitlab', ['gitlabBranches']),
        nameState() {
            let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
            if (this.form.name == "") {
                return null;
            } else if (
                nameRegex.test(this.form.name) &&
                this.form.name != undefined
            ) {
                return true;
            } else {
                return false;
            }
        },
        emailState() {
            let emailRegex =
                /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (this.form.email == "") {
                return null;
            } else if (
                emailRegex.test(this.form.email) &&
                this.form.email != undefined
            ) {
                return true;
            } else {
                return false;
            }
        },
        typeState() {
            if (this.form.type == "") {
                return null;
            } else if (this.form.type == undefined) {
                return false;
            } else {
                return true;
            }
        },
        /*  projectState() {
                     if (this.dProject == "") {
                         return null;
                     } else if (this.dProject == undefined) {
                         return false;
                     } else {
                         return true;
                     }
                 }, */
    },
};
</script>

<style>
</style>
