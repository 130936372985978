<template>
  <md-card class="md-card-profile">
    <div class=""></div>
    <md-card-content>
      <h6 class="category text-gray">Estado de Base24</h6>
      <h5 class="card-title">
        Estado actual:
        <i
          v-if="status"
          class="fa fa-circle text-success icono-grande icono-parpadeo"
        ></i
        ><i v-else class="fa fa-circle text-danger icono-grande"></i>
      </h5>
      <em>
        <i class="fa fa-circle text-danger"></i> Apagado
        <i class="fa fa-circle text-success"></i> Encendido
      </em>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "EpsStatusCardComponent",
  props: {
    status: Boolean,
  },
  data() {
    return {};
  },
};
</script>
<style>
.icono-grande {
  font-size: 1.5em;
}

@keyframes parpadeo {
  50% {
    opacity: 0;
  }
}

.icono-parpadeo {
  animation: parpadeo 1s infinite;
}
</style>
