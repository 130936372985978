import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";
import router from "../../routes/router"

ls.config.encrypt = false;
const authStore = {
  namespaced: true,
  state: () => ({
    token: null,
    loggedUser: null,
    swProject: '',
  }),
  mutations: {
    removeLocalStorage(state, key) {
      ls.remove(key);
      state.loggedUser = null;
    },
    checkTimeOut() {
      ls.flush();
    },
    logout(state) {
      ls.remove("token");
      ls.remove("user");
      state.token = null;
      state.loggedUser = null;
      router.push('/acceder');
    },
    setUserData(state, userData) {
      ls.set("user", JSON.stringify(userData), { ttl: 5000 });
      state.loggedUser = userData;
    },
    setToken(state, token) {
      ls.set("token", token, { ttl: 5000 });
      state.token = token;
    },
    setLoginError(state, errorMessage) {
      // Almacenar mensaje de error en el estado
      state.loginError = errorMessage;
    },
    setSwProject(state, swProject) {
      state.swProject = swProject;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const response = await HttpClient.post("/api/auth/login", {
          email,
          password,
        });

        // Asumiendo que la respuesta incluye datos del usuario y un token
        const userData = response.data.user;
        const token = response.data.token;

        commit("setUserData", userData);
        commit("setToken", token);

        return true;
      } catch (error) {
        return false;
      }
    },
  },
  getters: {
    localStorage: () => (key) => ls.get(key),
    localStorages: () => ls.get(),
    user: (state) => state.loggedUser,
  },
};

export default authStore;
