<template>
<div class="container-fluid">
    
    <md-card>
        <md-card-header data-background-color="purple">
            <h4 class="title">Proyectos Registrados en KVZ</h4>
            <p class="category">
               Seleccione y agregue el proyecto que desee disponibilizar en la plataforma.
            </p>
        </md-card-header>

        <md-card-content >
            <div class="row">
        <!-- <div class="col-md-1" /> -->
        <div class="col-md-3">
            <h5 class="text-center">Nuevo Proyecto</h5>
            <div class="col-md-12">
                <FormProjectComponent/>
            </div>
        </div>
        <div class="col-md-9">
            <h5 class="text-center">Registro de Proyectos</h5>
            <div class="col-md-12">
                <TableProjectsComponent/>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-spinner label="Cargando..." v-if="spinner==true" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </div>
    </div>
        </md-card-content>
    </md-card>
    
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormProjectComponent from "../../components/Forms/FormProjectComponent";
import TableProjectsComponent from "../../components/Tables/TableProjectsComponent";
export default {
    name: "Maintainer-Projects",
    components: {
        TableProjectsComponent,
        FormProjectComponent
    },
    mounted() {
        this.getProjects();
    },    
    data() {
        return {
            spinner: false,
        };
    },
    methods: {
        ...mapActions('project', ['getProjects']),
    },

};
</script>

<style>
</style>
