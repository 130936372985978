var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modalLoadProjects","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-scrollable",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(" Proyectos Asociados al Usuario: "),_c('strong',[_vm._v(_vm._s(_vm.selectedUser.email))])]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table text-center table-sm"},[_vm._m(1),_c('tbody',_vm._l((_vm.selectedUser.user_projects),function(proj,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(proj.project.name))]),_c('td',[_vm._v(_vm._s(proj.project.id))]),_c('td',[_c('button',{staticClass:"btn btn-primary btn-sm",class:{
                        'btn-warning': _vm.activeProjectId === proj.project.id,
                      },attrs:{"type":"button"},on:{"click":function($event){return _vm.showBranchesModal(proj.project.id)}}},[_vm._v(" Ver Ramas ")])])])}),0)])]),(_vm.activeProjectId)?_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v(" Ramas del proyecto: "),_c('b',[_vm._v(_vm._s(_vm.activeProjectName))])]),(_vm.projectBranches)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',_vm._l((_vm.activeBranches),function(branch){return _c('tr',{key:branch.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(branch.branch))]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger btn-sm",on:{"click":function($event){return _vm.deleteBranch(branch.id)}}},[_c('i',{staticClass:"fas fa-trash"})])])])}),0)])]):_c('div')]):_vm._e()]),_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Proyecto")]),_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Rama")]),_c('th',{staticClass:"text-center"},[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Cerrar ")])])
}]

export { render, staticRenderFns }