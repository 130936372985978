import ls from 'localstorage-slim';
import { HttpClient } from '../httpClient';
import Swal from 'sweetalert2';

ls.config.encrypt = false;
const environmentStore = {
  namespaced: true,
  state: {
    environments: [],
    selectedEnvironment: null,
  },
  mutations: {
    setEnvironments(state, environments) {
      state.environments = environments;
    },
    setSelectedEnvironment(state, selectedEnvironment) {
      state.selectedEnvironment = selectedEnvironment;
    },
  },
  actions: {
    alert({ commit }, msg) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: msg,
      });
    },
    async getEnvironments({ commit }) {
      try {
        const response = await HttpClient.get("/api/environment");
        commit("setEnvironments", response.data);
      } catch (error) {
        console.error("Error al obtener ambientes:", error);
      }
    },
    async getEnvironment({ commit }, id) {
      try {
        const response = await HttpClient.get(`/api/environment/${id}`);
        return response.data
      } catch (error) {
        console.error('Error al obtener parámetros de ambiente:', error);
      }
    },
    async createEnvironment({ commit, dispatch }, createEnvironmentDto) {
      const msg = "Se agrego un nuevo Ambiente!";
      try {
        const response = await HttpClient.post('/api/environment', createEnvironmentDto);
        if(response){
          dispatch('alert', msg);
          return true;
        }
      } catch (error) {
        console.error('Error al crear ambiente:', error);
      }
    },
    async updateEnvironment({ commit }, { id, updateEnvironmentDto }) {
      try {
        const response = await HttpClient.patch(`/api/environment/${id}`, updateEnvironmentDto);
      } catch (error) {
        console.error('Error al actualizar Ambiente:', error);
      }
    },
    async deleteEnvironment({ commit, dispatch }, id) {
      const msg = "El Ambiente fue eliminado correctamente.";
      try {
        const response = await HttpClient.delete(`/api/environment/${id}`);
        dispatch('alert', msg);
      } catch (error) {
        console.error('Error al eliminar ambiente:', error);
      }
    },
  },
  getters: {
    getEnvironments: (state) => state.environments,
  },
};

export default environmentStore;
