<template>
<div>
    <div class="modal fade" id="modalAssingBranch" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><b>{{project.name}}</b></h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-md-left">
                        <div class="row">
                            
                            <div class="col-auto">
                                <b-form-select v-model="branchSelected">
                                    <option disabled value="" selected="selected">Seleccione</option>
                                    <option v-bind:key="index" v-bind:value="b" v-for="(b, index) in branchsGit">
                                        {{ b.name }}
                                    </option>
                                </b-form-select>
                            </div>
                            <div class="col-auto">
                                <table class="table text-center table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Rama Autorizada</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-bind:key="index" v-for="(b, index) in branchs">
                                            <td>{{b.branch}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-auto">
                                <button class="btn btn-primary btn-sm" @click="addBranch">
                                    Agregar
                                </button>
                            </div>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Modal-Assing-Branch",
    components: {

    },
    props: {
        project: Object,
        dataTarget: {
            type: String,
            default: ""
        }
    },
    mounted() {

    },
    watch: {
        project: function () {
            /* this.getProject() */
        }
    },
    data() {
        return {
            transactions: [],
            projectSelected: "",
            branchsGit: "",
            branchs: "",
            branchSelected: "",
        };
    },
    methods: {
        getUserBranch() {
            axios.get("/branchs/filter/user", {
                params: {
                    idUser: this.project.user_id,
                    idProject: this.project.project_id,
                },
            }).then(response => {
                this.branchs = response.data
            })
        },
        addBranch() {
            axios.post("/store/branches", {
                branchSelected: this.branchSelected.name,
                idUser: this.project.user_id,
                idProject: this.project.project_id,
            }).then(response => {
                this.getUserBranch()
            })

        },
        getProject() {
            axios.get("/projects/filter/id", {
                params: {
                    idProject: this.project.project_id,
                },
            }).then(response => {
                console.log(response.data);
                this.getBranchProjectSelected(response.data)
            })

        },
        getBranchProjectSelected(project) {
            /* let url = "http://gitlab.kvz.cl";
            axios
                .get(
                    url + "/api/v4/projects/" + project.id_git + "/repository/branches/", {
                        headers: {
                            "PRIVATE-TOKEN": "qbEbGSsJgf2os2nnHdJs",
                        },
                    }
                )
                .then((response) => {
                    this.branchsGit = response.data;
                    this.getUserBranch()
                }); */
            axios.get("/gitlab/branches",{
                params:{
                    idProject:project.id_git
                }
            } )
            .then(response => {
                this.branchsGit = response.data;
                this.getUserBranch()
        });
        },

    },
};
</script>

<style>
.modal-backdrop {
    top: 0;
    left: -2;
    z-index: 1040;
    width: 0vw;
    height: 100vh;
    background-color: #000;
}

.md-table-cell {
    height: 1%;
    position: relative;
    transition: .3s cubic-bezier(.4, 0, .2, 1);
    font-size: 13px;
    line-height: 0.1px;
}

.modal-body-trama {
    font-size: .7rem;
    font-weight: 600;
    line-height: 1;
}

.table td,
.table th {
    padding: .3rem;
    vertical-align: top;
    border-top: 0px solid #dee2e6;
}

/* elemento {
    margin-left: 15px;
    border-left-style: solid;
    border-left-width: 0px;
    margin-right: 15px;
} */
.md-card .table {
    margin-left: 15px;
    border-left-style: solid;
    border-left-width: 0px;
    margin-right: 15px;
}
</style>
