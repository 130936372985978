<template lang="es">
<div>
    <div class="table-responsive">
        <table class="table text-center table-sm">
            <thead>
                <tr>
                    <th>Nombre Proyecto</th>
                    <th>ID</th>
                    <th>Rama por defecto</th>                 
                    <th>Cliente</th>                 
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-bind:key="index" v-for="(p, index) in projects">
                    <td>{{p.name}}</td>
                    <td>{{p.id_git}}</td>
                    <td>{{p.default_branch}}</td>                    
                    <td>{{p.client.name}}</td>                    
                    <td>                      
                        <button title="Eliminar" @click="deleteAction(p.id)" class="btn btn-danger btn-sm" type="button">
                            <font-awesome-icon icon="trash-alt" />                            
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>  
</div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    props: {       
    },
    // props: ["dataProject"],
    data() {
        return {
            id: "",
        };
    },
    methods: {   
        ...mapActions('project', ['getProjects', 'deleteProject']),   
        async deleteAction(id){
            await this.deleteProject(id);
            this.getProjects();
        }
    },
    computed: {
        ...mapState('project', ['projects']),

    },
};
</script>

<style>
</style>
