<template>
  <table v-if="table" class="table table-sm text-center">
    <thead>
      <tr>
        <th>Test Data</th>
        <th>Tipo</th>
        <th>Versión</th>
        <th>Última Ejecución</th>
        <th>Pruebas Aprobadas</th>
        <th>Pruebas Rechazadas</th>
        <th>Pruebas Time-Out</th>
        <th>Total</th>
        <th>Descargar</th>
      </tr>
    </thead>
    <tbody>
      <template
        v-if="
          executionsByType.groupedByVEmArray.length > 0 ||
          executionsByType.groupedExecutionsArray.length > 0
        "
      >
        <tr
          v-for="(group, index) in executionsByType.groupedExecutionsArray"
          :key="`group-em${index}`"
        >
          <td>{{ group[0].red }}</td>
          <td>Adquiriente</td>
          <td>{{ group[0].v_ad }}</td>
          <td>
            {{
              new Date(group[0].created_at).toLocaleString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </td>
          <td style="color: green">
            {{ group[0].transactionSummary.approved }}
          </td>
          <td style="color: red">{{ group[0].transactionSummary.rejected }}</td>
          <td style="color: blue">{{ group[0].transactionSummary.missing }}</td>
          <td>{{ group[0].transactionSummary.total }}</td>
          <td>
            <font-awesome-icon
              icon="download"
              class="text-warning"
              size="1x"
              @click="downloadFile(group, 'adquiriente')"
            />
          </td>
        </tr>
        <tr
          v-for="(group, index) in executionsByType.groupedByVEmArray"
          :key="`group-ad${index}`"
        >
          <td>{{ group[0].v_em.split("/")[0].toUpperCase() }}</td>
          <td>Emisor</td>
          <td>
            {{
              group[0].v_em.split("/")[1] +
              "/" +
              group[index].v_em.split("/")[2]
            }}
          </td>
          <td>
            {{
              new Date(group[0].created_at).toLocaleString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })
            }}
          </td>
          <td style="color: green">
            {{ group[0].transactionSummary.approved }}
          </td>
          <td style="color: red">{{ group[0].transactionSummary.rejected }}</td>
          <td style="color: blue">{{ group[0].transactionSummary.missing }}</td>
          <td>{{ group[0].transactionSummary.total }}</td>
          <td>
            <font-awesome-icon
              icon="download"
              class="text-warning"
              size="1x"
              @click="downloadFile(group, 'emisor')"
            />
          </td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="9" class="text-center">No hay registros.</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Repository-Table",
  components: {},
  props: {
    table: Boolean,
  },
  update() {},
  watch: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("google", ["downloadTestdata"]),
    totalApproved(group) {
      let count = 0;
      group.forEach((element) => {
        count += element.transactionSummary.approved;
      });
      return count;
    },
    totalRejected(group) {
      let count = 0;
      group.forEach((element) => {
        count += element.transactionSummary.rejected;
      });
      return count;
    },
    totalTimeout(group) {
      let count = 0;
      group.forEach((element) => {
        count += element.transactionSummary.missing;
      });
      return count;
    },
    totalFinal(group) {
      let count = 0;
      group.forEach((element) => {
        count += element.transactionSummary.total;
      });
      return count;
    },
    lastExecution(group) {
      return group[group.length - 1].created_at;
    },
    downloadFile(group, type) {
      let red = "";
      let version = "";
      let client = "";
      if (type == "adquiriente") {
        red = group[0].red;
        version = group[0].v_ad;
      } else {
        red = group[0].v_em.split("/")[0].toUpperCase();
        version =
          group[0].v_em.split("/")[1] + "/" + group[0].v_em.split("/")[2];
      }

      client = group[0].project.client.name;

      this.downloadTestdata({client, type, red, version })
        .then((response) => {
          console.log("Testdata descargado correctamente.");
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    },
  },
  computed: {
    ...mapState("execution", ["executionsByType"]),
  },
};
</script>

<style>
table.audit {
  position: relative;
  border-collapse: collapse;
}

table.audit th {
  position: sticky;
  top: 55px;
  background: white;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
