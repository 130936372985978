<template>
<div class="container-fluid">
    <md-card>
        <md-card-header data-background-color="purple">
            <h4 class="title">Registro de Clientes</h4>
            <p class="category">
               Aquí puedes administrar los clientes del sistema.
            </p>
        </md-card-header>

        <md-card-content>
           <div class="row">
        <div class="col-md-1" />
        <div class="col-md-2">
            <h5 class="text-center">Nuevo Cliente</h5>
            <div class="col-md-12">
                <FormClientComponent/>
            </div>
        </div>
        <div class="col-md-8">
            <h5 class="text-center">Clientes</h5>
            <div class="col-md-12">
                <TableClientsComponent/>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-spinner label="Cargando..." v-if="spinner==true" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </div>
    </div>
        </md-card-content>
    </md-card>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import FormClientComponent from "../../components/Forms/FormClientComponent.vue";
import TableClientsComponent from "../../components/Tables/TableClientsComponent.vue";
export default {
    components: {
        TableClientsComponent,
        FormClientComponent
    },
    mounted() {
        this.getClients();
    },
    data() {
        return {
            spinner: false,
        };
    },
    methods: {
        ...mapActions('client', ['getClients']),
    },

};
</script>

<style>
</style>
