<template>
  <div>
    <table :key="table" class="table text-center table-sm table-hover">
      <thead>
        <tr>
          <th scope="col">Pipeline ID</th>
          <th scope="col">Fecha</th>
          <th scope="col">Usuario</th>
          <th scope="col">Proyecto</th>
          <th scope="col">Rama</th>
          <th scope="col">Adquiriente</th>
          <th scope="col">A.Versión</th>
          <th scope="col">E.Versión</th>
          <th scope="col">Tiempo Ejecución</th>
          <th scope="col">Aprobadas</th>
          <th scope="col">Rechazadas</th>
          <th scope="col">Time-Out</th>
          <th scope="col">Total</th>
          <th scope="col">Detalle</th>
          <th scope="col">EvtLog</th>
          <th
            scope="col"
            class="btn btn-sm btn-success p-1"
            @click="openExecutionComparations"
          >
            Comparar
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-bind:key="e.id"
          v-for="e in paginatedData"
          style="font-size: small; color: black"
        >
          <td :class="{ dimmed: addExecutionMode }">{{ e.pipeline_id }}</td>
          <td :class="{ dimmed: addExecutionMode }">
            {{ e.transactionSummary.date_execution }}
          </td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.user.name }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.project.name }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.project_branch }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.red }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.v_ad }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.v_em }}</td>
          <td :class="{ dimmed: addExecutionMode }">{{ e.execution_time }}</td>
          <td
            :class="{ dimmed: addExecutionMode }"
            :style="!addExecutionMode ? 'color: green' : ''"
          >
            <b>{{ e.transactionSummary.approved }}</b>
          </td>
          <td
            :class="{ dimmed: addExecutionMode }"
            :style="!addExecutionMode ? 'color: red' : ''"
          >
            <b>{{ e.transactionSummary.rejected }}</b>
          </td>
          <td
            :class="{ dimmed: addExecutionMode }"
            :style="!addExecutionMode ? 'color: blue' : ''"
          >
            <b>{{ e.transactionSummary.missing }}</b>
          </td>
          <td :class="{ dimmed: addExecutionMode }">
            <b>{{ e.transactionSummary.total }}</b>
          </td>
          <td :class="{ dimmed: addExecutionMode }">
            <font-awesome-icon
              icon="list-ol"
              class="text-success"
              :class="{ 'disabled-icon': addExecutionMode }"
              size="1x"
              data-toggle="modal"
              data-target="#executionsSummary"
              @click="
                addExecutionMode
                  ? null
                  : loadId(e.transaction_summaries_id, e.pipeline_id, e)
              "
            />
          </td>
          <td :class="{ dimmed: addExecutionMode }">
            <font-awesome-icon
              icon="download"
              class="text-warning"
              :class="{ 'disabled-icon': addExecutionMode }"
              size="1x"
              @click="
                addExecutionMode
                  ? null
                  : runDownloadEvtlog(e.red, e.pipeline_id)
              "
            />
          </td>
          <td :class="{ highlight: addExecutionMode }">
            <input
              type="checkbox"
              :id="`checkbox-${e.id}`"
              v-model="e.checked"
              @change="insertCheck(e)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <pagination
      id="pagination"
      align="center"
      :data="filteredExecutions"
      
    ></pagination> -->
    <div class="d-flex justify-content-center">
      <p-pagination
        :records="filteredExecutions.length"
        :per-page="tableSize"
        v-model="currentPage"
      ></p-pagination>
    </div>

    <ModalResultTransaccions
      v-if="selectedExecution"
      data-target="#executionsSummary"
      :idExecution="idExecution"
      :idPipeline="idPipeline"
      :execution="selectedExecution"
    ></ModalResultTransaccions>
    <ModalComparation
      data-target="#modalComparations"
      :executions="filteredExecutions"
    ></ModalComparation>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ModalResultTransaccions from "../Modal/ModalResultTransaccions.vue";
import ModalComparation from "../../components/Modal/ModalComparation.vue";
export default {
  name: "Executions-Summary",
  components: {
    ModalResultTransaccions,
    ModalComparation,
  },
  props: {
    getExecutions: function () {},
    executions: Array,
    tableHeaderColor: {
      type: String,
      default: "",
    },
    tableSize: Number,
  },
  update() {
    /* this.$emit("getExecutions"); */
  },
  watch: {
    executions: function () {
      this.table++;
    },
  },
  data() {
    return {
      table: 0,
      idExecution: 0,
      idPipeline: 0,
      selectedExecution: "",
      currentPage: 1,
      /* perPage: 10, */
    };
  },
  mounted() {
    this.setExecutionsCompare([]);
    this.setAddExecutionMode(false);
  },
  methods: {
    ...mapActions("execution", ["downloadEvtlog"]),
    ...mapMutations("execution", [
      "setExecutionsCompare",
      "setAddExecutionMode",
    ]),
    openExecutionComparations() {
      if (
        this.executionsCompare.length > 1 &&
        this.executionsCompare.length < 5
      ) {
        $("#modalComparations").modal("show");
      } else {
        this.$swal({
          text: "Debe seleccionar al menos 2 y como máximo 4 ejecuciones para comparar.",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
      }
    },
    insertCheck(execution) {
      const selectedExecutions = [...this.executionsCompare];

      const index = selectedExecutions.findIndex((e) => e.id === execution.id);

      if (index === -1) {
        if (selectedExecutions.length < 4) {
          const executionClone = {
            ...execution,
            transactionSummary: {
              ...execution.transactionSummary,
              transactions: execution.transactionSummary.transactions.map(
                (transaction) => ({
                  ...transaction,
                  checked: false,
                })
              ),
            },
          };
          selectedExecutions.push(executionClone);
        } else {
          this.$nextTick(() => {
            execution.checked = false;
          });

          this.$swal({
            text: "La cantidad máxima a comparar son 4 ejecuciones.",
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
          });
        }
      } else {
        selectedExecutions.splice(index, 1);
      }

      this.setExecutionsCompare(selectedExecutions);
    },

    loadId(id, idPipeline, execution) {
      this.idExecution = id;
      this.idPipeline = idPipeline;
      this.selectedExecution = execution;
    },
    emitGetExecutions(page) {
      this.$emit("getExecutions", page);
    },
    runDownloadEvtlog(red, idPipeline) {
      this.downloadEvtlog({ red, idPipeline });
    },
    versionSplit(execution, number) {
      if (execution.v_ad) {
        const parts = execution.v_ad.split("/");
        if (number >= 0 && number < parts.length) {
          return parts[number];
        }
      }
      return "";
    },
  },
  computed: {
    ...mapState("execution", ["executionsCompare", "addExecutionMode"]),
    filteredExecutions() {
      if (!this.executionsCompare[0]) {
        return this.executions;
      }

      return this.executions.filter((execution) => {
        return this.executionsCompare[0].red === execution.red;
      });
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.tableSize;
      const end = start + this.tableSize;
      return this.filteredExecutions.slice(start, end);
    },
  },
};
</script>

<style>
.modal-backdrop {
  top: 0;
  left: -2;
  z-index: 1040;
  width: 0vw;
  height: 100vh;
  background-color: #000;
}

.md-table-cell {
  height: 1%;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  line-height: 0.1px;
}

.modal-body-trama {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
}
.highlight {
  background-color: #dbefff;
}

.dimmed {
  background-color: #f9f9f9;
  color: #bbb;
}

.disabled-icon {
  cursor: not-allowed;
}

.VuePagination__count {
  display: none;
}
</style>
