import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './auth/auth';
import userStore from './user/user';
import projectStore from './project/project';
import gitlabStore from './gitlab/gitlab';
import designStore from './design/design';
import googleStore from './google/google';
import executionStore from './execution/execution';
import environmentStore from './environment/environment';
import clientStore from './client/client';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authStore,
    user: userStore,
    project: projectStore,
    gitlab: gitlabStore,
    design: designStore,
    google: googleStore,
    execution: executionStore,
    environment: environmentStore,
    client: clientStore
  },
});
