<template>
    <md-card class="md-card-profile">
        <div class=""></div>
        <md-card-content>
            <h6 class="category text-gray">Tiempo de Ejecución</h6>
            <h4 class="card-title">Tiempo transcurrido desde que se iniciaron las pruebas.</h4> 
                <h2 style="color: blue" class="text-center">{{resutTime}}</h2>   
            <em>
            </em>          
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: "ChronometerCardComponent",
    props: {
        logoKvz: {
            type: String,
            default: require("../../assets/imgKvz/logo.png")
        },
        resutTime: String,        
    },
    watch:{
        resutTime: function () {
            this.resutTime
        },
    },
    data() {
        return {};
    }
};
</script>

<style scoped>
.md-card-content {
    font-size: 16px;
    line-height: 22px;
}
</style>
