import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";
import Swal from "sweetalert2";

ls.config.encrypt = false;
const clientStore = {
  namespaced: true,
  state: {
    clients: [],
    selectedClient: null,
  },
  mutations: {
    setClients(state, clients) {
      state.clients = clients;
    },
    setSelectedClient(state, selectedClient) {
      state.selectedClient = selectedClient;
    },
  },
  actions: {
    alert({ commit }, msg) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: msg,
      });
    },
    async getClients({ commit }) {
      try {
        const response = await HttpClient.get("/api/client");
        commit("setClients", response.data);
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    },
    async createClient({ commit, dispatch }, createClientDto) {
      const msg = "Se agrego un nuevo Cliente!";
      try {
        const response = await HttpClient.post("/api/client", createClientDto);
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al crear client:", error);
      }
    },
    async updateClient({ commit }, { id, updateClientDto }) {
      try {
        const response = await HttpClient.patch(
          `/api/client/${id}`,
          updateClientDto
        );
      } catch (error) {
        console.error("Error al actualizar client:", error);
      }
    },
    async deleteClient({ commit, dispatch }, id) {
      const msg = "El Cliente fue eliminado correctamente.";
      try {
        const response = await HttpClient.delete(`/api/client/${id}`);
        dispatch("alert", msg);
      } catch (error) {
        console.error("Error al eliminar cliente:", error);
      }
    },
  },
  getters: {
    getClients: (state) => state.clients,
  },
};

export default clientStore;
