<template lang="es">
<div>
    <div class="table-responsive">
        <table class="table text-center table-sm">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>IP</th>
                    <th>Puerto MDS</th>
                    <th>Puerto Hiso87</th>
                    <th>Puerto Hiso93</th>
                    <th>Puerto Visanet</th>
                    <th>Proyecto</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-bind:key="index" v-for="(e, index) in environments">
                    <td>{{e.name}}</td>
                    <td>{{e.ip}}</td>
                    <td>{{e.port_mds}}</td>
                    <td>{{e.port_hiso87}}</td>
                    <td>{{e.port_hiso93}}</td>
                    <td>{{e.port_visanet}}</td>
                    <td>{{e.project.name}}</td>
                    <td>

                        <button title="Editar" class="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#editModal" @click="loadEditModal(e)" style="margin-right: 5px;">
                            <font-awesome-icon icon="edit" />
                        </button>
                        <button title="Eliminar" @click="deleteAm(e.id)" class="btn btn-danger btn-sm" type="button">
                            <font-awesome-icon icon="trash-alt" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--Edit Modal-->
    <div class="modal" id="editModal" tabindex="-1" role="dialog">
        <!-- <div class="modal-dialog-lg" role="document"> -->
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Editar Ambiente</h5>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- name -->
                        <div class="mr-2">
                            <label id="email">Nombre Ambiente: *</label>
                            <b-form-input id="name" maxLength="45" :state="nameState" type="text" class="shadow-sm" v-model="form.name" placeholder="Ingrese Nombre de cliente" trim />
                            <b-form-invalid-feedback> Ingrese un nombre valido. </b-form-invalid-feedback>
                        </div>
                        <!-- ip -->
                        <div>
                            <label for="ip">IP: *</label>
                            <b-form-input
                                id="ip"
                                maxLength="45"
                                :state="ipState"
                                type="text"
                                class="white-sm"
                                v-model="form.ip"
                                placeholder="Ingrese IP del ambiente"
                                trim
                            />
                            <b-form-invalid-feedback id="ip">
                                Ingrese IP valida.
                            </b-form-invalid-feedback>
                        </div>
                        <!-- port_mds -->
                        <div class="mr-2">
                            <label for="port_mds">Puerto MDS: *</label>
                            <b-form-input
                                id="port_mds"
                                maxLength="45"
                                :state="portMdsState"
                                type="text"
                                class="white-sm"
                                v-model="form.port_mds"
                                placeholder="Ingrese Puerto para MDS"
                                trim
                            />
                            <b-form-invalid-feedback id="port_mds">
                                Ingrese Puerto valido.
                            </b-form-invalid-feedback>
                        </div>
                        <!-- port_hiso87 -->
                        <div class="mr-2">
                            <label for="port_hiso87">Puerto Hiso87: *</label>
                            <b-form-input
                                id="port_hiso87"
                                maxLength="45"
                                :state="portHiso87State"
                                type="text"
                                class="white-sm"
                                v-model="form.port_hiso87"
                                placeholder="Ingrese Puerto para Hiso87"
                                trim
                            />
                            <b-form-invalid-feedback id="port_hiso87">
                                Ingrese Puerto valido.
                            </b-form-invalid-feedback>
                        </div>
                        <!-- port_hiso93 -->
                        <div class="mr-2">
                            <label for="port_hiso93">Puerto Hiso93: *</label>
                            <b-form-input
                                id="port_hiso93"
                                maxLength="45"
                                :state="portHiso93State"
                                type="text"
                                class="white-sm"
                                v-model="form.port_hiso93"
                                placeholder="Ingrese Puerto para Hiso93"
                                trim
                            />
                            <b-form-invalid-feedback id="port_hiso93">
                                Ingrese Puerto valido.
                            </b-form-invalid-feedback>
                        </div>
                        <!-- port_visanet -->
                        <div class="mr-2">
                            <label for="port_visanet">Puerto Visanet: *</label>
                            <b-form-input
                                id="port_visanet"
                                maxLength="45"
                                :state="portVisanetState"
                                type="text"
                                class="white-sm"
                                v-model="form.port_visanet"
                                placeholder="Ingrese Puerto para Visanet"
                                trim
                            />
                            <b-form-invalid-feedback id="port_visanet">
                                Ingrese Puerto valido.
                            </b-form-invalid-feedback>
                        </div>
                        <!-- project -->
                        <b-form-group id="project" label="Proyecto: *" label-for="project">
                            <b-form-select
                            class="shadow-sm"
                            id="project"
                            :state="projectState"
                            v-model="form.project_id"
                        >
                            <option disabled value="" selected="selected">Seleccione</option>
                            <option
                            v-bind:key="index"
                            v-bind:value="p.id"
                            v-for="(p, index) in projects"
                            >
                                {{ p.name }}
                            </option>
                        </b-form-select>
                            <b-form-invalid-feedback id="project">
                                Seleccione un proyecto.
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    <button type="button" class="btn btn-success" @click="validate()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Table-Environment",
  components: {},
  props: {},
  update() {},
  data() {
    return {
      form: {
        name: "",
        ip: "",
        port_mds: "",
        port_hiso87: "",
        port_hiso93: "",
        port_visanet: "",
        project_id: "",
      },
      id: "",
    };
  },
  methods: {
    ...mapActions("environment", [
      "getEnvironments",
      "deleteEnvironment",
      "updateEnvironment",
    ]),
    ...mapMutations("environment", ["setSelectedEnvironment"]),
    cleanForm() {
      this.form.name = "";
      this.form.ip = "";
      this.form.port_mds = "";
      this.form.port_hiso87 = "";
      this.form.port_hiso93 = "";
      this.form.port_visanet = "";
      this.form.project_id = "";
    },
    loadEditModal(e) {
      this.id = e.id;
      this.form.name = e.name;
      this.form.ip = e.ip;
      this.form.port_mds = e.port_mds;
      this.form.port_hiso87 = e.port_hiso87;
      this.form.port_hiso93 = e.port_hiso93;
      this.form.port_visanet = e.port_visanet;
      this.form.project_id = e.project_id;
    },
    async validate() {
      this.errors = [];
      if (!this.nameState) {
        this.form.name = undefined;
        this.errors.push("falta name");
      }
      if (!this.ipState) {
        this.form.ip = undefined;
        this.errors.push("falta ip");
      }
      if (!this.portMdsState) {
        this.form.port_mds = undefined;
        this.errors.push("falta puerto mds");
      }
      if (!this.portHiso87State) {
        this.form.port_hiso87 = undefined;
        this.errors.push("falta puerto hiso87");
      }
      if (!this.portHiso93State) {
        this.form.port_hiso93 = undefined;
        this.errors.push("falta puerto hiso93");
      }
      if (!this.portVisanetState) {
        this.form.port_visanet = undefined;
        this.errors.push("falta puerto visanet");
      }
      if (!this.projectState) {
        this.form.project_id = undefined;
        this.errors.push("falta proyecto");
      }
      if (this.errors.length == 0) {
        try {
          await this.updateEnvironment({
            id: this.id,
            updateEnvironmentDto: this.form,
          });
          this.getEnvironments();
          $("#editModal").modal("hide");
        } catch (error) {
          console.error("Error al actualizar ambiente:", error);
        }
      }
    },
    async deleteAm(id) {
      const confirmed = await this.$swal({
        title: "¿Seguro que deseas eliminar este Ambiente?",
        text: "(Esta acción es irreversible)",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
      });

      if (confirmed) {
        try {
          await this.deleteEnvironment(id);
          await this.getEnvironments();
        } catch (error) {
          console.error(
            "Error al eliminar ambiente o al obtener ambientes:",
            error
          );
        }
      }
    },
  },
  computed: {
    ...mapState("environment", ["environments", "selectedEnvironment"]),
    ...mapState("project", ["projects"]),
    nameState() {
      let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
      if (this.form.name == "") {
        return null;
      } else if (
        nameRegex.test(this.form.name) &&
        this.form.name != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    ipState() {
      if (this.form.ip == "") {
        return null;
      } else if (this.form.ip != undefined) {
        return true;
      } else {
        return false;
      }
    },
    portMdsState() {
      if (this.form.port_mds == "") {
        return null;
      } else if (this.form.port_mds != undefined) {
        return true;
      } else {
        return false;
      }
    },
    portHiso87State() {
      if (this.form.port_hiso87 == "") {
        return null;
      } else if (this.form.port_hiso87 != undefined) {
        return true;
      } else {
        return false;
      }
    },
    portHiso93State() {
      if (this.form.port_hiso93 == "") {
        return null;
      } else if (this.form.port_hiso93 != undefined) {
        return true;
      } else {
        return false;
      }
    },
    portVisanetState() {
      if (this.form.port_visanet == "") {
        return null;
      } else if (this.form.port_visanet != undefined) {
        return true;
      } else {
        return false;
      }
    },
    projectState() {
      if (this.form.project_id == "") {
        return null;
      } else if (this.form.project_id == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style></style>
