<template>
  <form @submit.prevent="validate" class="needs-validation" novalidate>
    <!-- name -->
    <div if="name" class="mb-2">
      <label for="name">Nombre Ambiente: *</label>
      <b-form-input
        id="name"
        maxLength="45"
        :state="nameState"
        type="text"
        class="white-sm"
        v-model="form.name"
        placeholder="Ingrese Nombre del ambiente"
        trim
      />
      <b-form-invalid-feedback id="name">
        Ingrese Nombre valido.
      </b-form-invalid-feedback>
    </div>
    <!-- ip -->
    <div if="ip" class="mb-2">
      <label for="ip">IP: *</label>
      <b-form-input
        id="ip"
        maxLength="45"
        :state="ipState"
        type="text"
        class="white-sm"
        v-model="form.ip"
        placeholder="Ingrese IP del ambiente"
        trim
      />
      <b-form-invalid-feedback id="ip">
        Ingrese IP valida.
      </b-form-invalid-feedback>
    </div>
    <!-- port_mds -->
    <div if="port_mds" class="mb-2">
      <label for="port_mds">Puerto MDS: *</label>
      <b-form-input
        id="port_mds"
        maxLength="45"
        :state="portMdsState"
        type="text"
        class="white-sm"
        v-model="form.port_mds"
        placeholder="Ingrese Puerto para MDS"
        trim
      />
      <b-form-invalid-feedback id="port_mds">
        Ingrese Puerto valido.
      </b-form-invalid-feedback>
    </div>
    <!-- port_hiso87 -->
    <div if="port_hiso87" class="mb-2">
      <label for="port_hiso87">Puerto Hiso87: *</label>
      <b-form-input
        id="port_hiso87"
        maxLength="45"
        :state="portHiso87State"
        type="text"
        class="white-sm"
        v-model="form.port_hiso87"
        placeholder="Ingrese Puerto para Hiso87"
        trim
      />
      <b-form-invalid-feedback id="port_hiso87">
        Ingrese Puerto valido.
      </b-form-invalid-feedback>
    </div>
    <!-- port_hiso93 -->
    <div if="port_hiso93" class="mb-2">
      <label for="port_hiso93">Puerto Hiso93: *</label>
      <b-form-input
        id="port_hiso93"
        maxLength="45"
        :state="portHiso93State"
        type="text"
        class="white-sm"
        v-model="form.port_hiso93"
        placeholder="Ingrese Puerto para Hiso93"
        trim
      />
      <b-form-invalid-feedback id="port_hiso93">
        Ingrese Puerto valido.
      </b-form-invalid-feedback>
    </div>
    <!-- port_visanet -->
    <div if="port_visanet" class="mb-2">
      <label for="port_visanet">Puerto Visanet: *</label>
      <b-form-input
        id="port_visanet"
        maxLength="45"
        :state="portVisanetState"
        type="text"
        class="white-sm"
        v-model="form.port_visanet"
        placeholder="Ingrese Puerto para Visanet"
        trim
      />
      <b-form-invalid-feedback id="port_visanet">
        Ingrese Puerto valido.
      </b-form-invalid-feedback>
    </div>
    <!-- project -->
    <b-form-group id="project" label="Proyecto: *" label-for="project">
      <b-form-select
        class="shadow-sm"
        id="project"
        :state="projectState"
        v-model="form.project_id"
      >
        <option disabled value="" selected="selected">Seleccione</option>
        <option
                v-bind:key="index"
                v-bind:value="p.id"
                v-for="(p, index) in projects"
              >
                {{ p.name }}
              </option>
      </b-form-select>
      <b-form-invalid-feedback id="project">
        Seleccione un proyecto.
      </b-form-invalid-feedback>
    </b-form-group>
    <br />
    <button class="btn btn-success btn-sm btn-block" type="submit">
      <font-awesome-icon icon="save" /><span class="ml-1">Guardar</span>
    </button>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {},
  data() {
    return {
      form: {
        name: "",
        ip: "",
        port_mds: "",
        port_hiso87: "",
        port_hiso93: "",
        port_visanet: "",
        project_id: "",
      },
      loading: false,
      errors: [],
    };
  },
  mounted(){
    this.getProjects();
  },
  methods: {
    ...mapActions("environment", ["createEnvironment", "getEnvironments"]),
    ...mapActions("project", ["getProjects"]),
    async validate() {
      this.errors = [];
      if (!this.nameState) {
        this.form.name = undefined;
        this.errors.push("falta name");
      }
      if (!this.ipState) {
        this.form.ip = undefined;
        this.errors.push("falta ip");
      }
      if (!this.portMdsState) {
        this.form.port_mds = undefined;
        this.errors.push("falta puerto mds");
      }
      if (!this.portHiso87State) {
        this.form.port_hiso87 = undefined;
        this.errors.push("falta puerto hiso87");
      }
      if (!this.portHis93State) {
        this.form.port_hiso93 = undefined;
        this.errors.push("falta puerto hiso93");
      }
      if (!this.portVisanetState) {
        this.form.port_visanet = undefined;
        this.errors.push("falta puerto visanet");
      }
      if (!this.projectState) {
        this.form.project_id = undefined;
        this.errors.push("falta proyecto");
      }
      if (this.errors.length == 0) {
        const resp = await this.createEnvironment(this.form);

        if (resp) {
          this.cleanForm();
          this.getEnvironments();
        }
      }
    },
    cleanForm() {
      this.form.name = "";
      this.form.ip = "";
      this.form.port_mds = "";
      this.form.port_hiso87 = "";
      this.form.port_hiso93 = "";
      this.form.port_visanet = "";
      this.form.project_id = "";
    },
  },
  computed: {
    ...mapState("project", ["projects"]),
    nameState() {
      let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
      if (this.form.name == "") {
        return null;
      } else if (
        nameRegex.test(this.form.name) &&
        this.form.name != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    ipState() {
      if (this.form.ip == "") {
        return null;
      } else if (
        this.form.ip != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    portMdsState() {
      if (this.form.port_mds == "") {
        return null;
      } else if (
        this.form.port_mds != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    portHiso87State() {
      if (this.form.port_hiso87 == "") {
        return null;
      } else if (
        this.form.port_hiso87 != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    portHiso93State() {
      if (this.form.port_hiso93 == "") {
        return null;
      } else if (
        this.form.port_hiso93 != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    portVisanetState() {
      if (this.form.port_visanet == "") {
        return null;
      } else if (
        this.form.port_visanet != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    projectState() {
      if (this.form.project_id == "") {
        return null;
      } else if (this.form.project_id == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
