import { HttpClient } from "../httpClient";

const googleStore = {
  namespaced: true,
  state: {
    emisorLoad: [],
    adquirienteLoad: [],
  },
  mutations: {
    setEmisorLoad(state, emisorLoad) {
      state.emisorLoad = emisorLoad;
    },
    setAdquirienteLoad(state, adquirienteLoad) {
      state.adquirienteLoad = adquirienteLoad;
    },
  },
  actions: {
    async uploadFiles({ commit }, payload) {
      try {
        let formData = new FormData();
        payload.files.forEach((file, index) => {
          formData.append(`files[]`, file.file);
          formData.append(`versionables[]`, file.versionable);
          formData.append(`versionNames[]`, file.versionName);
          formData.append(`clients[]`, file.client);
          formData.append(`types[]`, file.type);
        });

        payload.noFiles.forEach((noFile) => {
          formData.append(`noFileTests[]`, noFile.test);
          formData.append(`noFileVersionNames[]`, noFile.versionName);
          formData.append(`noFileVersions[]`, noFile.version);
          formData.append(`noFileClients[]`, noFile.client);
          formData.append(`noFileTypes[]`, noFile.type);
        });

        formData.append("emisor", payload.emisor);
        formData.append("adquiriente", payload.adquiriente);

        const response = await HttpClient.post(
          "/api/google-storage/upload-files",
          formData
        );

        return response.data;
      } catch (error) {
        console.error("Error al subir archivos:", error);
      }
    },
    async getFolders({ commit }, payload) {
      const { client, type, test } = payload;
      try {
        const response = await HttpClient.get(
          `/api/google-storage/folders/client/${client}/type/${type}/test/${test}`
        );

        if (type == "emisor") {
          commit("setEmisorLoad", response.data);
        } else {
          commit("setAdquirienteLoad", response.data);
        }
      } catch (error) {
        console.error("Error al obtener carpetas iniciales de emisor:", error);
      }
    },
    async getInitialAdquirienteFolders({ commit }, payload) {
      const { client, type, test } = payload;
      try {
        const response = await HttpClient.get(
          `/api/google-storage/adquiriente-folders/client/${client}/type/${type}/test/${test}`
        );
        commit("setAdquirienteLoad", response.data);
      } catch (error) {
        console.error(
          "Error al obtener carpetas iniciales de adquiriente:",
          error
        );
      }
    },
    async getFilesInFolder({ commit }, payload) {
      const { parentFolder, folderName } = payload;
      try {
        const response = await HttpClient.get(
          `/api/google-storage/${parentFolder}/folder/${folderName}`
        );
        if (parentFolder == "emisor") {
          commit("setEmisorLoad", response.data);
        }

        if (parentFolder == "adquiriente") {
          commit("setAdquirienteLoad", response.data);
        }
      } catch (error) {
        console.error(
          `Error al obtener archivos de ${parentFolder}/${folderName}:`,
          error
        );
      }
    },
    async getEmisorTests({ commit }) {
      try {
        const response = await HttpClient.get(
          "/api/google-storage/emisor-repository"
        );
        commit("setEmisorLoad", response.data);
      } catch (error) {
        console.error("Error al obtener carga de emisores:", error);
      }
    },
    async getAdquirienteTests({ commit }) {
      try {
        const response = await HttpClient.get(
          "/api/google-storage/adquiriente-repository"
        );
        commit("setAdquirienteLoad", response.data);
      } catch (error) {
        console.error("Error al obtener carga de adquirientes:", error);
      }
    },
    async downloadFile({ commit }, data) {
      const base = data.base;
      const folder = data.folder;
      const file = data.file;

      try {
        const response = await HttpClient.get(
          `/api/google-storage/download/base/${base}/folder/${folder}/file/${file}`
        );
        if (response && response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return response;
        }
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
        throw error;
      }
    },
    /* async getFilesInFolder(parentFolder, folderName) {
      try {
        const response = await HttpClient.get(
          `/api/google-storage/${parentFolder}/folder/${folderName}`
        );
        commit("setEmisorLoad", response.data);
      } catch (error) {
        console.error(
          `Error al obtener archivos de ${parentFolder}/${folderName}:`,
          error
        );
      }
    }, */
    async downloadTestdata({ commit }, {client, type, red, version }) {
      const encodedVersion = encodeURIComponent(version);
      const lowerRed = red.toLowerCase();
      try {
        const response = await HttpClient.get(
          `/api/google-storage/download-testdata/client/${client}/type/${type}/red/${lowerRed}/version/${encodedVersion}`
        );

        // Abrir una nueva pestaña con la URL para iniciar la descarga
        window.open(response.data.url, "_blank");
      } catch (error) {
        console.error("Error al obtener la URL de descarga:", error);
        throw error;
      }
    },
  },
  getters: {
    getEmisorLoad: (state) => state.emisorLoad,
    getAdquirienteLoad: (state) => state.adquirienteLoad,
  },
};

export default googleStore;
