<template lang="es">
<div>
    <div class="table-responsive">
        <table class="table text-center table-sm">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-bind:key="index" v-for="(c, index) in clients">
                    <td>{{c.name}}</td>
                    <td>

                        <button title="Editar" class="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#editModal" @click="loadEditModal(c)" style="margin-right: 5px;">
                            <font-awesome-icon icon="edit" />
                        </button>
                        <button title="Eliminar" @click="deleteCl(c.id)" class="btn btn-danger btn-sm" type="button">
                            <font-awesome-icon icon="trash-alt" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--Edit Modal-->
    <div class="modal" id="editModal" tabindex="-1" role="dialog">
        <!-- <div class="modal-dialog-lg" role="document"> -->
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Editar Cliente</h5>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <!-- name -->
                        <div>
                            <label id="email">Nombre Cliente: *</label>
                            <b-form-input id="name" maxLength="45" :state="nameState" type="text" class="shadow-sm" v-model="form.name" placeholder="Ingrese Nombre de cliente" trim />
                            <b-form-invalid-feedback> Ingrese un nombre valido. </b-form-invalid-feedback>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    <button type="button" class="btn btn-success" @click="validate()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapState,
    mapMutations
} from 'vuex';
export default {
    name: "Table-Client",
    components: {

    },
    props: {},
    update() {},
    data() {
        return {
            form: {
                name: "",
            },
            id: "",
        };
    },
    methods: {
        ...mapActions('client', ['getClients', 'deleteClient', 'updateClient']),
        ...mapMutations('client', ['setSelectedClient']),
        cleanForm() {
            this.form.name = "";
        },
        loadEditModal(u) {
            this.id = u.id;
            this.form.name = u.name;
        },
        async validate() {
            !this.nameState ? (this.form.name = undefined) : this.nameState;

            try {
                await this.updateClient({
                    id: this.id,
                    updateClientDto: this.form
                });
                this.getClients();
                $("#editModal").modal("hide");
            } catch (error) {
                console.error('Error al actualizar cliente:', error);
            }
        },
        async deleteCl(id) {
            const confirmed = await this.$swal({
                title: "¿Seguro que deseas eliminar este Cliente?",
                text: "(Esta acción es irreversible)",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Si",
            });

            if (confirmed) {
                try {
                    await this.deleteClient(id);
                    await this.getClients();
                } catch (error) {
                    console.error('Error al eliminar cliente o al obtener clientes:', error);
                }
            }
        }

    },
    computed: {
        ...mapState('client', ['clients', 'selectedClient']),
        nameState() {
            let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
            if (this.form.name == "") {
                return null;
            } else if (
                nameRegex.test(this.form.name) &&
                this.form.name != undefined
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style>
</style>
