<template>
<div class="app vh-100">
    <!-- <NavComponent class="shadow-sm mb-5" v-if="this.$store.getters.getCookie('user') != null && pathname!='not-found'"/> -->
    <transition name="fade" mode="out-in">
        <router-view :expressions="expressions"></router-view>
    </transition>
</div>
</template>

<script>
/* import NavComponent from './components/NavComponent' */
export default {
    components:{
       /*  NavComponent */
    },
    data(){
        return{
        pathname:'',
        expressions: {
            email:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            name: /^[a-zA-ZÀ-ÿ\s]{3,50}$/,
            phone:/^\d{8,12}$/,
            },
        }
    },
    watch: {
            '$route': {
                handler: function (to, from) {
                    this.pathname = to.name
                },
                immediate: true
            }
        }    
};
</script>

<style>
html{
    scroll-behavior: smooth;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
*{
    outline: none;
}
/* .app{
    font-family: 'Roboto', sans-serif;
    background-image: url("https://www.transparenttextures.com/patterns/transparent-square-tiles.png");
} */
</style>
