import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";

ls.config.encrypt = false;
const executionStore = {
  namespaced: true,
  state: {
    executions: [],
    executionsCompare: [],
    transactionCompare: [],
    executionsByType: [],
    addExecutionMode: false,
    executionMethod: "Pruebas",
    executionRunning: false,
    checkingEpsStatus: false,
    adquirienteVersion: "",
    emisorVersion: "",
    cancelButtonDisabled: false,
  },
  mutations: {
    setExecutions(state, executions) {
      state.executions = executions;
    },
    setExecutionsCompare(state, executionsCompare) {
      state.executionsCompare = executionsCompare;
    },
    setTransactionCompare(state, transactionCompare) {
      state.transactionCompare = transactionCompare;
    },
    setExecutionsByType(state, executionsByType) {
      state.executionsByType = executionsByType;
    },
    setAddExecutionMode(state, addExecutionMode) {
      state.addExecutionMode = addExecutionMode;
    },
    setExecutionMethod(state, executionMethod) {
      state.executionMethod = executionMethod;
    },
    setExecutionRunning(state, executionRunning) {
      state.executionRunning = executionRunning;
    },
    setCheckingEpsStatus(state, checkingEpsStatus) {
      state.checkingEpsStatus = checkingEpsStatus;
    },
    setAdquirienteVersion(state, adquirienteVersion) {
      state.adquirienteVersion = adquirienteVersion;
    },
    setEmisorVersion(state, emisorVersion) {
      state.emisorVersion = emisorVersion;
    },
    setCancelButtonDisabled(state, cancelButtonDisabled) {
      state.cancelButtonDisabled = cancelButtonDisabled;
    },
  },
  actions: {
    async getUserExecutions({ commit }, data) {
      try {
        const response = await HttpClient.post("/api/gitlab/executions", data);
        const executionsWithChecked = response.data.map(execution => ({
          ...execution,
          checked: false
        }));
        
        commit("setExecutions", executionsWithChecked);
      } catch (error) {
        console.error("Error al obtener ejecuciones:", error);
      }
    },
    async getExecutionsByType({ commit }, { idUser, type }) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/executions/user/${idUser}/type/${type}`
        );
        commit("setExecutionsByType", response.data);
      } catch (error) {
        console.error("Error al obtener ejecuciones:", error);
      }
    },
    async downloadTrace({ commit }, { tsId, numTxn, idPipeline }) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/download-trace/ts/${tsId}/txn/${numTxn}/pipeline/${idPipeline}`
        );
        if (response && response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "trace.txt");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return response;
        }
      } catch (error) {
        console.error("Error al descargar el archivo trace:", error);
        throw error;
      }
    },
    async downloadEvtlog({ commit }, { red, idPipeline }) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/download-evtlog/red/${red}/pipeline/${idPipeline}`
        );
        if (response && response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "evtlog.txt");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return response;
        }
      } catch (error) {
        console.error("Error al descargar el archivo evtlog:", error);
        throw error;
      }
    },
  },
  getters: {
    getExecutions: (state) => state.executions,
    getExecutionsCompare: (state) => state.executionsCompare,
    getTransactionCompare: (state) => state.transactionCompare,
    getExecutionsByType: (state) => state.executionsByType,
    getAddExecutionMode: (state) => state.addExecutionMode,
  },
};

export default executionStore;
