<template>
  <div>
    <div
      class="modal fade"
      id="modalVersion"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalVersion"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pt-2 px-1">
            <h5 class="modal-title m-3">
              <b>Versionamiento {{ type }}</b>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="nav nav-tabs m-2" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              @click="showInput = true"
              >Nuevo Versionado</a
            >
            <a
              class="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              @click="showInput = false"
              >Versionado Existente</a
            >
          </div>

          <div class="modal-body p-3">
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div v-if="showInput" class="row mt-2">
                  <p class="text-info px-3">
                    <font-awesome-icon icon="info-circle" /> Puedes versionar tu
                    Test Data con un nombre especifico.
                  </p>
                  <div class="col-6">
                    <label>Nombre</label>
                    <b-form-input
                      class="form-control form-control-sm"
                      type="text"
                      v-model="name"
                    ></b-form-input>
                  </div>
                </div>
                <small
                  >Si omites el nombre de tu Test Data, este se versionará por
                  defecto.</small
                >
              </div>

              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div class="row mt-2">
                  <div class="col-6">
                    <label>Versiones Existentes</label>

                    <b-form-select v-model="selectedVersion">
                      <option disabled value="" selected="selected">
                        Seleccione
                      </option>
                      <option
                        v-bind:key="index"
                        v-bind:value="version.name"
                        v-for="(version, index) in type == 'emisor'
                          ? emisorLoad
                          : adquirienteLoad"
                      >
                        {{ version.name }}
                      </option>
                    </b-form-select>
                    <b-form-invalid-feedback id="pass">
                      Seleccione Proyecto.
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Omitir
                </button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-primary" @click="store">
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    type: String,
    saveVersionName: Function,
  },
  data() {
    return {
      name: "",
      showInput: true,
      selectedVersion: "",
    };
  },
  methods: {
    store() {
      if (this.showInput) {
        this.$emit("saveVersionName", this.name, this.type);
        this.name = "";
        this.selectedVersion = "";
      } else {
        this.$emit("saveVersionName", this.selectedVersion, this.type);
        this.name = "";
        this.selectedVersion = "";
      }
    },
  },
  computed: {
    ...mapState("google", ["emisorLoad", "adquirienteLoad"]),
  },
};
</script>

<style>
.nav-tabs .nav-link {
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  margin-right: 0.25rem;
}
</style>
