<template>
  <form @submit.prevent="validate" class="needs-validation" novalidate>
    <!-- name -->
    <div if="name" class="mb-2">
      <label for="name">Nombre Usuario: *</label>
      <b-form-input
        id="name"
        maxLength="45"
        :state="nameState"
        type="text"
        class="white-sm"
        v-model="form.name"
        placeholder="Ingrese Nombre de usuario"
        trim
      />
      <b-form-invalid-feedback id="name">
        Ingrese Nombre valido.
      </b-form-invalid-feedback>
    </div>
    <!-- type -->
    <b-form-group id="type" label="Tipo Usuario: *" label-for="type">
      <b-form-select
        class="shadow-sm"
        id="selManager"
        :state="typeState"
        v-model="form.type"
      >
        <option disabled value="" selected="selected">Seleccione</option>
        <option value="1">Administrador</option>
        <option value="2">QA</option>
        <option value="3">Acceso Total</option>
      </b-form-select>
      <b-form-invalid-feedback id="type">
        Ingrese tipo de usuario.
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- email -->
    <div if="email" class="mb-2">
      <label for="email">Correo Electronico: *</label>
      <b-form-input
        id="email"
        maxLength="45"
        :state="emailState"
        type="text"
        class="form-control"
        v-model="form.email"
        placeholder="Ingrese correo electronico"
        trim
      />
      <b-form-invalid-feedback id="email">
        Ingrese email.
      </b-form-invalid-feedback>
    </div>

    <!-- pass -->
    <div if="pass" class="mb-2">
      <label for="pass">Contraseña: *</label>
      <b-form-input
        id="pass"
        maxLength="45"
        :state="passState"
        type="password"
        class="form-control"
        v-model="form.password"
        placeholder="Ingrese correo contraseña"
        trim
      />
      <b-form-invalid-feedback id="pass">
        Ingrese Contraseña.
      </b-form-invalid-feedback>
    </div>
    <br />
    <button class="btn btn-success btn-sm btn-block" type="submit">
      <font-awesome-icon icon="save" /><span class="ml-1">Guardar</span>
    </button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      form: {
        name: "",
        type: "",
        email: "",
        password: "",
      },
      loading: false,
      errors: [],
    };
  },
  methods: {
    ...mapActions("user", ["createUser", "getUsers"]),
    async validate() {
      this.errors = [];
      if (!this.nameState) {
        this.form.name = undefined;
        this.errors.push("falta name");
      }
      if (!this.typeState) {
        this.form.type = undefined;
        this.errors.push("falta type");
      }
      if (!this.emailState) {
        this.form.email = undefined;
        this.errors.push("falta email");
      }
      if (!this.passState) {
        this.form.password = undefined;
        this.errors.push("falta password");
      }
      if (this.errors.length == 0) {
        this.form.type = parseInt(this.form.type);
        const resp = await this.createUser(this.form);

        if (resp) {
          this.cleanForm();
          this.getUsers();
        }
      }
    },
    //TO DO - VERIFY FUNCTION SEND TOEKN AND CLEANFORM
    registerUser() {
      axios
        .post("/store-user", {
          form: this.form,
        })
        .then((response) => {
          this.$swal({
            title: "Se agrego un nuevo Usuario!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          this.$emit("getUsers");
          this.sendToken();
          this.cleanForm();
        })
        .catch((error) => {
          this.$swal({
            title: "El USUARIO ya se encuentra registrado!",
            icon: "warning",
            timer: 2500,
            showConfirmButton: false,
          });
        });
    },
    sendToken() {
      axios
        .post("/send-mail", {
          form: this.form,
          sendUser: true,
        })
        .then((response) => {
          this.sentToken = true;
        })
        .catch((error) => {
          this.errors.push(error.response.data);
        });
    },
    cleanForm() {
      this.form.name = "";
      this.form.type = "";
      this.form.email = "";
      this.form.password = "";
    },
  },
  computed: {
    nameState() {
      let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
      if (this.form.name == "") {
        return null;
      } else if (
        nameRegex.test(this.form.name) &&
        this.form.name != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    passState() {
      let passRegex = /^[A-Za-z0-9\s]{6,50}$/g;
      if (this.form.password == "") {
        return null;
      } else if (
        passRegex.test(this.form.password) &&
        this.form.password != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    emailState() {
      let emailRegex =
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if (this.form.email == "") {
        return null;
      } else if (
        emailRegex.test(this.form.email) &&
        this.form.email != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    typeState() {
      if (this.form.type == "") {
        return null;
      } else if (this.form.type == undefined) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
