import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";
import Swal from "sweetalert2";

ls.config.encrypt = false;
const projectStore = {
  namespaced: true,
  state: {
    projects: [],
    userBranches: {},
    projectBranches: {},
    activeProjectId: null,
  },
  mutations: {
    setActiveProjectId(state, projectId) {
      state.activeProjectId = projectId;
    },
    setProjects(state, projects) {
      state.projects = projects;
    },
    setUserBranches(state, { projectId, branches }) {
      state.userBranches[projectId] = branches;
    },
    toggleProjectVisibility(state, projectId) {
      if (
        !state.projectBranches[projectId] ||
        !state.projectBranches[projectId].visible
      ) {
        state.activeProjectId = projectId;
        Vue.set(state.projectBranches, projectId, {
          visible: true,
          branches: state.projectBranches[projectId]
            ? state.projectBranches[projectId].branches
            : [],
        });
      } else {
        state.activeProjectId = null;
        Vue.set(state.projectBranches[projectId], "visible", false);
      }
    },
    setProjectBranches(state, { projectId, branches }) {
      if (!state.projectBranches[projectId]) {
        Vue.set(state.projectBranches, projectId, {
          visible: true,
          branches: [],
        });
      }
      state.projectBranches[projectId].branches = branches;
    },
  },
  actions: {
    alert({ commit }, msg) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: msg,
      });
    },
    async deleteUserBranch({ commit, state }, { branchId, projectId }) {
      try {
        await HttpClient.delete(`/api/project/deleteBranch/${branchId}`);
    
        if (!state.projectBranches[projectId]) {
          console.error(`No se encontraron ramas para el projectId: ${projectId}`);
          return;
        }
    
        const updatedBranches = state.projectBranches[projectId].branches.filter(branch => branch.id !== branchId);
    
        commit("setProjectBranches", { projectId, branches: updatedBranches });
      } catch (error) {
        console.error("Error al eliminar la rama:", error);
      }
    }
,    
    async getUserBranches({ commit }, { userId, projectId }) {
      try {
        const response = await HttpClient.post("/api/project/getUserBranches", {
          user_id: userId,
          project_id: projectId,
        });
        commit("setProjectBranches", { projectId, branches: response.data });
      } catch (error) {
        console.error("Error al obtener ramas del usuario:", error);
      }
    },
    async getProjects({ commit }) {
      try {
        const response = await HttpClient.get("/api/project");
        commit("setProjects", response.data);
      } catch (error) {
        console.error("Error al obtener proyectos:", error);
      }
    },
    async getProjectsGitlab({ commit }) {
      try {
        const response = await HttpClient.get("/api/project");
        commit("setProjects", response.data);
      } catch (error) {
        console.error("Error al obtener proyectos:", error);
      }
    },
    async getProjectById({ commit }, id) {
      try {
        const response = await HttpClient.get(
          `/project/filterId?idProject=${id}`
        );
      } catch (error) {
        console.error("Error al obtener proyecto por ID:", error);
      }
    },
    async createProject({ commit, dispatch }, createProjectDto) {
      const msg = "Se agrego un nuevo Proyecto!";
      try {
        const response = await HttpClient.post(
          "/api/project",
          createProjectDto
        );
        if (response) {
          dispatch("alert", msg);
          return true;
        }
      } catch (error) {
        console.error("Error al crear proyecto:", error);
      }
    },
    async updateProject({ commit }, { id, updateProjectDto }) {
      try {
        const response = await HttpClient.patch(
          `/api/project/${id}`,
          updateProjectDto
        );
      } catch (error) {
        console.error("Error al actualizar proyecto:", error);
      }
    },
    async deleteProject({ commit, dispatch }, id) {
      const msg = "El Proyecto fue eliminado correctamente.";
      try {
        const response = await HttpClient.delete(`/api/project/${id}`);
        dispatch("alert", msg);
      } catch (error) {
        console.error("Error al eliminar proyecto:", error);
      }
    },
  },
  getters: {
    getProjects: (state) => state.projects,
    getUserBranches: (state) => (projectId) =>
      state.userBranches[projectId] || [],
  },
};

export default projectStore;
