require("./bootstrap");

window.Vue = require("vue");
import Vue from "vue";
import router from './routes/router';
import store from './storage';
import App from "./App.vue";

// Dependencias plantilla
import GlobalComponents from "./globalComponents";
Vue.use(GlobalComponents);
import GlobalDirectives from "./globalDirectives";
Vue.use(GlobalDirectives);
import Notifications from "./components/NotificationPlugin";
Vue.use(Notifications);
import MaterialDashboard from "./material-dashboard";
Vue.use(MaterialDashboard);
import Chartist from "chartist";
Vue.prototype.$Chartist = Chartist;

// FIN Dependencias Plantilla

Vue.use(VueRouter);

axios.defaults.baseURL = "/api"

//Paginator
import VuePagination from 'vue-pagination-2';
Vue.component('p-pagination', VuePagination);

//Bloque para importar cualquier otra dependencia
//VueGoogleCharts
import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)
//vue-select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

//alert
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
//bootstrap-vue
/* import 'bootstrap/dist/css/bootstrap.css'; */
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

//Font awesome icons
import {
    faSave, faTools, faPlusCircle, faPaperPlane,
    faCheckCircle, faTimesCircle, faUserCog, faClock,
    faCalculator, faSignOutAlt, faTrashAlt, faHome,
    faUsers, faProjectDiagram, faUserTag, faSignInAlt,
    faCamera, faTimes, faCheck, faSearch, faAngleLeft,
    faAngleRight, faExclamationCircle, faAddressCard,
    faGhost, faEdit, faNotEqual, faMicrochip, faUpload, faEye, faExclamationTriangle,faBug,faListOl,faDownload, faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faProjectDiagram, faUsers, faUserCog,
    faSignOutAlt, faPlusCircle, faSave,
    faUserTag, faTrashAlt, faCheckCircle,
    faTimesCircle, faTools, faClock, faCalculator,
    faPaperPlane, faHome, faSignInAlt, faCamera, faTimes,
    faCheck, faSearch, faAngleLeft, faAngleRight,
    faExclamationCircle, faAddressCard, faGhost,
    faEdit, faNotEqual, faMicrochip, faUpload, faEye,faExclamationTriangle,faBug,faListOl,faDownload, faInfoCircle);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import VueRouter from "vue-router";
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({ el: "#app", render: h => h(App), router, store, data: { Chartist: Chartist }}); // Implementacion plantilla
//const app = new Vue(Vue.util.extend({ router, store }, App)).$mount("#app");
