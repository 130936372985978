<template>
  <div>
    <div
      class="modal fade"
      id="modalComparations"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div
        class="modal-dialog modal-super-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <div class="flex-grow-1 d-flex justify-content-center">
              <h4 class="modal-title mb-2">
                <b>Comparación de Ejecuciones</b>
              </h4>
            </div>
            <div class="d-flex justify-content-center p-2">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                :disabled="disabledTramaCompare"
                @click="openTransactionTramaEnvModal"
              >
                Comparar trama env
              </button>
            </div>
            <div class="d-flex justify-content-center p-2">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                :disabled="disabledTramaCompare"
                @click="openTransactionTramaRespModal"
              >
                Comparar trama resp
              </button>
            </div>
            <div class="d-flex justify-content-center p-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="showErrors"
                  v-model="showErrors"
                />
                <label class="custom-control-label" for="showErrors"
                  >Mostrar Errores</label
                >
              </div>
            </div>
            <div class="d-flex justify-content-center p-2">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="showDifferences"
                  v-model="showDifference"
                />
                <label class="custom-control-label" for="showDifferences"
                  >Mostrar diferencias</label
                >
              </div>
            </div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div
              v-if="
                executionsCompare.length > 1 && executionsCompare.length < 5
              "
              class="row"
            >
              <div
                v-for="(execution, ind) in filteredTransactions"
                v-bind:key="ind"
                class="col-sm-3"
              >
                <div class="card mb-3">
                  <div class="card-header" style="text-align: center">
                    <b
                      >Ejecución: {{ execution.pipeline_id }} / Fecha Ejecución:
                      {{ execution.transactionSummary.date_execution }}</b
                    >
                  </div>
                  <div class="card-body">
                    <table
                      v-if="
                        execution.transactionSummary.transactions.length > 0
                      "
                      class="table text-center table-sm table-hover"
                    >
                      <thead>
                        <tr>
                          <th>N° txn</th>
                          <th>Type Txn</th>
                          <th>E.RC</th>
                          <th>RC</th>
                          <th>Resultado</th>
                          <th>Trace</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-bind:key="index"
                          v-for="(t, index) in execution.transactionSummary
                            .transactions"
                          style="font-size: small; color: black"
                        >
                          <td>{{ t.header }}</td>
                          <td>{{ t.m_type }}</td>
                          <td
                            :style="`${
                              t.expected_rc == ''
                                ? 'color:#00bcd4'
                                : 'color:green'
                            }`"
                          >
                            <b>{{
                              t.expected_rc == "" ? "-" : t.expected_rc
                            }}</b>
                          </td>
                          <td
                            :style="`${
                              t.result == 1
                                ? 'color:green'
                                : t.result == 3
                                ? 'color:#00bcd4'
                                : 'color:red'
                            }`"
                          >
                            <b>{{ t.rc == "" ? "-" : t.rc }}</b>
                          </td>
                          <td v-if="t.result == 1">
                            <font-awesome-icon
                              icon="check-circle"
                              class="text-success"
                              size="1x"
                            />
                          </td>
                          <td v-else-if="t.result == 0">
                            <font-awesome-icon
                              class="text-danger"
                              icon="times-circle"
                              size="1x"
                            /><br />
                          </td>
                          <td v-else>
                            <font-awesome-icon
                              icon="tools"
                              class="text-info"
                              size="1x"
                            /><br />
                          </td>
                          <td>
                            <font-awesome-icon
                              icon="download"
                              class="text-primary"
                              size="1x"
                              @click="dloadTrace(t.ts_id, t.header)"
                            />
                          </td>
                          <!-- <td>
                            <input
                              type="checkbox"
                              id="checkbox"
                              @input="insertCheck(t)"
                            />
                          </td> -->
                          <td>
                            <input
                              type="checkbox"
                              :id="`checkbox-${execution.id}-${t.id}`"
                              v-model="t.checked"
                              @change="insertCheck(t)"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-else>
                      <p class="text-center">
                        No se han encontrado diferencias.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="n in 4 - executionsCompare.length"
                :key="'new-card-' + n"
                class="col-sm-3"
              >
                <div
                  class="card add-new-card mb-3 d-flex justify-content-center align-items-center"
                  @click="addExecution"
                >
                  <div class="card-body text-center">
                    <span class="plus-icon">+</span>
                    <p>Agregar Ejecución</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalTransactionComparison
      data-target="#modalTransactionComparison"
      :transactions="transactionCompare"
      :mode="mode"
    ></ModalTransactionComparison>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ModalTransactionComparison from "./ModalTransactionComparison.vue";
export default {
  name: "Modal-Comparations-Transactions",
  components: {
    ModalTransactionComparison,
  },
  props: {
    dataTarget: {
      type: String,
      default: "",
    },
    executions: Array,
  },
  mounted() {},
  watch: {},
  data() {
    return {
      showDifference: false,
      showErrors: false,
      mode: "",
    };
  },
  methods: {
    ...mapActions("execution", ["downloadTrace"]),
    ...mapMutations("execution", [
      "setAddExecutionMode",
      "setExecutionsCompare",
      "setTransactionCompare",
    ]),
    addExecution() {
      this.setAddExecutionMode(true);
      $("#modalComparations").modal("hide");
    },
    closeModal() {
      this.executionsCompare.forEach((execution) => {
        execution.transactionSummary.transactions.forEach((transaction) => {
          transaction.checked = false;
        });
      });

      this.setTransactionCompare([]);
      this.setAddExecutionMode(false);
      $("#modalComparations").modal("hide");
    },
    dloadTrace(tsId, numTxn) {
      this.downloadTrace({ tsId, numTxn });
    },
    openTransactionTramaEnvModal() {
      this.mode = "env";
      $("#modalTransactionComparison").modal("show");
    },
    openTransactionTramaRespModal() {
      this.mode = "resp";
      $("#modalTransactionComparison").modal("show");
    },
    insertCheck(transaction) {
      const selectedTransactions = [...this.transactionCompare];

      const index = selectedTransactions.findIndex(
        (t) => t.id === transaction.id
      );

      if (index === -1) {
        if (selectedTransactions.length < 2) {
          selectedTransactions.push(transaction);
        } else {
          this.$nextTick(() => {
            transaction.checked = false;
          });

          this.$swal({
            text: "La cantidad máxima a comparar son 2 transacciones.",
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
          });
        }
      } else {
        selectedTransactions.splice(index, 1);
      }

      this.setTransactionCompare(selectedTransactions);
    },
  },
  computed: {
    ...mapState("execution", ["executionsCompare", "transactionCompare"]),
    disabledTramaCompare() {
      let cond = true;

      if (this.transactionCompare.length == 2) {
        cond = false;
      }

      return cond;
    },
    filteredTransactions() {
      if (this.showDifference) {
        const allTransactions = this.executionsCompare.flatMap(
          (execution) => execution.transactionSummary.transactions
        );

        return this.executionsCompare.map((execution) => {
          const filteredTransactions =
            execution.transactionSummary.transactions.filter((transaction) => {
              const matchingTransactions = allTransactions.filter(
                (t) => t.header === transaction.header
              );

              const isUnique = matchingTransactions.length === 1;

              const hasDifferences = matchingTransactions.some((match) => {
                return (
                  match.m_type !== transaction.m_type ||
                  match.rc !== transaction.rc ||
                  match.result !== transaction.result
                );
              });

              return isUnique || hasDifferences;
            });

          return {
            ...execution,
            transactionSummary: {
              ...execution.transactionSummary,
              transactions: filteredTransactions,
            },
          };
        });
      }

      if (this.showErrors) {
        return this.executionsCompare.map((execution) => {
          const filteredTransactions =
            execution.transactionSummary.transactions.filter((transaction) => {
              return !transaction.result || transaction.result == 3;
            });

          return {
            ...execution,
            transactionSummary: {
              ...execution.transactionSummary,
              transactions: filteredTransactions,
            },
          };
        });
      }

      return this.executionsCompare;
    },
  },
};
</script>

<style>
.modal-backdrop {
  top: 0;
  left: -2;
  z-index: 1040;
  width: 0vw;
  height: 100vh;
  background-color: #000;
}

.md-table-cell {
  height: 1%;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  line-height: 0.1px;
}

.modal-body-trama {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
}

.table td,
.table th {
  padding: 0.3rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}

.md-card .table {
  margin-left: 15px;
  border-left-style: solid;
  border-left-width: 0px;
  margin-right: 15px;
}

.close {
  display: none;
}
.modal-super-xl {
  max-width: 90%;
}
.card {
  min-height: 250px;
}

.add-new-card {
  border: 2px dashed #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-new-card .card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-plus-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-icon {
  font-size: 24px;
  color: #aaa;
  margin-right: 0.5rem;
}
.modal-dialog.modal-super-xl .modal-content {
  max-height: 600px;
}
</style>
