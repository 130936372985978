<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a @click="handleClick" href="https://www.kvz.cl/" class="col-">
        <div class="logo-img text-center">
          <div
            style="
              display: inline-block;
              background-color: white;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 5px;
            "
          >
            <img :src="imgLogo" alt="" style="height: 4em" />
          </div>
        </div>
      </a>
      <a class="simple-text logo-normal col- text-center">
        {{ title + " " }}<br />{{ buildVersion }}
      </a>
      <div class="mt-2 text-center d-block">
        <b-button :disabled="executionRunning" v-b-toggle.sidebar-right class="w-75">
          <font-awesome-icon icon="user-cog" />
          {{ loggedUser.name }}
        </b-button>
      </div>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  mounted() {
    this.version = process.env.MIX_VERSION;
  },
  data() {
    return {
      version: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "Gestor de Pruebas",
    },
    buildVersion: {
      type: String,
      default: process.env.MIX_VERSION,
    },
    sidebarBackgroundImage: {
      type: String,
      default: require("../../assets/img/sidebar-6.png"),
    },
    imgLogo: {
      type: String,
      default: require("../../assets/imgKvz/logo.png"),
    },
    sidebarItemColor: {
      type: String,
      default: "purple",
      validator: (value) => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    ...mapState("auth", ["loggedUser"]),
    ...mapState("execution", ["executionRunning"]),
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      };
    },
  },
  methods: {
    handleClick(event) {
      if (this.executionRunning) {
        event.preventDefault();
      }
    }
  },
};
</script>

<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
</style>
