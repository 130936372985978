<template>
  <form @submit.prevent="validate" class="needs-validation" novalidate>
    <!-- name -->
    <div if="name" class="mb-2">
      <label for="name">Nombre Cliente: *</label>
      <b-form-input
        id="name"
        maxLength="45"
        :state="nameState"
        type="text"
        class="white-sm"
        v-model="form.name"
        placeholder="Ingrese Nombre de cliente"
        trim
      />
      <b-form-invalid-feedback id="name">
        Ingrese Nombre valido.
      </b-form-invalid-feedback>
    </div>
    <br />
    <button class="btn btn-success btn-sm btn-block" type="submit">
      <font-awesome-icon icon="save" /><span class="ml-1">Guardar</span>
    </button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      form: {
        name: "",
      },
      loading: false,
      errors: [],
    };
  },
  methods: {
    ...mapActions("client", ["createClient", "getClients"]),
    async validate() {
      this.errors = [];
      if (!this.nameState) {
        this.form.name = undefined;
        this.errors.push("falta name");
      }
      if (this.errors.length == 0) {
        const resp = await this.createClient(this.form);

        if (resp) {
          this.cleanForm();
          this.getClients();
        }
      }
    },
    cleanForm() {
      this.form.name = "";
    },
  },
  computed: {
    nameState() {
      let nameRegex = /^[a-zA-ZÀ-ÿ\s]{3,50}$/;
      if (this.form.name == "") {
        return null;
      } else if (
        nameRegex.test(this.form.name) &&
        this.form.name != undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
