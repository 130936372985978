<template>
<div class="container-fluid">
    <md-card>
        <md-card-header data-background-color="purple">
            <h4 class="title">Registro de Usuarios</h4>
            <p class="category">
               Aquí puedes administrar los usuarios del sistema y asignarles proyectos.
            </p>
        </md-card-header>

        <md-card-content>
           <div class="row">
        <div class="col-md-1" />
        <div class="col-md-2">
            <h5 class="text-center">Nuevo Usuario</h5>
            <div class="col-md-12">
                <FormUserComponent/>
            </div>
        </div>
        <div class="col-md-8">
            <h5 class="text-center">Usuarios</h5>
            <div class="col-md-12">
                <TableUsersComponent/>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-spinner label="Cargando..." v-if="spinner==true" variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </div>
    </div>
        </md-card-content>
    </md-card>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import FormUserComponent from "../../components/Forms/FormUserComponent.vue";
import TableUsersComponent from "../../components/Tables/TableUsersComponent.vue";
export default {
    components: {
        TableUsersComponent,
        FormUserComponent
    },
    mounted() {
        this.getUsers();
        this.getProjects();
    },
    data() {
        return {
            usersProjectManager: [],
            spinner: false,
        };
    },
    methods: {
        ...mapActions('user', ['getUsers']),
        ...mapActions('project', ['getProjects']),
    },

};
</script>

<style>
</style>
