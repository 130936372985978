<template>
    <div class="modal fade" id="modalViewTest" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><b>Tests</b></h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="scrollable-table">
                        <table class="table table-striped table-responsive">
                            <thead>
                                <tr>
                                    <th
                                        v-for="(value,
                                        key) in transformedData[0]"
                                        :key="key"
                                    >
                                        {{ key }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(test, index) in transformedData"
                                    :key="index"
                                >
                                    <td v-for="(value, key) in test" :key="key">
                                        {{ value }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal-test",
    props: {
        trama: {
            type: Array,
            default: () => []
        },
        dataTarget: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            transformedData: []
        };
    },
    mounted() {
        this.updateTransformedData();
    },
    watch: {
        trama: {
            handler: "updateTransformedData",
            deep: true
        }
    },
    methods: {
        transformData() {
            return this.trama.map(test => {
                return Object.entries(test).reduce((result, [key, value]) => {
                    result[key.trim()] =
                        typeof value === "string" ? value.trim() : value;
                    return result;
                }, {});
            });
        },
        updateTransformedData() {
            this.transformedData = this.transformData();
        }
    }
};
</script>

<style scoped>
.table th,
.table td {
    white-space: nowrap;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.scrollable-table {
    overflow-x: auto;
    max-height: 600px;
    display: block;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
}
</style>
