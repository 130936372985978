import ls from 'localstorage-slim';
import store from '../storage';

const isAuthenticatedGuard = async (to, from, next) => {
  if (ls.get('user') !== null) {
    store.commit('auth/setUserData', JSON.parse(ls.get('user')));
    return true;
  } else {
    return false;
  }
};

export default isAuthenticatedGuard;
