<template>
<md-card class="md-card-profile">
    <div class=""></div>

    <md-card-content v-if="lastExecution && projectSelected" style="padding-bottom: 0px;padding-top: 8px;">       
        <ul class="text-left">
            <li><b>Fecha:</b> {{formatDate(lastExecution.created_at)}}</li>
            <li><b>Proyecto:</b> {{ projectSelected.name }}</li>
            <li><b>Rama:</b> {{ lastExecution.project_branch }}</li>
            <li><b>Adquiriente:</b> {{ lastExecution.red }}</li>
            <li><b>Duracion de Ejecución:</b> {{ lastExecution.execution_time }}</li>
            
        </ul>
      
        <em>

        </em>
    </md-card-content>
    <md-card-content v-else>
        <h6 class="category text-gray">Gestor de Pruebas</h6>
        <h4 class="card-title">Ultima Ejecución</h4>
        <ul class="text-left">
            <li><b> No has realizado Ejecuciones de Pruebas.</b></li>
        </ul>
        <em>

        </em>
    </md-card-content>
</md-card>
</template>

<script>
import moment from 'moment';
export default {
    name: "data-last-execution-card",
    props: {
        lastExecution: Object,
        projectSelected: Object
    },
    watch: {     
    },
    data() {
        return {
           
        };
    },
    methods: {
          formatDate(date) {
            return moment(date).format('DD-MM-YYYY HH:mm');            
        },
    }
};
</script>

<style scoped>
.md-card-content {
    font-size: 16px;
    line-height: 22px;
}
.card-footer {
    padding: .75rem 1.25rem;
    padding-top: 0px;
    padding-bottom: 1px;
    background-color: white;
    border-top: white;
margin-top: -22px;
}
</style>
