import ls from "localstorage-slim";
import { HttpClient } from "../httpClient";

ls.config.encrypt = false;

const gitlabStore = {
  namespaced: true,
  state: {
    gitlabProjects: [],
    gitlabBranches: [],
    pipelinesProject: [],
    idPipeline: "",
    idGitProject: "",
    jobs: [],
    jobsLogs: "",
    selectedProject: "",
    epsStatus: false,
  },
  mutations: {
    setGitlabProjects(state, projects) {
      state.gitlabProjects = projects;
    },
    setGitlabBranches(state, branches) {
      state.gitlabBranches = branches;
    },
    setPipelinesProject(state, pipelines) {
      state.pipelinesProject = pipelines;
    },
    setIdPipeline(state, id) {
      state.idPipeline = id;
    },
    setIdGitProject(state, id) {
      state.idGitProject = id;
    },
    setJobs(state, jobs) {
      state.jobs = jobs;
    },
    setJobsLogs(state, jobsLogs) {
      state.jobsLogs = jobsLogs;
    },
    setSelectedProject(state, selectedProject) {
      state.selectedProject = selectedProject;
    },
    setEpsStatus(state, epsStatus) {
      state.epsStatus = epsStatus;
    },
  },
  actions: {
    async getGitlabProjects({ commit }) {
      try {
        const response = await HttpClient.get("/api/gitlab/projects");
        commit("setGitlabProjects", response.data);
      } catch (error) {
        console.error("Error al obtener projectos gilab:", error);
      }
    },
    async getGitlabBranchesByProject({ commit }, idGitProject) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/project/${idGitProject}/branches`
        );
        commit("setGitlabBranches", response.data);
      } catch (error) {
        console.error("Error al obtener projectos gilab:", error);
      }
    },
    async checkEpsStatus({ commit, dispatch }, checkEpsStatusDto) {
      try {
        const response = await HttpClient.post(
          "/api/gitlab/check-eps-status",
          checkEpsStatusDto
        );

        if (response) {
          await dispatch("getPipeline", checkEpsStatusDto.idProject);
          return true;
        }
      } catch (error) {
        console.error("Error al ejecutar pipeline status EPS:", error);
      }
    },
    async cancelExecution({ commit, dispatch }, cancelPipelineDto) {
      try {
        const response = await HttpClient.post(
          "/api/gitlab/cancel-pipeline",
          cancelPipelineDto
        );

        if (response) {
          return true;
        }
      } catch (error) {
        console.error("Error al cancelar la ejecución:", error);
      }
    },
    async executePipeline({ commit, dispatch }, executePipelineDto) {
      try {
        const response = await HttpClient.post(
          "/api/gitlab/execute-pipeline",
          executePipelineDto
        );
        if (response) {
          await dispatch("getPipeline", executePipelineDto.idProject);
          return true;
        }
      } catch (error) {
        console.error("Error al ejecutar pipeline:", error);
      }
    },
    async getPipeline({ commit }, idProject) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/pipelines/${idProject}`
        );
        commit("setPipelinesProject", response.data);
        commit("setIdPipeline", response.data[0].id);
        commit("setIdGitProject", idProject);
      } catch (error) {
        console.error("Error al obtener pipeline:", error);
      }
    },
    async getJobsProject({ commit }, { idProject, pipelineId }) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/projects/${idProject}/pipelines/${pipelineId}/jobs`
        );
        commit("setJobs", response.data);
      } catch (error) {
        console.error("Error al obtener jobs del proyecto:", error);
      }
    },
    async getJobsLogs({ commit }, { idProject, jobId }) {
      try {
        const response = await HttpClient.get(
          `/api/gitlab/projects/${idProject}/jobs/${jobId}/logs`
        );
        commit("setJobsLogs", response.data.data);
        if (response.data.data.includes("EPS status: OK")) {
          console.log("EPS encendido");
          commit("setEpsStatus", true);
        } else if (response.data.data.includes("EPS status: NOT OK")) {
          console.log("EPS apagado 1");
          commit("setEpsStatus", false);
        } else if (response.data.data.includes("is not running; skipping connection.")){
          console.log("EPS apagado 2");
          commit("setEpsStatus", false);
        }
      } catch (error) {
        console.error("Error al obtener logs del job:", error);
      }
    },
    async doCloneLogs({ commit }, params) {
      try {
        const time = params.time;
        const project = parseInt(params.project, 10);
        const branch = params.branch;
        const user = parseInt(params.user, 10);
        const ad = params.ad;
        const countJobs = parseInt(params.countJobs, 10);
        const idPipeline = parseInt(params.idPipeline, 10);
        const adVersion = params.adVersion;
        const emVersion = params.emVersion;

        const url = `/api/gitlab/clone-logs?time=${time}&project=${project}&branch=${branch}&user=${user}&ad=${ad}&countJobs=${countJobs}&idPipeline=${idPipeline}&adVersion=${adVersion}&emVersion=${emVersion}`;
        const response = await HttpClient.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message || "Error desconocido");
      }
    },
  },
  getters: {
    getGitlabProjects(state) {
      return state.gitlabProjects;
    },
    getGitlabBranches(state) {
      return state.gitlabBranches;
    },
  },
};

export default gitlabStore;
