<template>
  <div class="modal fade" id="modalTrama" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><b>Trama</b></h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-body-trama">
          <table class="table table-sm">
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="ind" v-for="(t, ind) in trama">
                <td v-if="t.length < 120">{{ t }}</td>
                <td v-else-if="t.length >= 120">
                  {{ t.substr(0, 120) + "\n" + t.substr(121, t.length) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal-Trama",
  props: {
    trama: Array,
    // transactions: Object,
    dataTarget: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.modal-backdrop {
  top: 0;
  left: -2;
  z-index: 1040;
  width: 0vw;
  height: 100vh;
  background-color: #000;
}

.md-table-cell {
  height: 1%;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  line-height: 0.1px;
}

.modal-body-trama {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
  max-height: 700px;
  overflow-y: auto;
}
.md-card .table {
  margin-left: 15px;
  border-left-style: solid;
  border-left-width: 0px;
  margin-right: 15px;
}
</style>
