<template>
<footer class="footer">
    <div class="container">
        <nav>
            <ul>            
                <li>
                    <a href="https://www.kvz.cl/">                        
                    </a>
                </li>           
            </ul>
        </nav>
        <div class="copyright text-center">
            &copy; {{ new Date().getFullYear() }}
            <a href="https://www.kvz.cl/" target="_blank" rel="noopener">KUVASZ Solutions</a> - Equipo de Automatización
        </div>
    </div>
</footer>
</template>

<script>
export default {};
</script>

<style></style>
