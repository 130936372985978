<template>
  <md-card v-if="jobsLogs">
    <md-card-content>
      <b-form-textarea
        disabled
        style="color: white; background-color: #1a5276; color: #48c9b0"
        id="jobsLogsRef"
        :value="jobsLogs"
        placeholder=""
        rows="11"
        max-rows="11"
      ></b-form-textarea>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "log-testing-card",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
    jobsLogs: String,
  },
  updated() {
    this.scrollToEnd();
  },
  watch: {
    jobsLogs: function () {
      if (this.jobsLogs != "") {
        if (document.getElementById("jobsLogsRef")) {
          // console.log(document.getElementById("jobsLogsRef"));
          this.scrollToEnd();
        }
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    scrollToEnd() {
      //console.log("hola");
      let textarea = document.getElementById("jobsLogsRef");
      if (textarea) {
        textarea.scrollTop = textarea.scrollHeight;
      }
      // textarea.scrollTop = textarea.scrollHeight;
    },
  },
};
</script>

<style>
.step-progress__step-label {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 100;
  color: grey;
  transition: 0.3s ease;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}
</style>
