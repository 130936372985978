<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-80"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">Pruebas Almacenadas</h4>
            <p class="category">
              Aquí puede visualizar al desempeño histórico de las distintas pruebas.
            </p>
          </md-card-header>
          <md-card-content>
            <div class="row mb-2">
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-primary btn-custom"
                  @click="getExecutions('MDS')"
                >
                  Mastercard
                </button>
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-primary btn-custom"
                  @click="getExecutions('HISO87')"
                >
                  Hiso87
                </button>
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-primary btn-custom"
                  @click="getExecutions('HISO93')"
                >
                  Hiso93
                </button>
              </div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-primary btn-custom"
                  @click="getExecutions('VISANET')"
                >
                  Visanet
                </button>
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <RepositoryTable
                table-header-color="orange"
                :table="table"
              ></RepositoryTable>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import RepositoryTable from "../../components/Tables/RepositoryTable.vue";
export default {
  name: "Test-Repository",
  components: {
    RepositoryTable,
  },
  props: {},
  mounted() {},
  data() {
    return {
      table: false,
    };
  },
  methods: {
    ...mapActions("execution", ["getExecutionsByType"]),
    ...mapMutations("execution", ["setExecutionsByType"]),
    showTable() {
      if (this.table) {
        this.table = false;
      } else {
        this.table = true;
      }
    },
    async getExecutions(type) {
      const idUser = this.loggedUser.id;
      this.table = false;
      await this.getExecutionsByType({ idUser, type })
        .then((response) => {
          this.table = true;
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    },
  },
  computed: {
    ...mapState("execution", ["executionsByType"]),
    ...mapState("auth", ["loggedUser"]),
  },
};
</script>

<style>
.btn-custom {
  width: 300px;
  height: 100px;
  font-size: 35px;
}
</style>
