<template>
  <div class="content">
    <h5 class="text-center"><b>ANALISIS DE CODIGO</b></h5>
    <hr />
    <div v-if="compilations" id="accordion">
      <div v-bind:key="i" v-for="(c, i) in compilations.data">
        <div class="col-auto">
          <button
            @click="resetColor(c)"
            class="btn btn-primary btn-xs text-left"
            data-toggle="collapse"
            :data-target="'#collapse' + i"
            aria-expanded="false"
            :aria-controls="'collapse' + i"
          >
            Desplegar
          </button>
          <button disabled class="btn btn-danger btn-xs text-left">
            Eliminar
          </button>

          <h5 class="text-center">
            <label style="font-size: 100%"
              ><b>Pipeline: </b>{{ c.pipeline_id }} <b>Proyecto: </b
              >{{ c.project_name }} <b>Rama:</b> {{ c.branch }}</label
            >
          </h5>

          <div class="card">
            <div
              :id="'collapse' + i"
              class="collapse"
              :aria-labelledby="'heading' + i"
              data-parent="#accordion"
            >
              <div class="card-body">
                <label>
                  <b
                    >Para más detalles visitar:
                    <a
                      target="_blank"
                      :href="
                        'https://sonarqube.kvz.cl/dashboard?id=' +
                        c.project_name.toLowerCase() +
                        '&branch=' +
                        editBranch(c.branch.toLowerCase())
                      "
                      class="link-primary"
                      >Sonar</a
                    ></b
                  ></label
                >
                <div>
                  <div
                    class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                  >
                    <div class="row" id="acc">
                      <div
                        v-for="(r, index) in compilations.data[i].report"
                        :key="index"
                        class="mb-2"
                      >
                        <div class="col-sm-auto">
                          <StatsCard data-background-color="white">
                            <template slot="header">
                              <div id="btnn">
                                <button
                                  style="
                                    padding-top: 0.1px;
                                    padding-bottom: 1px;
                                    border-left-style: solid;
                                    border-left-width: 111px;
                                    border-right-width: 111px;
                                    border-right-style: solid;
                                  "
                                  :id="'btn-report' + index + i"
                                  :class="'btn btn-success btn-xs btn-block text-center'"
                                  @click="assignData(r, index, i, c)"
                                >
                                  <h4 id="btn-name">
                                    <b>{{ r.scanner_name }}</b>
                                  </h4>
                                  <!-- <h3 class="title">{{r.scanner_name}}</h3> -->
                                </button>
                              </div>
                            </template>
                            <template slot="content">
                              <label for=""> Filtros:</label>
                            </template>
                            <template slot="footer">
                              <div class="stats">
                                <!-- <md-icon>fact_check</md-icon> -->
                                <h4>Informe: {{ r.type }}</h4>
                              </div>
                            </template>
                          </StatsCard>
                        </div>
                      </div>
                    </div>
                    <!--   <button class="btn btn-success" @click="filter('s',i)"> Style</button>
                                    <button class="btn btn-warning" @click="filter('w',i)"> Warning</button>
                                    <button class="btn btn-danger" @click="filter('e',i)"> Errors</button> -->
                    <div>
                      <TableReportDetail
                        :table="table"
                        :report="dataReport"
                      ></TableReportDetail>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      id="pagination"
      align="center"
      :data="compilations"
      @pagination-change-page="getCompilations"
    ></pagination>
    <div v-if="spinner" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <div v-if="compilations.data == ''">
      <label for=""> No hay Informes que mostrar.</label>
    </div>
    <!--  <button @click="getCompilations">reportes</button>
    <button @click="saveReport">save check</button> -->
  </div>
</template>

<script>
import TableReportDetail from "../../components/Tables/ReportDetailTable.vue";
import StatsCard from "../../components/Cards/StatsCard.vue";
export default {
  name: "ReportComponent",
  components: {
    StatsCard,
    TableReportDetail,
  },
  mounted() {
    // this.saveReport();
    this.getCompilations();
  },
  data() {
    return {
      compilations: {},
      dataReport: {},
      change: "",
      table: false,
      spinner: true,
    };
  },
  watch: {
    change: function () {
      this.table = true;
    },
  },
  methods: {
    editBranch(branch) {
      let newBranch = "";
      let b = branch.split(".");
      if (b.length > 1) {
        newBranch = b[0] + "/" + b[1];
      } else {
        newBranch = b[0];
      }
      // console.log(b)
      return newBranch;
    },
    resetColor(c) {
      const reportLengths = [2, 3];
      reportLengths.forEach((len) => {
        if (c.report.length === len) {
          for (let i = 0; i < len; i++) {
            for (let j = 0; j < 3; j++) {
              const elem = document.getElementById("btn-report" + i + j);
              if (elem) {
                elem.className = "btn btn-success btn-xs btn-block text-left";
              }
            }
          }
        }
      });
      this.table = false;
    },
    assignData(r, index, i, c) {
      this.resetColor(c); // Use the reset function to avoid duplicating code.

      const elemToWarn = document.getElementById("btn-report" + index + i);
      if (elemToWarn) {
        elemToWarn.className = "btn btn-warning btn-xs btn-block text-left";
      }

      this.dataReport = r;
      this.table = true;
    },
    saveReport() {
      axios
        .get("/save-reports", {
          params: {
            userEmail: this.$store.getters.getUser.email,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.getCompilations();
        });
    },
    getCompilations(page) {
      axios
        .get("/compilations", {
          params: {
            idUser: this.$store.getters.getUser.id,
            page: page,
          },
        })
        .then((response) => {
          this.spinner = false;
          this.compilations = response.data;
        });
    },
  },
};
</script>

<style scoped>
.btn-name {
  height: 1100px;
  width: 1400;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgb(126, 121, 121);
  background-clip: padding-box;
  border: 1px solid rgba(1, 1, 1, 0);
  border-radius: 0.5rem;
}

.lastUpdate {
  text-align: right;
  color: darkgray;
}

.card {
  border-top-width: 4px;
}

.md-card [data-background-color] {
  color: #93c54b;
}

.md-card-stats .md-card-header {
  float: left;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
