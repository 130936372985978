<template>
  <div
    class="modal fade"
    id="modalTransactionComparison"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-tramas modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center mb-2">
          <h4 class="modal-title"><b>Comparación de Tramas</b></h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-body-trama">
          <div class="row">
            <div class="col-12">
              <table
                class="table table-striped table-inverse table-responsive"
                style="display: inline-table; justify-content: center"
              >
                <thead class="thead-inverse">
                  <tr>
                    <th>Campo</th>
                    <th>Transacción 1</th>
                    <th>Transacción 2</th>
                    <th>¿Hay diferencias?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in comparedTransactions"
                    :key="index"
                  >
                    <td>{{ item.field }}</td>
                    <td>{{ item.transaction1 }}</td>
                    <td>{{ item.transaction2 }}</td>
                    <td
                      :style="{ color: item.hasDifferences ? 'red' : 'green' }"
                    >
                      {{ item.hasDifferences ? "Sí" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-2">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTransactionComparison",
  props: {
    transactions: Array,
    mode: String,
    dataTarget: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    closeModal() {
      $("#modalTransactionComparison").modal("hide");
    },
  },
  computed: {
    comparedTransactions() {
      if (this.transactions.length !== 2) {
        return [];
      }

      let transactionFields = [{}, {}];
      this.transactions.forEach((transaction, index) => {
        let fields = this.mode == 'env' ? transaction.trama.split("|").filter(Boolean) : transaction.trama_response.split("|").filter(Boolean);
        let fieldObject = {};
        fields.forEach((field) => {
          let [key, value] = field.split(":");
          fieldObject[key] = value;
        });
        transactionFields[index] = fieldObject;
      });

      let allFields = new Set([
        ...Object.keys(transactionFields[0]),
        ...Object.keys(transactionFields[1]),
      ]);
      let comparisonArray = [];

      allFields.forEach((field) => {
        let comparisonObject = {
          field: field,
          transaction1: transactionFields[0][field] || "",
          transaction2: transactionFields[1][field] || "",
          hasDifferences:
            transactionFields[0][field] !== transactionFields[1][field],
        };
        comparisonArray.push(comparisonObject);
      });

      return comparisonArray;
    },
  },
};
</script>

<style>
.modal-backdrop {
  top: 0;
  left: -2;
  z-index: 1040;
  width: 0vw;
  height: 100vh;
  background-color: #000;
}

.md-table-cell {
  height: 1%;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 13px;
  line-height: 0.1px;
}

.modal-body-trama {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
  max-height: 700px;
  overflow-y: auto;
}
.md-card .table {
  margin-left: 15px;
  border-left-style: solid;
  border-left-width: 0px;
  margin-right: 15px;
}
.modal-tramas {
  max-width: 75%;
  max-height: 75%;
}
</style>
