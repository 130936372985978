<template>
  <div class="content">
    <div class="md-layout">
      <br />
      <div>
        <!-- <TableIsloDetailsComponent @cleanVSelect="cleanVSelect" :idIslo="ts.islo_id" :iss="ts.iss" :flag="flag" /> -->
      </div>

      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <div class="row">
              <!-- <div class="row" v-if="this.$route.params.data"> -->
              <h4 class="title col-auto">Busqueda de Ejecuciones</h4>
              <!--  <h4 class="title col-5"></h4>
                        <h4 class="title col-auto"><b>Tiempo Ejecución: {{this.$route.params.data[0].time}}</b></h4>
                        <h4 class="title col-auto"><b>Proyecto: {{this.$route.params.data[1].project.name}}</b></h4>
                        <h4 class="title col-auto"><b>Rama: {{this.$route.params.data[2].branch}}</b></h4> -->
            </div>
          </md-card-header>

          <md-card-content>
            <!-- <div class="row"> -->
            <div class="md-layout-item md-small-size-100 md-size-100">
              <label><b>Proyecto</b></label>
              <md-field>
                <b-form-select
                  class="form form-control"
                  v-model="projectSelected"
                >
                  <option disabled value="" selected="selected">
                    Seleccione
                  </option>
                  <option
                    v-bind:key="index"
                    v-bind:value="p.project"
                    v-for="(p, index) in loggedUser.user_projects"
                  >
                    {{ p.project.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>
                  Seleccione una Proyecto.
                </b-form-invalid-feedback>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-25 md-size-100">
              <label for=""><b>Rama</b></label>
              <md-field>
                <b-form-select
                  class="form form-control"
                  v-model="branchSelected"
                  :disabled="value"
                >
                  <option disabled value="" selected="selected">
                    Seleccione
                  </option>
                  <option
                    v-bind:key="index"
                    v-bind:value="bra"
                    v-for="(bra, index) in branches"
                  >
                    {{ bra.branch }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback>
                  Seleccione una Rama.
                </b-form-invalid-feedback>
              </md-field>
            </div>
            <!-- <div class="md-layout-item md-small-size-100 md-size-100">
                        <label for=""><b>Adquiriente</b></label>
                        <md-field>
                            <b-form-select class="form-control" v-model="red">
                                <option disabled value="" selected="selected">Seleccione</option>
                                <option value="VISA">VISA</option>
                                <option value="HISO">HISO</option>
                            </b-form-select>
                            <b-form-invalid-feedback>
                                Seleccione una Red.
                            </b-form-invalid-feedback>
                        </md-field>
                    </div> -->
            <div class="md-layout-item md-small-size-100 md-size-100">
              <label for=""><b>Fecha</b></label>
              <md-field>
                <input
                  v-model="dateExecution"
                  class="form form-control"
                  type="date"
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-success btn-block"
                @click="getExecutions"
                >Buscar
                <b-spinner
                  v-if="spinnerBuscar == true"
                  small
                  variant="white"
                ></b-spinner>
              </md-button>
            </div>

            <!-- </div> -->

            <div class="row">
              <div
                v-if="transactions.t"
                class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-23"
              >
                <StatsCard data-background-color="red">
                  <template slot="header">
                    <md-icon>info_outline</md-icon>
                  </template>
                  <template slot="content">
                    <h5 class="category">
                      <b>Rechazadas</b>
                    </h5>
                    <h3 class="title">
                      {{ transactions.ts.rejected }}
                    </h3>
                  </template>
                  <template slot="footer">
                    <div class="stats">
                      <md-icon>info_outline</md-icon>
                      Correspondientes al
                      {{
                        parseInt(
                          (transactions.ts.rejected * 100) /
                            transactions.ts.total
                        )
                      }}% del total
                    </div>
                  </template>
                </StatsCard>
              </div>
              <div
                v-if="transactions.t"
                class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-23"
              >
                <StatsCard data-background-color="green">
                  <template slot="header">
                    <md-icon>check_circle</md-icon>
                  </template>
                  <template slot="content">
                    <h5 class="category">
                      <b>Aprobadas</b>
                    </h5>
                    <h3 class="title">
                      {{ transactions.ts.approved }}
                    </h3>
                  </template>
                  <template slot="footer">
                    <div class="stats">
                      <md-icon>check_circle</md-icon>
                      Correspondientes al
                      {{
                        parseInt(
                          (transactions.ts.approved * 100) /
                            transactions.ts.total
                        )
                      }}% del total
                    </div>
                  </template>
                </StatsCard>
              </div>
              <div
                v-if="transactions.t"
                class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-23"
              >
                <StatsCard data-background-color="orange">
                  <template slot="header">
                    <md-icon>rule</md-icon>
                  </template>
                  <template slot="content">
                    <h5 class="category">
                      <b>Sin Respuesta</b>
                    </h5>
                    <h3 class="title">
                      {{ transactions.ts.missing }}
                    </h3>
                  </template>
                  <template slot="footer">
                    <div class="stats">
                      <md-icon>rule</md-icon>
                      Correspondientes al
                      {{
                        parseInt(
                          (transactions.ts.missing * 100) /
                            transactions.ts.total
                        )
                      }}% del total
                    </div>
                  </template>
                </StatsCard>
              </div>
              <div
                v-if="transactions.t"
                class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
              >
                <StatsCard data-background-color="blue">
                  <template slot="header">
                    <md-icon>fact_check</md-icon>
                  </template>
                  <template slot="content">
                    <h5 class="category"><b>Totales</b></h5>
                    <h3 class="title">
                      {{ transactions.ts.total }}
                    </h3>
                  </template>
                  <template slot="footer">
                    <div class="stats">
                      <md-icon>fact_check</md-icon>
                      Transacciones Totales
                    </div>
                  </template>
                </StatsCard>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <!-- COMPARAR EJECUCIONES -->
        <!-- <div class="col-12">
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title col-auto">Comparar Ejecuciones</h4>
            </md-card-header>
            <md-card-content>
              <p>Compare ejecuciones seleccionadas</p>
              <div class="md-layout-item md-size-100 text-right">
                <md-button
                  class="md-raised md-info btn-block"
                  @click="openExecutionComparations"
                  >Comparar
                </md-button>
              </div>
            </md-card-content>
          </md-card>
        </div> -->
      </div>
      <div
        v-if="executions"
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-80"
      >
        <md-card>
          <md-card-header data-background-color="purple" class="header-flex">
            <h4 class="title">Ejecuciones</h4>
            <div class="filter-container">
              <div class="input-with-icon">
                <input
                  class="mr-2"
                  type="search"
                  placeholder="Ingrese ID Pipeline"
                  aria-label="Search"
                  v-model="filters.pipelineFilter"
                />
                <i class="fa fa-search"></i>
              </div>
              <div class="input-with-icon">
                <input
                  class="mr-2"
                  type="search"
                  placeholder="Ingrese Proyecto"
                  aria-label="Search"
                  v-model="filters.projectFilter"
                />
                <i class="fa fa-search"></i>
              </div>
              <div class="input-with-icon">
                <input
                  type="search"
                  placeholder="Ingrese Rama"
                  aria-label="Search"
                  v-model="filters.branchFilter"
                />
                <i class="fa fa-search"></i>
              </div>
            </div>
          </md-card-header>
          <md-card-content v-if="spinnerTable == true" class="text-center">
            <div
              class="spinner-border text-primary text-center mt-2"
              role="status"
            ></div>
          </md-card-content>
          <md-card-content v-else>
            <ExecutionsTable
              table-header-color="purple"
              @getExecutions="getExecutions"
              :executions="filteredExecutions"
              :tableSize="15"
            ></ExecutionsTable>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "../../components/Cards/StatsCard.vue";
import ExecutionsTable from "../../components/Tables/ExecutionsTable.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Result-Logger",
  components: {
    StatsCard,
    ExecutionsTable,
  },
  mounted() {
    this.getExecutions();
  },

  watch: {
    ts: function () {
      if (this.ts == null) {
        this.ts = [];
        this.showTable = false;
      } else {
        this.getDetailTransactions();
        this.flag = this.flag + 1;
      }
    },
    projectSelected: function () {
      if (this.projectSelected != undefined) {
        const data = {
          projectId: this.projectSelected.id,
          userId: this.loggedUser.id,
        };
        this.setSelectedProject(this.projectSelected);
        this.getUserBranches(data);
        this.value = false;
      }
    },
  },
  data() {
    return {
      dataComparation: [],
      spinnerBuscar: false,
      value: true,
      red: "",
      projects: "",
      projectSelected: "",
      selectedFile: null,
      importStatus: {
        status: 0,
        errors: [],
      },
      tss: [],
      ts: [],
      islo: "",
      isloModal: "",
      dateExecution: "",
      testSw: "",
      dataIslosAll: [],
      dataIslos: [],
      backIss: "",
      showTable: true,
      spinnerModal: false,
      spinnerSelectIslo: false,
      spinnerDataIslo: false,
      spinnerTable: false,
      flag: 0,
      pipelines: "",
      projectsApi: "",
      projectApiSelected: "",
      projectIdApiSelected: "",
      branchProjectSelected: "",
      branchSelected: "",
      ejecucionPipeline: "",
      txnsSelected: "",
      transactions: [],
      filters: {
        pipelineFilter: "",
        projectFilter: "",
        branchFilter: "",
      },
    };
  },
  methods: {
    ...mapActions("user", ["getUserBranches"]),
    ...mapActions("execution", ["getUserExecutions"]),
    ...mapMutations("gitlab", ["setSelectedProject"]),
    validateModal() {
      !this.fileState ? (this.selectedFile = "") : this.fileState;
      !this.testSwState ? (this.testSw = undefined) : this.testSwState;
      !this.isloModalState ? (this.isloModal = undefined) : this.isloModalState;
      !this.dateExecutionState
        ? (this.dateExecution = undefined)
        : this.dateExecutionState;
      if (
        this.selectedFile != "" &&
        this.testSw != undefined &&
        this.isloModal != undefined &&
        this.dateExecution != undefined
      ) {
        this.importExcel();
      }
    },
    cleanModal() {
      this.selectedFile = null;
      this.importStatus.status = 0;
      this.importStatus.errors = [];
      this.isloModal = "";
      this.dateExecution = "";
      this.testSw = "";
      this.spinnerModal = false;
    },
    cleanVSelect() {
      this.islo = "";
      this.ts = "";
    },
    getIslosFilter() {
      axios
        .get("/islos-modal", {
          params: {
            idUser: this.loggedUser.id,
          },
        })
        .then((response) => {
          this.dataIslos = response.data;
        });
    },
    getIslos() {
      this.spinnerDataIslo = true;
      axios
        .get("/islos-filter", {
          params: {
            idUser: this.loggedUser.id,
          },
        })
        .then((response) => {
          this.dataIslosAll = response.data;
          this.spinnerDataIslo = false;
        });
    },
    getSTFilter() {
      this.spinnerSelectIslo = true;
      axios
        .get("/sTransaction/filter", {
          params: {
            idIslo: this.islo == null ? this.islo == [] : this.islo.id,
          },
        })
        .then((response) => {
          this.spinnerSelectIslo = false;
          this.showTable = true;
          this.tss = response.data;
          if (this.backIss != "") {
            this.tss.forEach((element) => {
              if (this.backIss == element.iss) {
                this.ts = element;
              }
            });
            this.backIss = "";
          }
        });
      this.ts = [];
      /* this.tss = []
            this.ts = [] */
      /*  this.backIss = "" */
    },
    getExecutions(page = 1) {
      this.spinnerTable = true;
      if (this.branchSelected != "") {
        this.spinnerBuscar = true;
      }

      let data = {
        idProject: this.projectSelected != "" ? this.projectSelected.id : "",
        branch: this.branchSelected.branch,
        red: this.red,
        dateExecution: this.dateExecution,
        idUser: this.loggedUser.id,
        page: page,
      };

      this.getUserExecutions(data).then((response) => {
        this.spinnerTable = false;
        this.spinnerBuscar = false;
      });
    },
  },
  computed: {
    ...mapState("auth", ["loggedUser"]),
    ...mapState("user", ["branches"]),
    ...mapState("execution", ["executions", "executionsCompare"]),
    filteredExecutions() {
      let copyExecutions = [...this.executions];

      if (this.filters.pipelineFilter != "") {
        copyExecutions = copyExecutions.filter((execution) => {
          return execution.pipeline_id
            .toString()
            .includes(this.filters.pipelineFilter);
        });
      }

      if (this.filters.projectFilter != "") {
        copyExecutions = copyExecutions.filter((execution) => {
          return execution.project.name.includes(this.filters.projectFilter);
        });
      }

      if (this.filters.branchFilter != "") {
        copyExecutions = copyExecutions.filter((execution) => {
          return execution.project_name.includes(this.filters.branchFilter);
        });
      }

      return copyExecutions;
    },
  },
};
</script>
<style>
.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-with-icon {
  position: relative;
  display: inline-block;
}

.input-with-icon input[type="search"] {
  padding-right: 15px;
}

.input-with-icon .fa-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
}
</style>
