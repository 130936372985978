<template>
<!-- <div class="table-responsive-sm"> -->
    <table v-if="table" class="table audit table-sm table-hover table-striped">
        <thead>
            <tr>
                <th scope="col">Severity</th>
                <th scope="col">Message</th>
                <th scope="col">Solution</th>
                <th scope="col">File</th>
                <th scope="col">Line</th>                
            </tr>
        </thead>
        <tbody>
            <tr v-bind:key="ind" v-for="(r, ind) in report.reportdetail" style="font-size: small; color: black">
                <td>{{ r.severity }}</td>
                <td>{{ r.message }}</td>
                <td>{{ (r.solution==null)?'-':r.solution }}</td>
                <td>{{ r.file }}</td>
                <td>{{ r.start_line }}</td>               
            </tr>
        </tbody>
    </table>
<!-- </div> -->
</template>

<script>
export default {
    name: "Table-Reports",
    props: {
        table: Boolean,
        report: Object,
        dataTarget: {
            type: String,
            default: "",
        },
    },
    mounted() {},
    watch: {},
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
table.audit {
    position: relative;
    border-collapse: collapse;
}

table.audit th {
    position: sticky;
    top: 55px;
    background: white;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
</style>
